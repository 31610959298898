import React, {useState, useEffect} from 'react';
import Breadcrumb from "../../Components/Common/Breadcrumb";
import useApi from "../../hooks/useApi";
import {fetchData} from "../../BL/Assets";
import {toast, ToastContainer} from "react-toastify";
import LoadingOverlay from "react-loading-overlay-ts";
import BounceLoader from "react-spinners/BounceLoader";
import DataGrid from "../../Components/Common/DataGrid";
import {assetColDef} from "../../uihelper/gridutil";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import {assetsColDataSources} from "../../uihelper/columndatasources";

const endpoints = {
    getAll: 'api/AssetManagement/Asset',
    getById: 'api/AssetManagement/Asset',
    addData: 'api/AssetManagement/Asset',
    updateData: 'api/AssetManagement/Asset',
    deleteData: 'api/AssetManagement/Asset',
};
const Assets = () => {
    const [assetsData, setAssetsData] = useState<any[]>([]);
    const [baseUrl ] = useState<string>(process.env.REACT_APP_UIINTEGRATION_BASE_URL || '');

    const { getDataByParams, addData, updateData, deleteData} = useApi(endpoints, baseUrl);
    //const { getDataByParams } = useApi(endpoints,'/api/' + baseUrl);
    const [loading, setLoading] = useState(false);
    const [clientId, setClientId] = useLocalStorage("id", "");
    const [partnershipId, setPartnershipId] = useLocalStorage("pid", "");
    const [contributingPartnerId, setContributingPartnerId] = useLocalStorage("cpid", "");
    const [contributionId, setContributionId] = useLocalStorage("cid", "");
    const [selectedClient, setSelectedClient] = useLocalStorage("selectedClient", "");
    const [selectedPartnership, setSelectedPartnership] = useLocalStorage("selectedPartnership", "");

    const refreshData = () => {
        setLoading(true);
        Promise.all([
            fetchData(getDataByParams, partnershipId, clientId),
        ])
            .then(([result]) => {
                setLoading(false);
                if(result.success) {
                    if(result.data.length > 0) {
                        setAssetsData(result.data);
                        toast.success("Data refreshed successfully!");
                    } else {
                        toast.error("No data found...")
                    }
                } else {
                    // Handle error from fetchData
                    toast.error("An error occurred while fetching the data: " + result.error);
                }
            })
            .catch(error => {
                toast.error("An error occurred while refreshing the data.");
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        refreshData();
    }, []);
    const getBreadcrumbItems  = () => {
        breadcrumbItems.push({title: selectedClient.clientName + " (" + selectedClient.clientID + ")", url: "clienthome"});
        breadcrumbItems.push({title: selectedPartnership.partnershipName + " (" + selectedPartnership.partnershipID + ")", url: "partnershiphome"});
        return breadcrumbItems;
    }

    const breadcrumbItems =
        [
            {
                "title": "Clients",
                "url": "Clients"
            }
        ]
    const onDataGridDelete = (data:any) => {
        console.log(data.data)
        deleteData(data?.data[0]?.id,'id').then((response:any) => {
            toast.success("Data deleted successfully!");
            refreshData();
            setLoading(false);
        },error => {
            toast.error("An error occurred while deleting the data.");
            console.error(error);
            setLoading(false);
        });
    };

    // update grid data
    const onDataGridUpdate = async (data:any) => {

        if(data.data.subEntityID===null || data.data.subEntityID===undefined)

            data.data.subEntityID="";

        if (!data.data.acquisitionDate || isNaN(Date.parse(data.data.acquisitionDate))) {

            data.data.acquisitionDate = "";

        }
        if(data.data.accumulatedTaxDepreciation===null || data.data.accumulatedTaxDepreciation===undefined)

            data.data.accumulatedTaxDepreciation =0;
        if(data.data.accumulatedBookDepreciation===null || data.data.accumulatedBookDepreciation===undefined)

            data.data.accumulatedBookDepreciation =null;

        if(data.data.depreciationAllocationMethod===null || data.data.depreciationAllocationMethod===undefined)

            data.data.depreciationAllocationMethod="";

        if (!data.data.dispositionDate || isNaN(Date.parse(data.data.dispositionDate))) {

            data.data.dispositionDate = ""; // Set to empty string if not a valid date

        }

        if (!data.data.effectivePlacedInServiceDate || isNaN(Date.parse(data.data.effectivePlacedInServiceDate))) {

            data.data.effectivePlacedInServiceDate = ""; // Set to empty string if not a valid date

        }

        if(data.data.isDepreciable===null || data.data.isDepreciable===undefined)

            data.data.isDepreciable =null;

        if (!data.data.effectiveDispositionDate || isNaN(Date.parse(data.data.effectiveDispositionDate))) {

            data.data.effectiveDispositionDate = ""; // Set to empty string if not a valid date

        }

        if(data.data.dispositionType===null || data.data.dispositionType===undefined)

            data.data.dispositionType="";

        if(data.data.gainAllocationMethod===null || data.data.gainAllocationMethod===undefined)

            data.data.gainAllocationMethod="";

        if(data.data.assetType===null || data.data.assetType===undefined)

            data.data.assetType="";

        if(data.data.assetClass===null || data.data.assetClass===undefined)

            data.data.assetClass="";

        if(data.data.description===null || data.data.description===undefined)

            data.data.description="";

        if(data.data.isDepreciable===null || data.data.isDepreciable===undefined)

            data.data.isDepreciable="";

        if(data.data.gainTreatment===null || data.data.gainTreatment===undefined)

            data.data.gainTreatment="";
        if(data.data.factor===null || data.data.factor===undefined)

            data.data.factor=0;
        if(data.data.convention===null || data.data.convention===undefined)

            data.data.convention="";

        const result = await updateData(clientId,

            data.data,'id')

        if (result.success) {
            console.log('Data updated successfully:', result.data);

            toast.success("Data updated successfully!");

            refreshData();

            setLoading(false);

        } else {
            console.error('Failed to update data:', result.error);

            if (result.data) {

                toast.error(result.data.data);

            } else {

                toast.error("An error occurred while updating the data.", result.message);

            }
            console.error(result.error);

            setLoading(false);

        }

    }

// add data

    const onDataGridAdd = async (rowData: any) => {

        rowData.data.clientID = clientId;

        rowData.data.partnershipID = partnershipId;

        rowData.data.contributingPartnerID = contributingPartnerId;

        rowData.data.contributionID = contributionId;


        delete rowData.data.id;

        if(rowData.data.subEntityID===null || rowData.data.subEntityID===undefined)

            rowData.data.subEntityID="";

        if (!rowData.data.acquisitionDate || isNaN(Date.parse(rowData.data.acquisitionDate))) {

            rowData.data.acquisitionDate = ""; // Set to empty string if not a valid date

        }
        if(rowData.data.accumulatedTaxDepreciation===null || rowData.data.accumulatedTaxDepreciation===undefined)

            rowData.data.accumulatedTaxDepreciation =0;

        if (!rowData.data.effectivePlacedInServiceDate || isNaN(Date.parse(rowData.data.effectivePlacedInServiceDate))) {

            rowData.data.effectivePlacedInServiceDate = ""; // Set to empty string if not a valid date

        }

        if(rowData.data.isDepreciable===null || rowData.data.isDepreciable===undefined)

            rowData.data.isDepreciable =null;

        if(rowData.data.accumulatedBookDepreciation===null || rowData.data.accumulatedBookDepreciation===undefined)

            rowData.data.accumulatedBookDepreciation=null;

        if(rowData.data.depreciationAllocationMethod===null || rowData.data.depreciationAllocationMethod===undefined)

            rowData.data.depreciationAllocationMethod="";

        if (!rowData.data.dispositionDate || isNaN(Date.parse(rowData.data.dispositionDate))) {

            rowData.data.dispositionDate = ""; // Set to empty string if not a valid date

        }

        if (!rowData.data.effectiveDispositionDate || isNaN(Date.parse(rowData.data.effectiveDispositionDate))) {

            rowData.data.effectiveDispositionDate = ""; // Set to empty string if not a valid date

        }

        if(rowData.data.dispositionType===null || rowData.data.dispositionType===undefined)

            rowData.data.dispositionType="";

        if(rowData.data.gainAllocationMethod===null || rowData.data.gainAllocationMethod===undefined)

            rowData.data.gainAllocationMethod="";

        if(rowData.data.assetType===null || rowData.data.assetType===undefined)

            rowData.data.assetType="";

        if(rowData.data.assetClass===null || rowData.data.assetClass===undefined)

            rowData.data.assetClass="";

        if(rowData.data.description===null || rowData.data.description===undefined)

            rowData.data.description="";

        if(rowData.data.isDepreciable===null || rowData.data.isDepreciable===undefined)

            rowData.data.isDepreciable="";

        if(rowData.data.gainTreatment===null || rowData.data.gainTreatment===undefined)

            rowData.data.gainTreatment="";
        if(rowData.data.factor===null || rowData.data.factor===undefined)
            rowData.data.factor=0;
        if(rowData.data.convention===null || rowData.data.convention===undefined)
            rowData.data.convention="";

        const result = await addData(rowData.data);

        if (result.success) {
            console.log('Data added successfully:', result.data);

            toast.success("Data added successfully!");

            refreshData();

            setLoading(false);

        } else {
            console.error('Failed to add data:', result.error);

            if (result.data) {

                toast.error(result.data.data);

            } else {

                toast.error("An error occurred while adding the data.", result.message);

            }
            console.error(result.error);

            setLoading(false);

        }

    };
    return (
        <div className="page-content">
            <ToastContainer />
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader  />}
                text='Loading data...'
            >
                <div className={"container-fluid"}>
                    <Breadcrumb items={getBreadcrumbItems()} title={selectedClient.clientName} breadcrumbItem="Assets" />
                    <DataGrid  id={"iegridPartner"} columns={assetColDef}
                               data={assetsData} editing={true} add={true} del={true} onAdd={onDataGridAdd}
                               onDelete={onDataGridDelete} onUpdate={onDataGridUpdate}
                               hasColumnDataSource={true} columnDataSources={assetsColDataSources}
                               pageType={"Assets"}
                               hasExport={true}
                               pageName="Assets"
                    />
                </div>
            </LoadingOverlay>
        </div>
    );
}

export default Assets;
