import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import {
    Col,
    Row,
    CardBody, CardTitle, Card
} from "reactstrap";
import Breadcrumb from "../../Components/Common/Breadcrumb";
import DataGrid from "../../Components/Common/DataGrid";
import {partnerColDef} from "../../uihelper/gridutil";
import {fetchData} from '../../BL/Partners';
import LoadingOverlay from 'react-loading-overlay-ts';
import BounceLoader from 'react-spinners/BounceLoader'
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useApi from "../../hooks/useApi";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import '../../assets/css/custom.css';
import {partnerColDataSources} from "../../uihelper/columndatasources";
import {validateAddress} from '../../helpers/validations_helper';

interface Partnership {
    id: number;
    partnershipID: string;
    partnershipName: string;
    irsCenter: string;
    isPTP: string;
    address: {
        addressID: number;
        streetAddress: string;
        city: string;
        state: string;
        postalCode: string;
    }
}

const endpoints = {
    getAll: 'Partners',
    getById: 'Partners',
    addData: 'Partners',
    updateData: 'Partners',
    deleteData: 'Partners',
};

const PartnershipHome = () => {
    const [partnersData, setPartnersData] = useState<any[]>([]);

    const [baseUrl ] = useState<string>(process.env.REACT_APP_UIINTEGRATION_BASE_URL || '');

    const { getDataByParams, addData, updateData, deleteData} = useApi(endpoints, baseUrl);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const [clientId, setClientId] = useLocalStorage("id", "");
    const [partnershipId, setPartnershipId] = useLocalStorage("pid", "");
    const [selectedClient, setSelectedClient] = useLocalStorage("selectedClient", "");
    const [selectedPartnership, setSelectedPartnership] = useLocalStorage("selectedPartnership", "");
    const [PartnerId, setPartnerId] = useLocalStorage("prid", "");
    const [selectedPartner, setSelectedPartner] = useLocalStorage("selectedPartner", "");

    const refreshData = () => {
        setLoading(true);
        Promise.all([
            fetchData(getDataByParams, partnershipId, clientId),
        ])
            .then(([result]) => {
                if (result.success) {
                    if (result.data.length > 0) {
                        setPartnersData(result.data);
                        toast.success("Data refreshed successfully!");
                    } else {
                        setPartnersData([]);
                        toast.error("No data found...")
                    }
                } else {
                    // Handle error from fetchData
                    toast.error("An error occurred while fetching the data: " + result.error);
                }
                setLoading(false);
            })
            .catch(error => {
                toast.error("An error occurred while refreshing the data.");
                console.error(error);
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        //setPartnershipData(location.state.key);
        refreshData();
    }, [clientId]);

    const onDataGridDelete = (data:any) => {
        console.log(data.data)
        deleteData(data?.data[0]?.id,'id').then((response:any) => {
            toast.success("Data deleted successfully!");
            refreshData();
            setLoading(false);
        },error => {
            toast.error("An error occurred while deleting the data.");
            console.error(error);
            setLoading(false);
        });
    };
    const onDataGridUpdate = async (data:any) => {
        const errors = validateAddress(data.data.address);
        if (Object.keys(errors).length > 0) {
            Object.keys(errors).forEach(field => {
                toast.error(errors[field]);
            });
            return;
        }
        if(data.data.isTieringPartner===null || data.data.isTieringPartner===undefined)
            data.data.isTieringPartner="";
        if(data.data.parentPartnerID===null || data.data.parentPartnerID===undefined)
            data.data.parentPartnerID="";
        const result = await updateData(clientId,
            data.data,'id')
        if (result.success) {
            console.log('Data updated successfully:', result.data);
            toast.success("Data updated successfully!");
            refreshData();
            setLoading(false);
        } else {
            console.error('Failed to update data:', result.error);
            if (result.data) {
                toast.error(result.data.data);
            } else {
                toast.error("An error occurred while updating the data.", result.message);
            }

            console.error(result.error);
            setLoading(false);
        }
    };
    const onDataGridAdd = async (data: any) => {
        data.data.clientID = clientId;
        data.data.partnershipID = partnershipId;

        delete data.data.id;
        const errors = validateAddress(data.data.address);
        if (Object.keys(errors).length > 0) {
            Object.keys(errors).forEach(field => {
                toast.error(errors[field]);
            });
            return;
        }
        if(data.data.isTieringPartner===null || data.data.isTieringPartner===undefined)
            data.data.isTieringPartner="";
        if(data.data.parentPartnerID===null || data.data.parentPartnerID===undefined)
            data.data.parentPartnerID="";

        const result = await addData(data.data);

        if (result.success) {
            console.log('Data added successfully:', result.data);
            toast.success("Data added successfully!");
            refreshData();
            setLoading(false);
        } else {
            console.error('Failed to add data:', result.error);
            if (result.data) {
                toast.error(result.data.data);
            } else {
                toast.error("An error occurred while adding the data.", result.message);
            }

            console.error(result.error);
            setLoading(false);
        }

    };
    const wait = (n) => new Promise((resolve) => setTimeout(resolve, n));
    const initiateNavigation = async (data, partnerID) => {
        await wait(100);
        navigate(`/partnerhome?partnerID=${partnerID}`);
    }

    const handleRowClick = function (props: any) {
        console.log("handleRowClick: ", props);
        setPartnerId(props.rowData.partnerID);
        setSelectedPartner(props.rowData);
        initiateNavigation(props.rowData, props.rowData.partnerID);
    }
    const getBreadcrumbItems  = () => {
        breadcrumbItems.push({title: selectedClient.clientName + " (" + selectedClient.clientID + ")", url: "clienthome"});
        return breadcrumbItems;
    }
    const breadcrumbItems =
    [
        {
            "title": "Clients",
            "url": "Clients"
        }
    ]

    return (
        <div className="page-content">
            <ToastContainer />
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader/>}
                text='Loading data...'
            >
                <div className={"container-fluid"}>
                    <Breadcrumb items={getBreadcrumbItems()} title="Client" breadcrumbItem={selectedPartnership?.partnershipName + "(" + selectedPartnership?.partnershipID + ")"} />
                    <div style={{ width: '110%' }}>
                    <Card>
                        <CardBody>
                            <Row md={12}>
                                <Col md={6}>
                                    <label>
                                        Is Sub Entity: &nbsp;
                                    </label>
                                    <span className="col-md-4">
                                        {selectedPartnership?.isSubEntity ? "" + selectedPartnership.isSubEntity : ""}
                                    </span>
                                </Col>
                                <Col md={6}>
                                    <label>
                                        Sub Entity ID: &nbsp;
                                    </label>
                                    <span className="col-md-4">
                                        {selectedPartnership?.subentityId ? "" + selectedPartnership.subentityId : "-"}
                                    </span>
                                </Col>
                            </Row>
                            <Row md={12}>
                                <Col md={6}>
                                    <label>
                                        IRS Center: &nbsp;
                                    </label>
                                    <span className="col-md-4">
                                        {selectedPartnership?.irsCenter ? "" + selectedPartnership.irsCenter : ""}
                                    </span>
                                </Col>
                                <Col md={6}>
                                    <label>
                                        Is PTP: &nbsp;
                                    </label>
                                    <span className="col-md-4">
                                        {selectedPartnership?.isPTP ? "" + selectedPartnership.isPTP : ""}
                                    </span>
                                </Col>
                            </Row>
                            <Row md={12}>
                                <Col md={6}>
                                    <label>
                                        TIN: &nbsp;
                                    </label>
                                    <span className="col-md-4">
                                        {selectedPartnership?.tin ? "" + selectedPartnership.tin : ""}
                                    </span>
                                </Col>
                                <Col md={6}>
                                    <label>
                                        Interest Type: &nbsp;
                                    </label>
                                    <span className="col-md-4">
                                        {selectedPartnership?.interestType ? "" + selectedPartnership.interestType : ""}
                                    </span>
                                </Col>
                            </Row>
                            <Row md={12}>
                            <Col md={6}>
                                    <label>
                                        Partnership Start Date: &nbsp;
                                    </label>
                                    <span className="col-md-4">
                                        {selectedPartnership?.partnershipStartDate ? "" + selectedPartnership.partnershipStartDate : ""}
                                    </span>
                                </Col>
                                <Col md={6}>
                                    <label>
                                        Election754: &nbsp;
                                    </label>
                                    <span className="col-md-4">
                                        {selectedPartnership?.election754 ? "" + selectedPartnership.election754 : ""}
                                    </span>
                                </Col>
                            </Row>
                            <Row md={12}>
                                <Col md={6}>
                                    <label>
                                        Tax Year Start Date: &nbsp;
                                    </label>
                                    <span className="col-md-4">
                                        {selectedPartnership?.taxYearStartDate ? "" + selectedPartnership.taxYearStartDate : ""}
                                    </span>
                                </Col>
                                <Col md={6}>
                                    <label>
                                        Tax Year End Date: &nbsp;
                                    </label>
                                    <span className="col-md-4">
                                        {selectedPartnership?.taxYearEndDate ? "" + selectedPartnership.taxYearEndDate : ""}
                                    </span>
                                </Col>
                            </Row>
                            <Row md={12}>
                                <Col md={3}>
                                    <label>
                                        Street Address: &nbsp;
                                    </label>
                                    <span className="col-md-4">
                                        {selectedPartnership?.address.streetAddress ? "" + selectedPartnership.address.streetAddress : ""}
                                    </span>
                                </Col>
                                <Col md={3}>
                                    <label>
                                        City: &nbsp;
                                    </label>
                                    <span className="col-md-4">
                                        {selectedPartnership?.address.city ? "" + selectedPartnership.address.city : ""}
                                    </span>
                                </Col>
                                <Col md={3}>
                                    <label>
                                        State: &nbsp;
                                    </label>
                                    <span className="col-md-4">
                                        {selectedPartnership?.address.state ? "" + selectedPartnership.address.state : ""}
                                    </span>
                                </Col>
                                <Col md={3}>
                                    <label>
                                        Postal Code: &nbsp;
                                    </label>
                                    <span className="col-md-4">
                                        {selectedPartnership?.address.postalCode ? "" + selectedPartnership.address.postalCode : ""}
                                    </span>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    </div>
                    <div className="container-fluid">
                        <DataGrid id={"gridPartners"} columns={partnerColDef}
                                  data={partnersData}
                                  editing={true} add={true} del={true} template={false}
                                  onAdd={onDataGridAdd} onDelete={onDataGridDelete} onUpdate={onDataGridUpdate}
                                  columnDataSources={partnerColDataSources}
                                  pageType={"Partners"}
                                  hasColumnDataSource={true}
                                  onRowClick={handleRowClick}
                                  gridTitle={"Partners"}

                        />
                    </div>
                </div>
            </LoadingOverlay>
        </div>
    );
}

export default PartnershipHome;
