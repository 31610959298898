import { GetDataParams } from '../hooks/useApi';// adjust the import path according to your project structure


export const fetchData = async (getDataByParams: (params: GetDataParams) => Promise<any>, clientId: string, partnershipId: string) => {
    let endpointUrl = "api/OverrideManagement/RevalBigbilOverride";
    try {
      const { success, data, error } = await getDataByParams({
        endpoint: endpointUrl,
        params: {
          partnershipID: partnershipId,
          clientId: clientId,
        },
      });
      if (!success) {
        // Handle error
        console.error(error);
        return { success: false, error };
      } else {
        // Use data
        console.log(data);
        return { success: true, data };
      }
    } catch (error) {
      console.error(error);
      return { success: false, error: error as any };
    }
  };