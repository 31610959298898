import React, {useEffect, useRef, useState} from 'react';
import {Form, InputGroup} from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay-ts';
import BounceLoader from 'react-spinners/BounceLoader'
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {calculationResultsColDef, calculationsPartnershipColDef} from '../../uihelper/gridutil';
import useApi from '../../hooks/useApi';
import {useFormik} from "formik";
import * as Yup from 'yup';
import DataViewGrid from "../../Components/Common/DataViewGrid";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import {fetchData as fetchClientData} from "../../BL/Clients";
import {fetchData} from "../../BL/Partnerships";
import SignalRService  from "../../hooks/SingalrService";

import {DialogUtility} from "@syncfusion/ej2-popups";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import {fetchStatusData} from "../../BL/FunctionStatus";

const endpoints = {
    getAll: '/api/items',
    getById: '/api/items/{id}',
    addData: '/api/items',
    updateData: '/api/items/{id}',
    deleteData: '/api/items/{id}',
    uploadFile: '/api/items/{id}/upload',
};


const endpoint = {
    getAll: '/api/items',

};


const Calculations = () => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [clientId, setClientId] = useState<string>('');
    const [partnershipData, setPartnershipData] = useState<any[]>([]);
    const [resultsData, setResultsData] = useState<any[]>([]);
    const [partnershipId, setPartnershipId] = useState<string>('');
    const [taxYear, setTaxYear] = useState<string>('');

    const [rowData, setRowData] = useState<any[]>([]);
    const [clientList, setClientList] = useState<any[]>([]);

    const [calculationsBaseUrl ] = useState<string>(process.env.REACT_APP_CALCULATIONS_BASE_URL || '');
    const { submitMultipartForm,getDataByParams } = useApi(endpoints,calculationsBaseUrl);

    const [loading, setLoading] = useState(false);

    const isSignalREnabled = process.env.REACT_APP_SIGNALR_ENABLED === 'true';
    const dataViewGridRef = useRef(null);

        let currentClientId = clientId;
    const refreshData = () => {
        console.log("clientId in refresh: ", currentClientId)
        setLoading(true);
        Promise.all([
            fetchClientData(getDataByParams),
            fetchData(getDataByParams, currentClientId),
    ])
            .then(([clientData, partnershipData]) => {
                if(clientData.success) {
                    setClientList(clientData.data);
                }
                if (partnershipData.success) {
                    if (partnershipData.data.length > 0) {
                        setPartnershipData(partnershipData.data);
                        toast.success("Data refreshed successfully!");
                    } else {
                        toast.error("No data found...")
                    }
                } else {
                    // Handle error from fetchData
                    toast.error("An error occurred while fetching the data: " + partnershipData.error);
                }
                setLoading(false);
            })
            .catch(error => {
                toast.error("An error occurred while refreshing the data.");
                console.error(error);
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // maps the appropriate column to fields property
    const fields = { text: 'clientName', value: 'clientID' };
    const onClientChange =(args:any)=>{
        console.log("..Client..: ", args.itemData.clientID)
        currentClientId = args.itemData.clientID;
        refreshData();
    }


    let signalRService = useRef<SignalRService | null>(null);

    useEffect(() => {

        refreshData();

        if(isSignalREnabled)
        {
            const start = async () => {
                try {
                    signalRService.current = new SignalRService();
                    console.log("SingalR Connected.............")


                } catch (error) {
                    console.error('Connection error:', error);
                }
            };

            start().then(r => {
                signalRService.current?.connection?.on('UpdateCalceStatus', data => {
                    console.warn('Update Calce  message received..........:', data);
                    try {

                        setResultsData(prevResultsData => [...prevResultsData, data]);
                        if(dataViewGridRef.current) {
                            (dataViewGridRef.current as any).refreshData(resultsData);
                        }
                    } catch (err) {
                        console.error("Error occurred in SignalR client-side method: ", err);
                    }
                });
            });
        }
    }, [isSignalREnabled]); // Add dependencies here if any

    //Inline forms
    const inLineFormik: any = useFormik({
        initialValues: {
            partnershipId: "",
            taxYear: ""
        },
        validationSchema: Yup.object({
            partnershipId: Yup.string().required("This field is required"),
            taxYear: Yup.string().required("This field is required"),
        }),

        onSubmit: (values: any) => {
            setTaxYear(values.taxYear);
        },


    })

   //This method shall be used to submit the form data to the api along with the file for each tax year
    // selected partnerships

    const handleSubmitForm = async (values) => {
        //  await signalRService.broadcastMessage("Hey this is from runcalc.....");
        //  await signalRService.sendMessage("RunCalc","Sending general messaage....")
        setLoading(true);
        let debug = false;
        if(debug){
            setLoading(false);
            return;
        } else {
            const taxYearElement = document.getElementById('inlineFormInputGroupTaxYear') as HTMLInputElement;
            const taxYear = taxYearElement ? taxYearElement.value: '';
            const endpoint = 'api/CalculationOrchestration/run';
            console.log("Tax Year value is ..", taxYear, rowData);

            if(!taxYear) {
                DialogUtility.alert({
                    content: "Please enter a tax year! Tax year is required!",
                    title: 'Alert'
                });
                setLoading(false);
                return;
            }

            if(rowData && rowData.length > 0) {
                let partnershipId = "";
                currentClientId = rowData.map((row) => row.clientID).join(",")
                partnershipId = rowData.map((row) => row.partnershipID).join(",");
                console.log("partnerships selected: ", partnershipId)

                const formData = new FormData();
                formData.append('clientID', currentClientId);
                formData.append('partnershipId', partnershipId);
                formData.append('taxYear', taxYear);

                try {
                    const response = await submitMultipartForm(endpoint, formData, 1);
                    const statusDataResponse = await fetchStatusData(getDataByParams, taxYear, currentClientId);
                    if (statusDataResponse.success) {
                        setResultsData(statusDataResponse.data);
                        setLoading(false);
                    } else {
                        toast.error('Error fetching status data: ' + statusDataResponse.error);
                    }
                }catch (error) {
                    // show toast notification
                    setLoading(false);
                    console.error('Error submitting form:', error);
                }
            } else {
                DialogUtility.alert({
                    content: "Please select at least one row",
                    title: 'Alert'
                });
                setLoading(false);
            }
        }
    };
    useEffect(() => {
        console.log("Inside use effect....",rowData); // This will log the updated state
      }, [rowData]); // This effect runs whenever rowData changes


    const onRowDataChangedNew =(args:any)=>{
        console.log(   "Inside index-old.tsx selected rows new are .. ",args);
        setRowData(args);
        console.log(   "Inside index-old.tsx selected rows are .. ",rowData)

    }

    return (

        <div className="page-content">
            <ToastContainer />
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader/>}
                text='Loading data...'
            >

                <div className="container-fluid">
                    <div className="col-3">
                        <DropDownListComponent id="atcelement" dataSource={clientList} fields={fields} allowFiltering={true} value={clientId} placeholder="Change Client" change={onClientChange} />
                    </div>
                    <br/>
                    <Form className="align-items-center" onSubmit={inLineFormik.handleSubmit}>
                        <div className="col-2">
                            <InputGroup>
                                <input type="text" name="taxYear" className="form-control"
                                       id="inlineFormInputGroupTaxYear" placeholder="Tax Year"
                                       value={inLineFormik.values.taxYear}
                                       onChange={inLineFormik.handleChange}
                                       onBlur={inLineFormik.handleBlur}/>
                            </InputGroup>
                            {
                                inLineFormik.errors.taxYear && inLineFormik.touched.taxYear ? (
                                    <span className="text-danger">{inLineFormik.errors.taxYear}</span>
                                ) : null
                            }
                        </div>
                        <br/>
                        <div className="col-7">
                                <DataViewGrid  id={"Calculations"}
                                              columns={calculationsPartnershipColDef}
                                              data={partnershipData}
                                              hasMultiSelect={true}
                                              setSelectedRows={onRowDataChangedNew}
                                />
                        </div>
                        <br/>
                        <div className="container-fluid">
                            <button type="submit" className="btn btn-primary w-md" onClick={handleSubmitForm} >Run Calculations</button>
                        </div>
                    </Form>
                </div>
                <br/>
                <div className="container-fluid">
                    <DataViewGrid ref={dataViewGridRef}  id={"CalculationsStatus"}
                                  columns={calculationResultsColDef}
                                  data={resultsData}
                                  hasMultiSelect={false}
                                  hasExport={true}

                    />
                </div>

            </LoadingOverlay>
        </div>

    );
};

export default Calculations;
