import React, {useState, useEffect} from 'react';
import Breadcrumb from "../../Components/Common/Breadcrumb";
import useApi from "../../hooks/useApi";
import {fetchData} from "../../BL/IncomeExpense";
import {toast, ToastContainer} from "react-toastify";
import LoadingOverlay from "react-loading-overlay-ts";
import BounceLoader from "react-spinners/BounceLoader";
import DataGrid from "../../Components/Common/DataGrid";
import {incomeExpenseColDef} from "../../uihelper/gridutil";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import {incomeExpenseColDataSources} from "../../uihelper/columndatasources";

const endpoints = {
    getAll: 'api/IncomeExpenseManagement/IncomeExpense',
    getById: 'api/IncomeExpenseManagement/IncomeExpense',
    addData: 'api/IncomeExpenseManagement/IncomeExpense',
    updateData: 'api/IncomeExpenseManagement/IncomeExpense',
    deleteData: 'api/IncomeExpenseManagement/IncomeExpense',
};
const IncomeExpense = () => {
    const [incomeExpenseData, setIncomeExpenseData] = useState<any[]>([]);
    const [baseUrl ] = useState<string>(process.env.REACT_APP_UIINTEGRATION_BASE_URL || '');

    const { getDataByParams, addData, updateData, deleteData} = useApi(endpoints, baseUrl);
    // const { getDataByParams } = useApi(endpoints,'/api/' + baseUrl);
    const [loading, setLoading] = useState(false);
    const [clientId, setClientId] = useLocalStorage("id", "");
    const [partnershipId, setPartnershipId] = useLocalStorage("pid", "");
    const [selectedClient, setSelectedClient] = useLocalStorage("selectedClient", "");
    const [selectedPartnership, setSelectedPartnership] = useLocalStorage("selectedPartnership", "");

    const refreshData = () => {
        setLoading(true);
        Promise.all([
            fetchData(getDataByParams, partnershipId, clientId),
        ])
            .then(([result]) => {
                setLoading(false);
                if(result.success) {
                    if(result.data.length > 0) {
                        setIncomeExpenseData(result.data);
                        toast.success("Data refreshed successfully!");
                    } else {
                        toast.error("No data found...")
                    }
                } else {
                    // Handle error from fetchData
                    toast.error("An error occurred while fetching the data: " + result.error);
                }
            })
            .catch(error => {
                toast.error("An error occurred while refreshing the data.");
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        refreshData();
    }, []);

    const getBreadcrumbItems  = () => {
        breadcrumbItems.push({title: selectedClient.clientName + " (" + selectedClient.clientID + ")", url: "clienthome"});
        breadcrumbItems.push({title: selectedPartnership.partnershipName + " (" + selectedPartnership.partnershipID + ")", url: "partnershiphome"});
        return breadcrumbItems;
    }

    const breadcrumbItems =
        [
            {
                "title": "Clients",
                "url": "Clients"
            }
        ]

    const onDataGridAdd = async (rowData: any) => {
        rowData.data.clientID = clientId;
        rowData.data.partnershipID = partnershipId;

        delete rowData.data.id;
        if(rowData.data.subEntityID===null || rowData.data.subEntityID===undefined)
            rowData.data.subEntityID="";
        if(rowData.data.allocationGroupID===null || rowData.data.allocationGroupID===undefined)
            rowData.data.allocationGroupID="";
        if (!rowData.data.taxPeriodID || isNaN(Date.parse(rowData.data.taxPeriodID))) {
            rowData.data.taxPeriodID = ""; // Set to empty string if not a valid date
        }
        const result = await addData(rowData.data);

        if (result.success) {
            console.log('Data added successfully:', result.data);
            toast.success("Data added successfully!");
            refreshData();
            setLoading(false);
        } else {
            console.error('Failed to add data:', result.error);
            if (result.data) {
                toast.error(result.data.data);
            } else {
                toast.error("An error occurred while adding the data.", result.message);
            }

            console.error(result.error);
            setLoading(false);
        }

    };

    const onDataGridUpdate = async (data:any) => {
        if(data.data.subEntityID===null || data.data.subEntityID===undefined)
            data.data.subEntityID="";
        if(data.data.allocationGroupID===null || data.data.allocationGroupID===undefined)
            data.data.allocationGroupID="";
        if (!data.data.taxPeriodID || isNaN(Date.parse(data.data.taxPeriodID))) {
            data.data.taxPeriodID = ""; // Set to empty string if not a valid date
        }
        const result = await updateData(clientId,
            data.data,'id')
        if (result.success) {
            console.log('Data updated successfully:', result.data);
            toast.success("Data updated successfully!");
            refreshData();
            setLoading(false);
        } else {
            console.error('Failed to update data:', result.error);
            if (result.data) {
                toast.error(result.data.data);
            } else {
                toast.error("An error occurred while updating the data.", result.message);
            }

            console.error(result.error);
            setLoading(false);
        }
    }

    const onDataGridDelete = (data:any) => {
        console.log(data.data)
        deleteData(data?.data[0]?.id,'id').then((response:any) => {
            toast.success("Data deleted successfully!");
            refreshData();
            setLoading(false);
        },error => {
            toast.error("An error occurred while deleting the data.");
            console.error(error);
            setLoading(false);
        });
    };
    return (
        <div className="page-content">
            <ToastContainer />
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader  />}
                text='Loading data...'
            >
                <div className={"container-fluid"}>
                    <Breadcrumb items={getBreadcrumbItems()} title={selectedClient.clientName} breadcrumbItem="IncomeExpense" />
                    <DataGrid  id={"iegridPartner"} columns={incomeExpenseColDef}
                               data={incomeExpenseData} editing={true} add={true} del={true} onAdd={onDataGridAdd}
                               onDelete={onDataGridDelete} onUpdate={onDataGridUpdate}
                               hasColumnDataSource={true} columnDataSources={incomeExpenseColDataSources}
                               pageType={"IncomeExpense"}
                               hasExport={true}
                               pageName="IncomeExpenses"
                    />
                </div>
            </LoadingOverlay>
        </div>
    );
}

export default IncomeExpense;
