import React, { useState } from "react";

import { Link } from "react-router-dom";



// Import menuDropdown
import LanguageDropdown from "../../Components/Common/LanguageDropdown";
import NotificationDropDown from "../../Components/CommonForBoth/NotificationDropDown";
import ProfileMenu from "../../Components/CommonForBoth/TopBarDropDown/ProfileMenu";




import logo from "../../assets/images/logo.svg";
import logoBlack from "../../assets/images/logo-black.png";

//i18n
import { withTranslation } from "react-i18next";

const Header = (props: any) => {

  const [search, setsearch] = useState(false);


  const toggleFullscreen = () => {
    let document: any = window.document;
    document.body.classList.add("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
    // handle fullscreen exit
    const exitHandler = () => {
      if (
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      )
        document.body.classList.remove("fullscreen-enable");
    };
    document.addEventListener("fullscreenchange", exitHandler);
    document.addEventListener("webkitfullscreenchange", exitHandler);
    document.addEventListener("mozfullscreenchange", exitHandler);
  };

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }


  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
            <div className="d-flex align-items-center">
              <div className="logo-container">
                <Link to="/" className="logo logo-light">
                  <img src={logoBlack} alt="Logo" height="30" />
                </Link>
              </div>
              <button
                  type="button"
                  onClick={tToggle}
                  className="btn btn-sm px-3 font-size-16 header-item"
                  id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars" />
              </button>
            </div>
          <div className="d-flex">
            {/*<LanguageDropdown />*/}
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>

            {/*<NotificationDropDown />*/}

            <ProfileMenu />

            <div className="dropdown d-inline-block">
              {/*<button*/}
              {/*  type="button"*/}
              {/*  className="btn header-item noti-icon right-bar-toggle"*/}
              {/*  onClick={props.toggleCanvas}*/}
              {/*>*/}
              {/*  <i className="bx bx-cog bx-spin" />*/}
              {/*</button>*/}
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};


export default withTranslation()(Header);
