import { createSlice } from "@reduxjs/toolkit";
const storedUser = JSON.parse(sessionStorage.getItem('authUser') || 'null');
export const initialState = {
    user: storedUser,
    error: "", // for error msg
    loading: false,
    isUserLogout: false,
    errorMsg: false, // for error
};

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        loginSuccess(state, action) {
            state.user = action.payload;
            state.loading = false;
            state.errorMsg = false;
        },
        apiError(state, action) {
            state.error = action.payload;
            state.loading = true;
            state.isUserLogout = false;
            state.errorMsg = true;
        },
        resetLoginFlag(state) {
            state.error = "";
            state.loading = false;
            state.errorMsg = false;
        },
        logoutUserSuccess(state, action) {
            state.isUserLogout = true;
        },
        sessionExpired(state) {
            state.error = "Session Expired";
            state.loading = false;
            state.isUserLogout = true;
            state.errorMsg = true;
        }
    }
});

export const { loginSuccess, apiError, resetLoginFlag, logoutUserSuccess, sessionExpired } = loginSlice.actions;
export default loginSlice.reducer;
