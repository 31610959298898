import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { UserManager } from 'oidc-client-ts';
import { oidcConfig } from '../helpers/keycloakHelper';
import { useDispatch } from 'react-redux';
import { sessionExpired } from '../slices/auth/login/reducer';
import eventEmitter from '../context/eventEmitter';
import axios from 'axios';

interface AuthContextType {
    isSessionExpired: boolean;
    simulateTokenExpiry: () => void;
}

interface AuthProviderProps {
    children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [isSessionExpired, setSessionExpired] = useState(false);
    const userManager = new UserManager(oidcConfig);
    const dispatch = useDispatch();

    const handleTokenExpired = () => {
        console.warn('Access token expired...................................');
        localStorage.removeItem('authUser');
        sessionStorage.removeItem('authUser');
        sessionStorage.clear();

        setSessionExpired(true);
        dispatch(sessionExpired());
        eventEmitter.emit('tokenExpired');
      // userManager.signinRedirect(); // Trigger redirect to Keycloak login
    };

    // Interceptor to manage token expiry
    axios.interceptors.request.use(
        async (config) => {
            try {
                const user = await userManager.getUser();

                if (user && user.expires_at) {
                    const currentTime = Math.floor(Date.now() / 1000);

                    if (currentTime > user.expires_at) {
                        console.warn('Access token is expired');
                        handleTokenExpired();
                        return Promise.reject(new Error('Access token expired'));
                    } else {
                        config.headers.Authorization = `Bearer ${user.access_token}`;
                    }
                }
                return config;
            } catch (error) {
                console.error('Error in request interceptor:', error);
                return Promise.reject(error);
            }
        },
        (error) => {
            console.error('Request error:', error);
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        const onTokenExpired = () => {
            console.warn('addAccessTokenExpired event triggered');
            handleTokenExpired();
        };

        userManager.events.addAccessTokenExpired(onTokenExpired);
        console.log('Token expiration listener added');

        return () => {
            userManager.events.removeAccessTokenExpired(onTokenExpired);
            console.log('Token expiration listener removed');
        };
    }, [dispatch, userManager.events]);

    const simulateTokenExpiry = () => {
        console.log('Simulating token expiry');
        handleTokenExpired();
    };

    return (
        <AuthContext.Provider value={{ isSessionExpired, simulateTokenExpiry }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
