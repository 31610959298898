import axios from 'axios';

class ApiHelper<T> {
  private axiosInstance: any;
  public config: any;
  private token: string | undefined;
  private userId: string | undefined;
  // constructor() {
  //   this.config = {
  //     baseURL: process.env.REACT_APP_BASE_URL || 'http://localhost:5000/', // Default base URL
  //     //timeout: 10000, // Default timeout
  //     headers: { 'Content-Type': 'application/json' }, // Default headers
  //   };
  //   this.axiosInstance = axios.create(this.config);
  // }


  
  constructor(baseURL?: string) {
    if(process.env.REACT_APP_DEFAULTAUTH === "oidc"){
      const authUser = JSON.parse(localStorage.getItem("authUser") || '{}');
      this.token = authUser.token;
      this.userId = authUser.profile?.preferred_username;
      this.token = JSON.parse(sessionStorage.getItem("authUser") || '{}').token;
      this.config = {
        baseURL: baseURL || process.env.REACT_APP_BASE_URL || 'http://localhost:5000/', // Default base URL
        //timeout: 10000, // Default timeout
        headers: { 'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`,
          'X-UserId': this.userId
        }, // Default headers

      };
    }else{
      //without token
      this.config = {
        baseURL: baseURL || process.env.REACT_APP_BASE_URL || 'http://localhost:5000/', // Default base URL
        //timeout: 10000, // Default timeout
        headers: { 'Content-Type': 'application/json' }, // Default headers
      };
    }

    this.axiosInstance = axios.create(this.config);
  }

  private async makeRequest(config: any): Promise<T> {
    try {
      let uri = this.config.baseURL + config.url;
   //   console.warn(uri)
    //  console.warn(`Making request to ${uri}`);
      const response = await this.axiosInstance({
        ...config,
        url: this.config.baseURL + config.url,
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.warn(`Error: http error occurred`,error);
      } else {
        console.warn(`Error: Other error `);
      }
      throw error;
    }
  }

  public async get(url: string, params?: any): Promise<T> {
    return this.makeRequest({ method: 'GET', url, params });
  }

  public async post(url: string, data?: any): Promise<T> {
    return this.makeRequest({ method: 'POST', url, data });
  }

  public async put(url: string, data?: any): Promise<T> {
    console.warn("inside put ...." + url)
    return this.makeRequest({ method: 'PUT', url, data });
  }

  public async delete(url: string, data?: any): Promise<T> {
    return this.makeRequest({ method: 'DELETE', url, data });
  }

  public async uploadFile(url: string, file: File, additionalData: any): Promise<T> {
    const formData = new FormData();
    formData.append('file', file);
  
    // Append additional data to formData
    for (const key in additionalData) {
      formData.append(key, additionalData[key]);
    }
  
    return this.makeRequest({ 
      method: 'POST', 
      url, 
      data: formData, 
      headers: { 'Content-Type': 'multipart/form-data' } 
    });
  }


  public async downloadFile(url: string, id: string, additionalParams: any): Promise<T> {
    return this.makeRequest({ 
      method: 'GET', 
      url: url.replace('{id}', id), 
      params: additionalParams, 
      responseType: 'blob' 
    });
  }


}

export default ApiHelper;