import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import {
    Col,
    Row,
    Card, CardBody, CardTitle
} from "reactstrap";
import Breadcrumb from "../../Components/Common/Breadcrumb";
import DataGrid from "../../Components/Common/DataGrid";
import {partnershipColDef} from "../../uihelper/gridutil";
import {fetchData} from '../../BL/Partnerships';
import LoadingOverlay from 'react-loading-overlay-ts';
import BounceLoader from 'react-spinners/BounceLoader'
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useApi from "../../hooks/useApi";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import '../../assets/css/custom.css';
import {partnershipColDataSources} from "../../uihelper/columndatasources";
import {validateAddress} from '../../helpers/validations_helper';

const endpoints = {
    getAll: 'Partnerships',
    getById: 'Partnerships',
    addData: 'Partnerships',
    updateData: 'Partnerships',
    deleteData: 'Partnerships',
};

const ClientHome = () => {
    const [partnershipData, setPartnershipData] = useState<any[]>([]);

    const [baseUrl ] = useState<string>(process.env.REACT_APP_UIINTEGRATION_BASE_URL || '');

    const { getDataByParams, addData, updateData, deleteData} = useApi(endpoints, baseUrl);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const [clientId, setClientId] = useLocalStorage("id", "");
    const [partnershipId, setPartnershipId] = useLocalStorage("pid", "");
    const [selectedClient, setSelectedClient] = useLocalStorage("selectedClient", "");
    const [selectedPartnership, setSelectedPartnership] = useLocalStorage("selectedPartnership", "");

    const refreshData = () => {
        setLoading(true);
        Promise.all([
            fetchData(getDataByParams, clientId),
        ])
            .then(([result]) => {
                if (result.success) {
                    if (result.data.length > 0) {
                        setPartnershipData(result.data);
                        toast.success("Data refreshed successfully!");
                    } else {
                        setPartnershipData([]);
                        toast.error("No data found...")
                    }
                } else {
                    // Handle error from fetchData
                    toast.error("An error occurred while fetching the data: " + result.error);
                }
                setLoading(false);
            })
            .catch(error => {
                toast.error("An error occurred while refreshing the data.");
                console.error(error);
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        //setClientData(location.state.key);
        refreshData();
    }, [clientId]);


    const wait = (n) => new Promise((resolve) => setTimeout(resolve, n));
    const initiateNavigation = async (data, partnershipID) => {
        await wait(100);
        navigate(`/partnershiphome?partnershipId=${partnershipID}`);
    }

    const handleRowClick = function (props: any) {
        console.log("handleRowClick: ", props);
        setPartnershipId(props.rowData.partnershipID);
        setSelectedPartnership(props.rowData);
        initiateNavigation(props.rowData, props.rowData.partnershipID);
    }
    const onDataGridDelete = (data:any) => {
        console.log(data.data)
        deleteData(data?.data[0]?.id,'id').then((response:any) => {
            toast.success("Data deleted successfully!");
            refreshData();
            setLoading(false);
        },error => {
            toast.error("An error occurred while deleting the data.");
            console.error(error);
            setLoading(false);
        });
    };
    const onDataGridUpdate = async (data:any) => {
        const errors = validateAddress(data.data.address);
        if (Object.keys(errors).length > 0) {
            Object.keys(errors).forEach(field => {
                toast.error(errors[field]);
            });
            return;
        }
        if(data.data.parentEntityID===null || data.data.parentEntityID===undefined)
            data.data.parentEntityID="";
        if (!data.data.partnershipEndDate || isNaN(Date.parse(data.data.partnershipEndDate))) {
            data.data.partnershipEndDate = ""; // Set to empty string if not a valid date
        }
        if(data.data.unitsClass===null || data.data.unitsClass===undefined)
            data.data.unitsClass="";
        if(data.data.unitsIssued===null || data.data.unitsIssued===undefined)
            data.data.unitsIssued=0;
        const result = await updateData(clientId,
            data.data,'id')
        if (result.success) {
            console.log('Data updated successfully:', result.data);
            toast.success("Data updated successfully!");
            refreshData();
            setLoading(false);
        } else {
            console.error('Failed to update data:', result.error);
            if (result.data) {
                toast.error(result.data.data);
            } else {
                toast.error("An error occurred while updating the data.", result.message);
            }

            console.error(result.error);
            setLoading(false);
        }
    };
    const onDataGridAdd = async (rowData: any) => {
        rowData.data.clientID = clientId;
        delete rowData.data.id;
        const errors = validateAddress(rowData.data.address);
        if (Object.keys(errors).length > 0) {
            Object.keys(errors).forEach(field => {
                toast.error(errors[field]);
            });
            return;
        }

        if(rowData.data.parentEntityID===null || rowData.data.parentEntityID===undefined)
            rowData.data.parentEntityID="";
        if (!rowData.data.partnershipEndDate || isNaN(Date.parse(rowData.data.partnershipEndDate))) {
            rowData.data.partnershipEndDate = ""; // Set to empty string if not a valid date
        }
        if(rowData.data.unitsClass===null || rowData.data.unitsClass===undefined)
            rowData.data.unitsClass="";
        if(rowData.data.unitsIssued===null || rowData.data.unitsIssued===undefined)
            rowData.data.unitsIssued=0;
        const result = await addData(rowData.data);

        if (result.success) {
            console.log('Data added successfully:', result.data);
            toast.success("Data added successfully!");
            refreshData();
            setLoading(false);
        } else {
            console.error('Failed to add data:', result.error);
            if (result.data) {
                toast.error(result.data.data);
            } else {
                toast.error("An error occurred while adding the data.", result.message);
            }

            console.error(result.error);
            setLoading(false);
        }
    };

    const getBreadcrumbItems  = () => {
        return breadcrumbItems;
    }

    const breadcrumbItems =
        [
            {
                "title": "Clients",
                "url": "Clients"
            }
        ]

    return (
        <div className="page-content">
            <ToastContainer />
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader/>}
                text='Loading data...'
            >
                <div className={"container-fluid"}>
                    <Breadcrumb items={getBreadcrumbItems()} title="Client" breadcrumbItem={selectedClient?.clientName + "(" + selectedClient?.clientID + ")"} />
                    <div style={{ width: '110%' }}>
                    <Card>
                        <CardBody>
                            <Row md={12}>
                                <Col md={6}>
                                    <label>
                                        Phone Number: &nbsp;
                                    </label>
                                    <span className="col-md-4">
                                        {selectedClient?.phoneNumber ? "" + selectedClient.phoneNumber : ""}
                                    </span>
                                </Col>
                                <Col md={6}>
                                    <label>
                                        Email: &nbsp;
                                    </label>
                                    <span className="col-md-4">
                                        {selectedClient?.email ? "" + selectedClient.email : ""}
                                    </span>
                                </Col>
                            </Row>
                            <Row md={12}>
                                <Col md={2}>
                                    <label>
                                        Street Address: &nbsp;
                                    </label>
                                    <span className="col-md-4">
                                        {selectedClient?.address.streetAddress ? "" + selectedClient.address.streetAddress : ""}
                                    </span>
                                </Col>
                                <Col md={2}>
                                    <label>
                                        City: &nbsp;
                                    </label>
                                    <span className="col-md-4">
                                        {selectedClient?.address.city ? "" + selectedClient.address.city : ""}
                                    </span>
                                </Col>
                                <Col md={2}>
                                    <label>
                                        State: &nbsp;
                                    </label>
                                    <span className="col-md-4">
                                        {selectedClient?.address.state ? "" + selectedClient.address.state : ""}
                                    </span>
                                </Col>
                                <Col md={2}>
                                    <label>
                                        Postal Code: &nbsp;
                                    </label>
                                    <span className="col-md-4">
                                        {selectedClient?.address.postalCode ? "" + selectedClient.address.postalCode : ""}
                                    </span>
                                </Col>
                                <Col md={2}>
                                    <label>
                                        Country: &nbsp;
                                    </label>
                                    <span className="col-md-4">
                                        {selectedClient?.address.country ? "" + selectedClient.address.country : ""}
                                    </span>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    </div>
                    <div className="container-fluid">
                        <DataGrid id={"gridPartnerships"} columns={partnershipColDef}
                                  data={partnershipData}
                                  editing={true} add={true} del={true} template={false}
                                  onAdd={onDataGridAdd} onDelete={onDataGridDelete} onUpdate={onDataGridUpdate}
                                  columnDataSources={partnershipColDataSources}
                                  pageType={"Partnership"}
                                  hasColumnDataSource={true}
                                  onRowClick={handleRowClick}
                                  gridTitle={"Partnerships"}
                        />
                    </div>
                </div>
            </LoadingOverlay>
        </div>
);
}

export default ClientHome;
