import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, BreadcrumbItem, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

const Breadcrumb = (props: any) => {
  const [menu, setMenu] = useState<any>(false);
  console.log(props)

    return (
        <Row>
            <Col className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h2 className="mb-sm-0 font-size-18">{props.breadcrumbItem}</h2>
                    {props.title &&
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                {props.items && props.items.map((item, index) => (
                                    <BreadcrumbItem key={index}>
                                        <Link to={item.url ? `/${item.url}` : "#"}>{item.title}</Link>
                                    </BreadcrumbItem>
                                ))}
                                <BreadcrumbItem>
                                    <span>{props.breadcrumbItem}</span>
                                </BreadcrumbItem>
                            </ol>
                        </div>}
                </div>
            </Col>
        </Row>
    );
};

export default Breadcrumb;
