import React, {useEffect, useState} from 'react';
import useApi from "../../hooks/useApi";
import {fetchRoles} from "../Admin/BL/roles";
import {toast, ToastContainer} from "react-toastify";
import LoadingOverlay from "react-loading-overlay-ts";
import BounceLoader from "react-spinners/BounceLoader";
import DataGrid from "../../Components/Common/DataGrid";
import {roleColumDefs} from "../../uihelper/gridutil";
const endpoints = {
    getAll: 'api/RbacManagement/Role',
    GetById: 'api/RbacManagement/Role',
    addData: 'api/RbacManagement/Role',
    updateData: `api/RbacManagement/Role`,
    deleteData: 'api/RbacManagement/Role',
};
const Roles = () => {
    const [rolesData, setRolesData] = useState<any[]>([]);
    const [baseUrl ] = useState<string>(process.env.REACT_APP_UIINTEGRATION_BASE_URL || '');
    const [txRoleId, setTxRoleId] = useState("");

    const { getDataByParams, addData, updateData, deleteData} = useApi(endpoints, baseUrl);
    const [loading, setLoading] = useState(false);

    const refreshData = () => {
        setLoading(true);
        Promise.all([
            fetchRoles(getDataByParams),
        ])
            .then(([result]) => {
                setLoading(false);
                if(result.success) {
                    if(result.data.length > 0) {
                        setRolesData(result.data);
                        toast.success("Data refreshed successfully!");
                    } else {
                        toast.error("No data found...")
                    }
                } else {
                    // Handle error from fetchData
                    toast.error("An error occurred while fetching the data: " + result.error);
                }
            })
            .catch(error => {
                toast.error("An error occurred while refreshing the data.");
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        refreshData();
    }, []);

    const onDataGridDelete = (rowData:any) => {
        deleteData(rowData.data[0].txModuleId,'id').then((response:any) => {
            toast.success("Data deleted successfully!");
            refreshData();
            setLoading(false);
        },error => {
            toast.error("An error occurred while deleting the data.");
            console.error(error);
            setLoading(false);
        });
    }
    // update grid data
    const onDataGridUpdate = (rowData:any) => {
        updateData(txRoleId,
            rowData.data,'id').then((response:any) => {
            toast.success("Data updated successfully!");
            refreshData();
            setLoading(false);
        },error => {
            toast.error("An error occurred while updating the data.");
            console.error(error);
            setLoading(false);
        });
    }

    // add data
    const onDataGridAdd = async (rowData: any) => {
        console.log("Inside onDataGridAdd",rowData.data)
        const result = await addData(rowData.data);

        if (result.success) {
            console.log('Data added successfully:', result.data);
            toast.success("Data added successfully!");
            refreshData();
            setLoading(false);
        } else {
            console.error('Failed to add data:', result.error);
            if (result.data) {
                toast.error(result.data.data);
            } else {
                toast.error("An error occurred while adding the data.", result.message);
            }

            console.error(result.error);
            setLoading(false);
        }
    };



    return (
        <div className="page-content">
            <ToastContainer />
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader  />}
                text='Loading data...'
            >
                <div className={"container-fluid"}>
                    <DataGrid  id={"gridRoles"} columns={roleColumDefs}
                               data={rolesData}
                               editing={true} add={true} del={true} template={false}
                               onAdd={onDataGridAdd} onDelete={onDataGridDelete} onUpdate={onDataGridUpdate}
                               gridTitle={"Roles"}
                    />
                </div>
            </LoadingOverlay>
        </div>
    );
}

export default Roles;
