import React, { useState, useEffect } from 'react';
import { DropDownListComponent, MultiSelectComponent, CheckBoxSelection, Inject } from '@syncfusion/ej2-react-dropdowns';
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-grids';
import { ToastContainer, toast } from "react-toastify";
import BounceLoader from "react-spinners/BounceLoader";
import LoadingOverlay from "react-loading-overlay-ts";
import useApi from "../../hooks/useApi";
import axios from 'axios';

const endpoints = {
    getAllUsers: 'api/UserManagement/users',
};

const cendpoints = {
    getAllClients: 'api/ClientManagement/Clients',
}

const pendpoints = {
    getAllPartnerships: 'Partnerships',
}

const rendpoints = {
    getAllUserClientMapping: 'api/RbacManagement/UserClientPartnershipMapping',
    saveUserClientMapping: 'api/RbacManagement/UserClientPartnershipMapping/CreateBatch',
    deleteUserClientMapping: 'api/RbacManagement/UserClientPartnershipMapping'
};

const UserClientMapping: React.FC = () => {
    const [users, setUsers] = useState<any[]>([]);
    const [clients, setClients] = useState<any[]>([]);
    const [partnerships, setPartnerships] = useState<any[]>([]);
    const [selectedUser, setSelectedUser] = useState<string | null>(null);
    const [selectedClients, setSelectedClients] = useState<number[]>([]);
    const [selectedPartnerships, setSelectedPartnerships] = useState<number[]>([]);
    const [combinedData, setCombinedData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [baseUrl] = useState<string>(process.env.REACT_APP_UIINTEGRATION_BASE_URL || '');
    const { getDataByParams } = useApi({ getAllUsers: endpoints.getAllUsers, getAllClients: cendpoints.getAllClients }, baseUrl);
    const [mappedData, setMappedData] = useState<any[]>([]);

    useEffect(() => {
        const fetchMappedData = async () => {
            try {
                setLoading(true);
                const usersResponse = await getDataByParams({ endpoint: rendpoints.getAllUserClientMapping });
                setMappedData(usersResponse.data);
            } catch (error) {
                console.error('Error fetching mapped data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchMappedData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const usersResponse = await getDataByParams({ endpoint: endpoints.getAllUsers });
                setUsers(usersResponse.data);
                const clientsResponse = await getDataByParams({ endpoint: cendpoints.getAllClients });
                setClients(clientsResponse.data);
            } catch (error) {
                console.error('Error fetching users and clients data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchPartnerships = async () => {
            try {
                if (selectedClients.length > 0) {
                    const partnershipPromises = selectedClients.map(async clientID => {
                        const response = await getDataByParams({ endpoint: `${pendpoints.getAllPartnerships}?ClientID=${clientID}` });
                        return response.data.map((partnership: any) => ({ ...partnership, clientID }));
                    });
                    const results = await Promise.all(partnershipPromises);
                    const flattenedPartnerships = results.flat();
                    setPartnerships(flattenedPartnerships);
                } else {
                    setPartnerships([]);
                }
            } catch (error) {
                console.error("Error fetching partnerships:", error);
            }
        };
        fetchPartnerships();
    }, [selectedClients]);

    useEffect(() => {
        const combined = selectedPartnerships.map(partnershipID => {
            const partnership = partnerships.find(p => p.partnershipID === partnershipID);
            const client = clients.find(client => client.clientID === partnership.clientID);
            return {
                clientID: client.clientID,
                clientName: client.clientName,
                partnershipID: partnership.partnershipID,
                partnershipName: partnership.partnershipName,
                userId: selectedUser,
                txClientId: client.id,
                txPartnershipId: partnership.id,
                txucpMappingId: 0,
                userName: users.find(user => user.id === selectedUser)?.username // Find username based on selectedUser ID
            };
        });
        setCombinedData(combined);
    }, [selectedPartnerships, partnerships, clients, selectedUser, users]);

    const handleUserChange = (event: any) => {
        setSelectedUser(event.value);
        setSelectedClients([]); // Clear selected clients when user changes
        setSelectedPartnerships([]); // Clear selected partnerships when user changes
        setCombinedData([]); // Clear combined data when user changes
    };

    const handleClientChange = (event: any) => {
        setSelectedClients(event.value);
        setSelectedPartnerships([]); // Clear selected partnerships when clients change
        setCombinedData([]); // Clear combined data when clients change
    };

    const handlePartnershipChange = (event: any) => {
        setSelectedPartnerships(event.value);
    };

    const handleSave = async () => {
        try {
            setLoading(true);
            if (combinedData.length === 0) {
                throw new Error("No data to save.");
            }
            await axios.post(`${baseUrl}${rendpoints.saveUserClientMapping}`, combinedData);
            toast.success("Data saved successfully!");
            const usersResponse = await getDataByParams({ endpoint: rendpoints.getAllUserClientMapping });
            setMappedData(usersResponse.data);
        } catch (error) {
            console.error('Error saving data:', error);
            toast.error("Failed to save data.");
        } finally {
            setLoading(false);
        }
    };
    const handleDelete = async (id: number) => {
        try {
            setLoading(true);
            await axios.delete(`${baseUrl}${rendpoints.deleteUserClientMapping}/${id}`);
            toast.success("Record deleted successfully!");
            const updatedMappedData = mappedData.filter((item) => item.txucpMappingId !== id);
            setMappedData(updatedMappedData);
        } catch (error) {
            console.error('Error deleting record:', error);
            toast.error("Failed to delete record.");
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="page-content">
            <ToastContainer />
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader />}
                text='Loading data...'
            >
                <div className="row">
                    <div className="col-md-3">
                        <label htmlFor="user-select">Select User:</label>
                        <DropDownListComponent
                            id="user-select"
                            dataSource={users}
                            fields={{ text: 'username', value: 'id' }}
                            placeholder="Select a user"
                            change={handleUserChange}
                            floatLabelType="Auto"
                        />
                    </div>

                    {selectedUser && (
                        <div className="col-md-3">
                            <label htmlFor="client-select">Select Clients:</label>
                            <MultiSelectComponent
                                id="client-select"
                                dataSource={clients}
                                fields={{ text: 'clientName', value: 'clientID' }}
                                placeholder="Select Clients"
                                change={handleClientChange}
                                mode="CheckBox"
                                showDropDownIcon={true}
                                floatLabelType="Auto"
                            >
                                <Inject services={[CheckBoxSelection]} /> {/* Inject CheckBoxSelection */}
                            </MultiSelectComponent>
                        </div>
                    )}

                    {selectedUser && (
                        <div className="col-md-3">
                            <label htmlFor="partnership-select">Select Partnerships:</label>
                            <MultiSelectComponent
                                id="partnership-select"
                                dataSource={partnerships}
                                fields={{ text: 'partnershipName', value: 'partnershipID' }}
                                placeholder="Select Partnerships"
                                change={handlePartnershipChange}
                                mode="CheckBox"
                                showDropDownIcon={true}
                                floatLabelType="Auto"
                            >
                                <Inject services={[CheckBoxSelection]} /> {/* Inject CheckBoxSelection */}
                            </MultiSelectComponent>
                        </div>
                    )}
                </div>
                {selectedUser && (
                    <div className="col-12 mt-4">
                        <button className="btn btn-primary" onClick={handleSave}>Save</button>
                    </div>
                )}
                <div className="col-12 mt-4">
                    <h3>Mapped Data</h3>
                    <GridComponent dataSource={mappedData} allowPaging={true}>
                        <ColumnsDirective>
                            <ColumnDirective field='userName' headerText='User Name' textAlign="Center" headerTextAlign="Center"/>
                            <ColumnDirective field='clientId' headerText='Client ID' textAlign="Center" headerTextAlign="Center"/>
                            <ColumnDirective field='clientName' headerText='Client Name' textAlign="Center" headerTextAlign="Center"/>
                            <ColumnDirective field='partnershipId' headerText='Partnership ID' textAlign="Center" headerTextAlign="Center"/>
                            <ColumnDirective field='partnershipName' headerText='Partnership Name' textAlign="Center" headerTextAlign="Center"/>
                            <ColumnDirective
                                headerText='Actions'
                                textAlign="Center"
                                headerTextAlign="Center"
                                template={(props: any) => (
                                    <div>
                                        <button
                                            className="e-btn e-flat"
                                            onClick={() => handleDelete(props.txucpMappingId)}
                                            title="Delete"
                                        >
                                            <span className="e-icons e-delete"></span>
                                        </button>
                                    </div>
                                )}
                            />
                        </ColumnsDirective>
                    </GridComponent>
                </div>
            </LoadingOverlay>
        </div>
    );
};

export default UserClientMapping;
