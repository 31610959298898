import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import useApi from "../../hooks/useApi";
import { fetchData } from "../../BL/ChartOfAccounts";
import { toast, ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay-ts";
import BounceLoader from "react-spinners/BounceLoader";
import DataGrid from "../../Components/Common/DataGrid";
import { chartOfAccColDef } from "../../uihelper/gridutil";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import '../../assets/css/custom.css';

import {chartOfAccountColDataSources} from "../../uihelper/columndatasources";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { validateAddress } from '../../helpers/validations_helper';

const endpoints = {
    getAll: 'api/ChartOfAccounts/ChartOfAccounts',
    getById: 'api/ChartOfAccounts/ChartOfAccounts',
    addData: 'api/ChartOfAccounts/ChartOfAccounts',
    updateData: 'api/ChartOfAccounts/ChartOfAccounts',
    deleteData: 'api/ChartOfAccounts/ChartOfAccounts',
};


const ChartOfAccounts = () => {
    const [chartOfAccData, setChartOfAccData] = useState<any[]>([]);
    const [baseUrl] = useState<string>(process.env.REACT_APP_UIINTEGRATION_BASE_URL || '');
    const { getDataByParams, addData, updateData, deleteData } = useApi(endpoints, baseUrl);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const [clientId, setClientId] = useLocalStorage("id", "");
    const [clientList, setClientList] = useLocalStorage("clients", "");
    const [selectedClient, setSelectedClient] = useLocalStorage("selectedClient", "");

    const refreshData = () => {
        setLoading(true);
        Promise.all([
            fetchData(getDataByParams, clientId),
        ])
            .then(([result]) => {
                if (result.success) {
                    if (result.data.length > 0) {
                        setChartOfAccData(result.data);
                        toast.success("Data refreshed successfully!");
                    } else {
                        setChartOfAccData([]);
                        toast.error("No data found...")
                    }
                } else {
                    // Handle error from fetchData
                    toast.error("An error occurred while fetching the data: " + result.error);
                }
                setLoading(false);
            })
            .catch(error => {
                toast.error("An error occurred while refreshing the data.");
                console.error(error);
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        refreshData();
    }, [clientId]);
    const onDataGridUpdate = async (data:any) => {
        const result = await updateData(clientId,
            data.data,'id')
        if (result.success) {
            console.log('Data updated successfully:', result.data);
            toast.success("Data updated successfully!");
            refreshData();
            setLoading(false);
        } else {
            console.error('Failed to update data:', result.error);
            if (result.data) {
                toast.error(result.data.data);
            } else {
                toast.error("An error occurred while updating the data.", result.message);
            }

            console.error(result.error);
            setLoading(false);
        }
    };
    const onDataGridAdd = async (rowData: any) => {
        rowData.data.clientID = clientId;
        delete rowData.data.id;

        const result = await addData(rowData.data);

        if (result.success) {
            console.log('Data added successfully:', result.data);
            toast.success("Data added successfully!");
            refreshData();
            setLoading(false);
        } else {
            console.error('Failed to add data:', result.error);
            if (result.data) {
                toast.error(result.data.data);
            } else {
                toast.error("An error occurred while adding the data.", result.message);
            }

            console.error(result.error);
            setLoading(false);
        }
    };
    const onDataGridDelete = (data:any) => {
        console.log(data.data)
        deleteData(data?.data[0]?.id,'id').then((response:any) => {
            toast.success("Data deleted successfully!");
            refreshData();
            setLoading(false);
        },error => {
            toast.error("An error occurred while deleting the data.");
            console.error(error);
            setLoading(false);
        });
    };

    const fields = { text: 'clientName', value: 'clientID' };
    const onClientChange = (args: any) => {
        setClientId(args.itemData.clientID);
    }

    // Define your getBreadcrumbItems function here

    return (
        <div className="page-content">
            <ToastContainer />
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader />}
                text='Loading data...'
            >
                <div className={"container-fluid"}>

                    <div className="col-3">
                        <DropDownListComponent id="atcelement" dataSource={clientList} fields={fields}
                                               allowFiltering={true}
                                               value={clientId} placeholder="Select Client" change={onClientChange}
                                               floatLabelType="Auto"
                        />
                    </div>
                    <br />
                    <DataGrid id={"gridChartOfAcc"} columns={chartOfAccColDef}
                              data={chartOfAccData} editing={true} add={true} del={true} template={false}
                              onAdd={onDataGridAdd} onDelete={onDataGridDelete} onUpdate={onDataGridUpdate}
                              hasColumnDataSource={true}

                              columnDataSources={chartOfAccountColDataSources}
                              pageType={"ChartOfAccount"}


                    />
                </div>
            </LoadingOverlay>
        </div>
    );
}

export default ChartOfAccounts;
