import * as signalR from "@microsoft/signalr";
import {HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import axios from 'axios';
class SignalRService {
    public connection?: signalR.HubConnection ;
    private  signalREndpoint:any = "http[://localhost:5001/calcHub";//process.env.REACT_APP_SIGNALR_ENDPOINT;
    private static instance: SignalRService;

    public constructor() {
        this.initializeConnection();
    }


    private async initializeConnection() {
        try {
            const response = await axios.post('https://caliculationorchestrationhubfunction.azurewebsites.net/api/negotiate');
            console.log(response);
            const data = response;
            console.log("Data from signalr auth is ... ",data);
            this.connection = new signalR.HubConnectionBuilder()
                .withUrl(data.url, { accessTokenFactory: () => data.accessToken })
                .configureLogging(signalR.LogLevel.Debug)
                .withAutomaticReconnect()
                .build();

            this.connection.start().then(r => {
                console.warn("Started connection")
            }).catch(err => console.error('Error while starting connection: ' + err));
        } catch (err) {
            console.error('Error while initializing connection: ' + err);
        }
    }
 
    public async reinitializeConnection() {
        // Stop the current connection if it exists
        if (this.connection) {
            await this.connection.stop();
        }

        // Initialize a new connection
        this.initializeConnection();
    }

    
    public async stopConnection() {
        try {
            await this.connection?.stop();
            console.log('SignalR Disconnected.');
        } catch (err) {
            console.log(err);
        }
    }
  
    public registerMethod(methodName: string, newMethod: (...args: any[]) => void) {
        this.connection?.on(methodName, newMethod);
    }

    public removeMethod(methodName: string) {
        this.connection?.off(methodName);
    }
}
export  default  SignalRService;
//export const signalRService = SignalRService.getInstance();
