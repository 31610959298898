import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay-ts';
import BounceLoader from 'react-spinners/BounceLoader';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useApi from '../../hooks/useApi';
import './FileGrid.css';

const endpoints = {
    GetAllFiles: 'api/PartnershipCreation/GetAllFiles',
    GetFile: 'api/PartnershipCreation/GetFile'
};

interface FileItem {
    original: string;
    display: string;
}


const Logs= () => {
    const [fileList, setFileList] = useState<FileItem[]>([]); // Use FileItem type
    const [baseUrl] = useState<string>(process.env.REACT_APP_UIINTEGRATION_BASE_URL || '');
    const [loading, setLoading] = useState(false);

    const { submitMultipartForm } = useApi(endpoints, baseUrl);

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const response = await fetch(`${baseUrl}${endpoints.GetAllFiles}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch files');
                }
                const files: string[] = await response.json();
                // Process file names to create FileItem objects
                const fileItems: FileItem[] = files.map(fileName => ({
                    original: fileName,
                    display: removeGuid(fileName)
                }));
                setFileList(fileItems);
            } catch (error) {
                console.error('Error fetching files:', error);
                toast.error('Error fetching files.');
            }
        };

        fetchFiles();
    }, [baseUrl, endpoints]);
    const removeGuid = (fileName: string) => {
        // Remove GUID from the file name
        return fileName.replace(/^[^_]+_/, '');
    };

    const handleDownload = async (originalFileName: string) => {
        try {
            const response = await fetch(`${baseUrl}${endpoints.GetFile}?fileName=${encodeURIComponent(originalFileName)}`);
            if (!response.ok) {
                throw new Error('Failed to download file');
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = originalFileName; // Download using the original file name
            link.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
            toast.error('Error downloading file.');
        }
    };

    return (
        <div className="page-content">
            <ToastContainer />
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader />}
                text='Loading data...'
            >
                <Row className="mb-3">
                <Col xs={12}>
                    <h3>Logs:</h3>
                </Col>
            </Row>
            <Row>
                {loading ? (
                    <Col xs={12} className="text-center">
                        <div>Loading...</div>
                    </Col>
                ) : (
                    fileList.length > 0 ? (
                        <Col xs={12}>
                            <div className="file-grid">
                                {fileList.map(file => (
                                    <div key={file.original} className="file-grid-item"> {/* Use file.original as the key */}
                                        <div className="file-name">{file.display}</div> {/* Display cleaned file name */}
                                        <div className="file-action">
                                            <button className="btn btn-secondary" onClick={() => handleDownload(file.original)}>
                                                Download
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Col>
                    ) : (
                        <Col xs={12} className="text-center">
                            <div>No Logs available.</div>
                        </Col>
                    )
                )}
            </Row>
            </LoadingOverlay>
        </div>
    );
};

export default Logs;
