import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../Components/Common/Breadcrumb";
import DataGrid from "../../Components/Common/DataGrid";
import {revalBigbilOverrideColDef} from "../../uihelper/gridutil";
import {fetchData} from '../../BL/RevalBigbilOverride';
import LoadingOverlay from 'react-loading-overlay-ts';
import BounceLoader from 'react-spinners/BounceLoader'
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useApi from "../../hooks/useApi";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import '../../assets/css/custom.css';
import {validateAddress} from '../../helpers/validations_helper';
import RevalAssetOverride from "./RevalAssetOverride";
import {contributionColDataSources} from "../../uihelper/columndatasources";

const endpoints = {
    getAll: 'api/OverrideManagement/RevalBigbilOverride',
    getById: 'api/OverrideManagement/RevalBigbilOverride',
    addData: 'api/OverrideManagement/RevalBigbilOverride',
    updateData: 'api/OverrideManagement/RevalBigbilOverride',
    deleteData: 'api/OverrideManagement/RevalBigbilOverride',
};

const RevalBigbilOverride = () => {
    const [revalBigbilOverrideData, setRevalBigbilOverrideData] = useState<any[]>([]);
    const [baseUrl ] = useState<string>(process.env.REACT_APP_UIINTEGRATION_BASE_URL || '');

    const { getDataByParams, addData, updateData, deleteData} = useApi(endpoints, baseUrl);
    const [loading, setLoading] = useState(false);

    const [clientId, setClientId] = useLocalStorage("id", "");
    const [partnershipId, setPartnershipId] = useLocalStorage("pid", "");
    const [selectedClient, setSelectedClient] = useLocalStorage("selectedClient", "");
    const [selectedPartnership, setSelectedPartnership] = useLocalStorage("selectedPartnership", "");

    const refreshData = () => {
        setLoading(true);
        fetchData(getDataByParams, clientId, partnershipId)
            .then(result => {
                setLoading(false);
                if(result.success) {
                    if(result.data && result.data.length > 0) {
                        setRevalBigbilOverrideData(result.data);
                        // toast.success("Data refreshed successfully!");
                    } else {
                        toast.error("No data found...")
                    }
                } else {
                    // Handle error from fetchData
                    toast.error("An error occurred while fetching the data: " + result.error);
                }
            })
            .catch(error => {
                toast.error("An error occurred while refreshing the data.");
                console.error(error);
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        refreshData();
    }, []);

    const getBreadcrumbItems  = () => {
        breadcrumbItems.push({title: selectedClient.clientName + " (" + selectedClient.clientID + ")", url: "clienthome"});
        breadcrumbItems.push({title: selectedPartnership.partnershipName + " (" + selectedPartnership.partnershipID + ")", url: "partnershiphome"});
        return breadcrumbItems;
    }

    const breadcrumbItems =
        [
            {
                "title": "Clients",
                "url": "Clients"
            }
        ]

    const onDataGridDelete = (data:any) => {
        console.log(data.data)
        deleteData(data?.data[0]?.id,'id').then((response:any) => {
            toast.success("Data deleted successfully!");
            refreshData();
            setLoading(false);
        },error => {
            toast.error("An error occurred while deleting the data.");
            console.error(error);
            setLoading(false);
        });
    };

    // update grid data
    const onDataGridUpdate = async (data:any) => {
        if(data.data.assetID===null || data.data.assetID===undefined)
            data.data.assetID="";
        /*if(data.data.fractionOfContribution===null || data.data.fractionOfContribution===undefined)
            data.data.fractionOfContribution="";
        if(data.data.unitsAssigned===null || data.data.unitsAssigned===undefined)
            data.data.unitsAssigned="";*/
        if(data.data.unitsClass===null || data.data.unitsClass===undefined)
            data.data.unitsClass="";
        const result = await updateData(clientId,
            data.data,'id')
        if (result.success) {
            console.log('Data updated successfully:', result.data);
            toast.success("Data updated successfully!");
            refreshData();
            setLoading(false);
        } else {
            console.error('Failed to update data:', result.error);
            if (result.data) {
                toast.error(result.data.data);
            } else {
                toast.error("An error occurred while updating the data.", result.message);
            }

            console.error(result.error);
            setLoading(false);
        }
    }

    // add data
    const onDataGridAdd = async (rowData: any) => {
        rowData.data.clientID = clientId;
        rowData.data.partnershipID = partnershipId;

        delete rowData.data.id;
        if (rowData.data.assetID === null || rowData.data.assetID === undefined)
            rowData.data.assetID = "";
        if (rowData.data.unitsClass === null || rowData.data.unitsClass === undefined)
            rowData.data.unitsClass = "";

        const result = await addData(rowData.data);

        if (result.success) {
            console.log('Data added successfully:', result.data);
            toast.success("Data added successfully!");
            refreshData();
            setLoading(false);
        } else {
            console.error('Failed to add data:', result.error);
            if (result.data) {
                toast.error(result.data.data);
            } else {
                toast.error("An error occurred while adding the data.", result.message);
            }

            console.error(result.error);
            setLoading(false);
        }

    };

    return (
        <div className="page-content_overrides">
            <ToastContainer />
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader  />}
                text='Loading data...'
            >
                <div className={"container-fluid"}>
                    <Breadcrumb items={getBreadcrumbItems()} title={selectedClient.clientName} breadcrumbItem="" />
                    <DataGrid  id={"gridPartnerContr"} columns={revalBigbilOverrideColDef}
                               data={revalBigbilOverrideData}
                               editing={true} add={true} del={true} template={false}
                               onAdd={onDataGridAdd} onDelete={onDataGridDelete} onUpdate={onDataGridUpdate}
                               hasColumnDataSource={false}
                               pageType={"RevalBigBilOverride"}
                               hasExport={true}
                               pageName="RevaluationBigBilOverrides"
                    />
                </div>
            </LoadingOverlay>
        </div>
    );
}

export default RevalBigbilOverride;
