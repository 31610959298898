import { GetDataParams } from '../hooks/useApi';


export const fetchData = async (getDataByParams: (params: GetDataParams) => Promise<any>, clientId: string) => {
    let endpointUrl = "api/ChartOfAccounts/ChartOfAccounts";
    try {
        const { success, data, error } = await getDataByParams({
            endpoint: endpointUrl,
            params: {
                clientId: clientId,
            },
        });
        if (!success) {
            // Handle error
            console.error(error);
            return { success: false, error };
        } else {
            // Use data
            console.log(data);
            return { success: true, data };
        }
    } catch (error:any) {
        console.error(error);
        return { success: false, error: error.message };
    }
};
