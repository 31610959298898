import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../Components/Common/Breadcrumb";
import DataGrid from "../../Components/Common/DataGrid";
import {revaluationColDef} from "../../uihelper/gridutil";
import {fetchData} from '../../BL/Revaluation';
import LoadingOverlay from 'react-loading-overlay-ts';
import BounceLoader from 'react-spinners/BounceLoader'
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useApi from "../../hooks/useApi";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import '../../assets/css/custom.css';
import {revaluationsColDataSources} from "../../uihelper/columndatasources";

const endpoints = {
    getAll: 'api/AssetManagement/Revaluations',
    getById: 'api/AssetManagement/Revaluations',
    addData: 'api/AssetManagement/Revaluations',
    updateData: 'api/AssetManagement/Revaluations',
    deleteData: 'api/AssetManagement/Revaluations',
};

const Revaluation = () => {
    const [revaluationData, setRevaluationData] = useState<any[]>([]);
    const [baseUrl ] = useState<string>(process.env.REACT_APP_UIINTEGRATION_BASE_URL || '');

    const { getDataByParams, addData, updateData, deleteData} = useApi(endpoints, baseUrl);
    const [loading, setLoading] = useState(false);

    const [clientId, setClientId] = useLocalStorage("id", "");
    const [partnershipId, setPartnershipId] = useLocalStorage("pid", "");
    const [selectedClient, setSelectedClient] = useLocalStorage("selectedClient", "");
    const [selectedPartnership, setSelectedPartnership] = useLocalStorage("selectedPartnership", "");

    const refreshData = () => {
        setLoading(true);
        fetchData(getDataByParams, clientId, partnershipId)
            .then(result => {
                setLoading(false);
                if(result.success) {
                    if(result.data && result.data.length > 0) {
                        setRevaluationData(result.data);
                        // toast.success("Data refreshed successfully!");
                    } else {
                        toast.error("No data found...")
                    }
                } else {
                    // Handle error from fetchData
                    toast.error("An error occurred while fetching the data: " + result.error);
                }
            })
            .catch(error => {
                toast.error("An error occurred while refreshing the data.");
                console.error(error);
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        refreshData();
    }, []);

    const getBreadcrumbItems  = () => {
        breadcrumbItems.push({title: selectedClient.clientName + " (" + selectedClient.clientID + ")", url: "clienthome"});
        breadcrumbItems.push({title: selectedPartnership.partnershipName + " (" + selectedPartnership.partnershipID + ")", url: "partnershiphome"});
        return breadcrumbItems;
    }

    const breadcrumbItems =
        [
            {
                "title": "Clients",
                "url": "Clients"
            }
        ]

    const onDataGridDelete = (data:any) => {
        console.log(data.data)
        deleteData(data?.data[0]?.id,'id').then((response:any) => {
            toast.success("Data deleted successfully!");
            refreshData();
            setLoading(false);
        },error => {
            toast.error("An error occurred while deleting the data.");
            console.error(error);
            setLoading(false);
        });
    };

    // update grid data
    const onDataGridUpdate = async (data:any) => {
        if(data.data.depreciationAllocationMethod===null || data.data.depreciationAllocationMethod===undefined)
            data.data.depreciationAllocationMethod="";
        const result = await updateData(clientId,
            data.data,'id')
        if (result.success) {
            console.log('Data updated successfully:', result.data);
            toast.success("Data updated successfully!");
            refreshData();
            setLoading(false);
        } else {
            console.error('Failed to update data:', result.error);
            if (result.data) {
                toast.error(result.data.data);
            } else {
                toast.error("An error occurred while updating the data.", result.message);
            }

            console.error(result.error);
            setLoading(false);
        }
    }

    // add data
    const onDataGridAdd = async (rowData: any) => {
        rowData.data.clientID = clientId;
        rowData.data.partnershipID = partnershipId;
        if(rowData.data.depreciationAllocationMethod===null || rowData.data.depreciationAllocationMethod===undefined)
            rowData.data.depreciationAllocationMethod="";
        delete rowData.data.id;
        const result = await addData(rowData.data);

        if (result.success) {
            console.log('Data added successfully:', result.data);
            toast.success("Data added successfully!");
            refreshData();
            setLoading(false);
        } else {
            console.error('Failed to add data:', result.error);
            if (result.data) {
                toast.error(result.data.data);
            } else {
                toast.error("An error occurred while adding the data.", result.message);
            }

            console.error(result.error);
            setLoading(false);
        }

    };

    return (
        <div className="page-content">
            <ToastContainer />
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader  />}
                text='Loading data...'
            >
                <div className={"container-fluid"}>
                    <Breadcrumb items={getBreadcrumbItems()} title={selectedClient.clientName} breadcrumbItem="Revaluations" />
                    <DataGrid  id={"gridPartnerContr"} columns={revaluationColDef}
                               data={revaluationData}
                               editing={true} add={true} del={true} template={false}
                               onAdd={onDataGridAdd} onDelete={onDataGridDelete} onUpdate={onDataGridUpdate}
                               hasColumnDataSource={true} columnDataSources={revaluationsColDataSources}
                               pageType={"Revaluations"}
                               hasExport={true}
                               pageName="Revaluations"
                    />
                </div>
            </LoadingOverlay>
        </div>
    );
}

export default Revaluation;
