import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './App.css';
import { authProtectedRoutes, publicRoutes } from './Routes/allRoutes';
import { Route, Routes } from 'react-router-dom';
import VerticalLayout from './Layouts/VerticalLayout';
import HorizontalLayout from './Layouts/HorizontalLayout/index';
import './assets/scss/theme.scss';
import NonAuthLayout from './Layouts/NonLayout';
import { registerLicense } from '@syncfusion/ej2-base';
import { LAYOUT_TYPES } from './Components/constants/layout';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import AuthProtected from './Routes/AuthProtected';
import LoginPage from './pages/LoginPage';
import SessionExpiredCard from './Components/Common/SessionExpiredCard';
import { AuthProvider, useAuth } from './context/AuthContext';
import eventEmitter from './context/eventEmitter';
import { sessionExpired } from './slices/auth/login/reducer';
import { handleCallback } from "./slices/auth/login/thunk";
import LoadingOverlay from "react-loading-overlay-ts";
import {SignoutPopupArgs, SignoutRedirectArgs, UserManager} from "oidc-client-ts";
import {oidcConfig} from "./helpers/keycloakHelper";

registerLicense('Ngo9BigBOggjHTQxAR8/V1NAaF5cWWJCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxecXRWQmddVkR/X0Q=');

const getLayout = (layoutType: any) => {
    let Layout = VerticalLayout;
    switch (layoutType) {
        case LAYOUT_TYPES.VERTICAL:
            Layout = VerticalLayout;
            break;
        case LAYOUT_TYPES.HORIZONTAL:
            Layout = HorizontalLayout;
            break;
        default:
            break;
    }
    return Layout;
};

function App() {
    const [sessionHasExpired, setSessionHasExpired] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const { simulateTokenExpiry } = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userManager = new UserManager(oidcConfig);
    const selectLeadData = createSelector(
        (state: any) => state.Layout,
        (layoutTypes) => layoutTypes
    );
    const { layoutTypes } = useSelector(selectLeadData);

    const Layout = getLayout(layoutTypes);

    useEffect(() => {
        if (window.location.pathname === '/callback') {
            dispatch<any>(handleCallback(navigate));
        }
    }, [dispatch, navigate]);
    const handleTokenExpired = () => {
        //setLoading(true);
    //    console.warn('Access token expired.........................................');
        // settimeout for 1 sec

        setSessionHasExpired( prevState => true);
        //  setLoading(false);
    };

    useEffect(() => {

        eventEmitter.on('tokenExpired', handleTokenExpired);

        return () => {
            eventEmitter.off('tokenExpired', handleTokenExpired);
        };
    }, []);

    const handleRelogin = async () => {
        console.log('Relogin button clicked');
        setSessionHasExpired(false); // Set to false to hide the expired card before redirecting
        dispatch(sessionExpired());

       // navigate('/login'); // Navigate to a login route to ensure the user is logged out
        let popupArgs:SignoutRedirectArgs = {
            id_token_hint: '',
            post_logout_redirect_uri: window.location.origin + '/login',


        }
        await userManager.signoutRedirect(popupArgs);

        await userManager.removeUser();
        await userManager.signinRedirect();
    };

    return (
        <React.Fragment>
            {sessionHasExpired ? (
                <SessionExpiredCard onRelogin={handleRelogin} />
            ) : (
                <div>
                    {/*<LoadingOverlay active={isLoading} spinner text='Session Check'>*/}
                    {/*    <div>Checking session expiry...</div>*/}
                    {/*</LoadingOverlay>*/}

                    <Routes>
                        <Route path='/callback' element={<div>Loading...</div>} />
                        <Route path='/login' element={<LoginPage />} />
                        {publicRoutes.map((route, idx) => (
                            <Route
                                path={route.path}
                                key={idx}
                                element={<NonAuthLayout>{route.component}</NonAuthLayout>}
                            />
                        ))}
                        {authProtectedRoutes.map((route, idx) => (
                            <Route
                                path={route.path}
                                key={idx}
                                element={
                                    <React.Fragment>
                                        <AuthProtected>
                                            <Layout>{route.component}</Layout>
                                        </AuthProtected>
                                    </React.Fragment>
                                }
                            />
                        ))}
                    </Routes>

                </div>
            )}
        </React.Fragment>
    );
}

const AppWithProvider = () => (
    <AuthProvider>
        <App />
    </AuthProvider>
);

export default AppWithProvider;
