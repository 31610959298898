import React, { useEffect, useRef, useCallback, useState } from "react";
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import withRouter from "../../Components/Common/withRouter";
import { useLocalStorage } from "../../hooks/useLocalStorage";

const SidebarContent = (props) => {
  const ref = useRef<any>();
  const [partnershipID, setPartnershipID] = useLocalStorage("pid", "");
  const location = useLocation();
  const [isPartnershipsOpen, setIsPartnershipsOpen] = useState(false);
  const navigate = useNavigate();
  const [partnerID, setPartnerID] = useLocalStorage("prid", "");
  const [isPartnersOpen, setIsPartnersOpen] = useState(false);
  const [isRevaluationsOpen, setIsRevaluationsOpen] = useState(false);
  const [revaluationID, setRevaluationID] = useLocalStorage("rid", "");
  const [initialPartnershipNavigation, setInitialPartnershipNavigation] = useState(false);

  useEffect(() => {
    if (location.pathname === "/partnershiphome") {
      const params = new URLSearchParams(location.search);
      const pid = params.get("partnershipId");
      if (pid) {
        setPartnershipID(pid);
        setIsPartnershipsOpen(true);
        setInitialPartnershipNavigation(true);
      }
    }
  }, [location.pathname, partnershipID, setPartnershipID]);

  useEffect(() => {
    if (location.pathname === "/revaluation") {
      const params = new URLSearchParams(location.search);
      const rid = params.get("revaluationID");
      if (rid) {
        setRevaluationID(rid);
      }
      setIsRevaluationsOpen(true);
    } else {
      setIsRevaluationsOpen(false);
    }
  }, [location.pathname, setRevaluationID]);

  useEffect(() => {
    if (location.pathname === "/partnerhome") {
      const params = new URLSearchParams(location.search);
      const prid = params.get("partnerId");
      if (prid) {
        setPartnerID(prid);
      }
      setIsPartnersOpen(true);
    }
  }, [location.pathname, partnerID, setPartnerID]);

  const handleTextClick = (path) => {
    navigate(path);
  };

  const handleArrowClick = (e, setOpen, isOpen) => {
    if (initialPartnershipNavigation) {
      e.preventDefault();
      setOpen(!isOpen);
    }
  };

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    let parent = item.parentElement;

    while (parent && parent !== document.body) {
      if (parent.tagName === "UL") {
        parent.classList.add("mm-show");
        const parentLink = parent.previousElementSibling;
        if (parentLink && parentLink.tagName === "A") {
          parentLink.classList.add("mm-active");
        }
      }
      parent = parent.parentElement;
    }
    scrollElement(item);
  }, []);

  const removeActivation = (items) => {
    for (let i = 0; i < items.length; ++i) {
      const item = items[i];
      const parent = item.parentElement;

      if (item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent && parent.tagName === "UL") {
        parent.classList.remove("mm-show");
        const parentLink = parent.previousElementSibling;
        if (parentLink && parentLink.tagName === "A") {
          parentLink.classList.remove("mm-active");
        }
      }
    }
  };

  const activeMenu = useCallback(() => {
    const pathName = process.env.PUBLIC_URL + props.router.location.pathname;
    const ul = document.getElementById("side-menu");
    if (ul) {
      const items = ul.getElementsByTagName("a");
      removeActivation(items);

      let matchingMenuItem: HTMLAnchorElement | null = null;
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    }
  }, [props.router.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current?.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    activeMenu();
  }, [activeMenu]);

  const scrollElement = (item) => {
    const currentPosition = item.offsetTop;
    const currentRef = ref.current;
    if (currentRef && currentPosition > window.innerHeight) {
      const scrollElement = currentRef.getScrollElement();
      if (scrollElement) {
        scrollElement.scrollTop = currentPosition - 300;
      }
    }
  };

  return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{props.t("Menu")}</li>
              <ul className="sidebar-menu">
                <li>
                  <Link to="/clients">{props.t("Clients")}</Link>
                </li>
                <li className={isPartnershipsOpen ? "mm-active" : ""}>
                  <Link
                      to="/partnerships"
                      onClick={() => handleTextClick("/partnerships")}
                      style={{ textDecoration: "none", display: "flex", alignItems: "center" }}
                  >
                    <span style={{ marginRight: "34px" }}>{props.t("Partnerships")}</span>
                    <i
                        className={isPartnershipsOpen ? "bx bx-chevron-up" : "bx bx-chevron-down"}
                        onClick={(e) => handleArrowClick(e, setIsPartnershipsOpen, isPartnershipsOpen)}
                        style={{ fontSize: "18px", lineHeight: "18px", marginLeft: "36px" }}
                    ></i>
                  </Link>
                  {isPartnershipsOpen && partnershipID !== undefined && partnershipID !== "" && (
                      <ul className="sidebar-menu">
                        <li>
                          <Link to="/partners">{props.t("Partners")}</Link>
                        </li>
                        <li>
                          <Link to="/contributions">{props.t("Contributions")}</Link>
                        </li>
                        <li>
                          <Link to="/transfers">{props.t("Transfers")}</Link>
                        </li>
                        <li>
                          <Link to="/redemptions">{props.t("Redemptions")}</Link>
                        </li>
                        <li>
                          <Link to="/distributions">{props.t("Distributions")}</Link>
                        </li>
                        <li>
                          <Link to="/distributiongroup">{props.t("DistributionGroup")}</Link>
                        </li>
                        <li>
                          <Link to="/assets">{props.t("Assets")}</Link>
                        </li>
                        <li>
                          <Link to="/income-expense">{props.t("Income/Expense")}</Link>
                        </li>
                        <li>
                          <Link to="/allocations">{props.t("Allocation Groups")}</Link>
                        </li>
                        <li className={isRevaluationsOpen ? "mm-active" : ""}>
                          <Link
                              to="/revaluation"
                              onClick={() => handleTextClick("/revaluation")}
                              style={{ textDecoration: "none", display: "flex", alignItems: "center" }}
                          >
                            <span style={{ marginRight: "36px" }}>{props.t("Revaluations")}</span>
                            <i
                                className={isRevaluationsOpen ? "bx bx-chevron-up" : "bx bx-chevron-down"}
                                onClick={(e) => handleArrowClick(e, setIsRevaluationsOpen, isRevaluationsOpen)}
                                style={{ fontSize: "16px", lineHeight: "16px", marginLeft: "36px" }}
                            ></i>
                          </Link>
                          {isRevaluationsOpen && (
                              <ul className="sub-menu">
                                <li>
                                  <Link to="/revaluationOverrides">{props.t("Revaluation Overrides")}</Link>
                                </li>
                              </ul>
                          )}
                        </li>
                        <li>
                          <Link to="/assetFMV">{props.t("Asset FMV")}</Link>
                        </li>
                        <li>
                          <Link to="/rollovers">{props.t("Rollovers")}</Link>
                        </li>
                      </ul>
                  )}
                </li>
                <li>
                  <Link to="/Overrides" className="has-arrow">
                    <span>{props.t("Overrides")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/avOverride">{props.t("Asset Valuations")}</Link>
                    </li>
                    <li>
                      <Link to="/pcOverride">{props.t("Partner Capital")}</Link>
                    </li>
                    <li>
                      <Link to="/piOverride">{props.t("Partner Interests")}</Link>
                    </li>
                    <li>
                      <Link to="/pbOverride">{props.t("Partner BigBil")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/form-master">{props.t("Form Master")}</Link>
                </li>
                <li>
                  <Link to="/imports">{props.t("Imports")}</Link>
                </li>
                <li>
                  <Link to="/calculations">{props.t("Calculations")}</Link>
                </li>
                <li>
                  <Link to="/results">{props.t("Calculation Results")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Reports")}</Link>
                </li>
                <li>
                  <Link to="/CharterOfAccounts" className="has-arrow">
                    <span>{props.t("Chart Of Accounts")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/accounts">{props.t("Accounts")}</Link>
                    </li>
                    <li>
                      <Link to="/form-mapping">{props.t("Form Mapping")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/Admin" className="has-arrow">
                    <span>{props.t("Admin")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/users">{props.t("Users")}</Link>
                    </li>
                    <li>
                      <Link to="/role-permissions">{props.t("Role Permissions")}</Link>
                    </li>
                    <li>
                      <Link to="/user-client-mapping">{props.t("UserClientMapping")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/data-manager">{props.t("Data Manager")}</Link>
                </li>
                {/*<li>
                  <Link to="/logs">{props.t("Logs")}</Link>
                </li>*/}
              </ul>
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
  );
};
export default withRouter(withTranslation()(SidebarContent));
