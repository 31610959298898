import React, {useEffect, useRef, useState, ChangeEvent} from 'react';
import {Col} from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay-ts';
import BounceLoader from 'react-spinners/BounceLoader'
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useApi from '../../hooks/useApi';
import DataViewGrid from "../../Components/Common/DataViewGrid";
import {importResultsColDef} from "../../uihelper/gridutil";
import DataHelper from "../../helpers/DataHelper";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import {DropDownListComponent} from "@syncfusion/ej2-react-dropdowns";
import { Workbook } from '@fortune-sheet/react';
import '@fortune-sheet/react/dist/index.css';
import {transformExcelToFortune} from "@zenmrp/fortune-sheet-excel";
import { v4 as uuidv4 } from 'uuid';

const endpoints = {
    getAll: '/api/items',
    getById: '/api/items/{id}',
    addData: '/api/items',
    updateData: '/api/items/{id}',
    deleteData: '/api/items/{id}',
    uploadFile: '/api/items/{id}/upload',
};

enum OperationType {
    PartnershipCreation = 'PartnershipCreation',
    Overrides = 'Overrides'
}

const Imports = () => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [selectedOperationType, setSelectedOperationType] = useState<OperationType>(OperationType.PartnershipCreation);

    const [clientId, setClientId] = useLocalStorage("id", "");
    const [clientList, setClientList] = useLocalStorage("clients", "");
    const [calculationsBaseUrl] = useState<string>(process.env.REACT_APP_UIINTEGRATION_BASE_URL || '');
    const [importType, setImportType] = useState<OperationType>(OperationType.PartnershipCreation);
    const [loading, setLoading] = useState(false);
    const [importResults, setImportResults] = useState<any[]>([]);
    const [sheets, setSheets] = useState<any[]>([]);
    const [key, setKey] = useState(0);
    const [excelError, setExcelError] = useState<string | null>(null);
    const {submitMultipartForm, getDataByParams} = useApi(endpoints, calculationsBaseUrl);
    const [ws, setWs] = useState<WebSocket | null>(null);
    const [fileUploaded, setFileUploaded] = useState(false); // New state to track file upload

    // Generate a session ID
    const sessionId = useRef(uuidv4()).current;
    const wsUrl = `${process.env.REACT_APP_UIINTEGRATION_WSS_BASE_URL}?sessionId=${sessionId}`;
    console.warn("WSS URL is ..... ",wsUrl);

    // Initialize WebSocket connection
    useEffect(() => {
        const ws = new WebSocket(wsUrl);
        setWs(ws);

        ws.onerror = (event) => {
            console.error('WebSocket error:', event);
        }

        ws.onmessage = async (event) => {
            console.log('WebSocket message:', event.data);

            if (fileUploaded) {
                const data = JSON.parse(event.data);
                console.log("Web Socket Data Is.....",data.InvalidFileName)
                if (data.Status === 'Success' && data.InvalidFileName) {
                    await fetchAndDisplayFile(data.InvalidFileName);
                    setFileUploaded(false);
                }
            }
        };

        return () => {
            ws.close();
        };
    }, []);
    // WebSocket URL with session ID



    // useEffect(() => {
    //     if (fileUploaded && ws) {
    //         ws.onmessage = async (event) => {
    //             const data = JSON.parse(event.data);
    //             console.log("Web Socket Data Is.....",data.InvalidFileName)
    //             if (data.Status === 'Success' && data.InvalidFileName) {
    //                 await fetchAndDisplayFile(data.InvalidFileName);
    //                 setFileUploaded(false);
    //             }
    //         };
    //     }
    //
    //     return () => {
    //         if (ws) {
    //             ws.close();
    //         }
    //     };
    // }, [fileUploaded, ws]);

    const arrayBufferToFile = (arrayBuffer: ArrayBuffer, filename: string): File => {
        return new File([new Blob([arrayBuffer])], filename, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    };

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        if (!fileInputRef.current || !fileInputRef.current.files || fileInputRef.current.files.length === 0) {
            console.error('No file selected');
            setExcelError('No file selected');
            setSheets([]);
            return;
        }

        const file = fileInputRef.current.files[0];
        setLoading(true);
        setExcelError(null);

        const formData = new FormData();
        formData.append('inputFile', file);

        try {
            // Construct the endpoint URL
            const endPoint = `api/PartnershipCreation/ExcelImport?operationType=${selectedOperationType}`;

            // Use submitMultipartForm to handle the file upload
            const response = await submitMultipartForm(endPoint, formData, 2);

            // if (!response.ok) {
            //     throw new Error('Network response was not ok.');
            // }

            console.log(response);

 ;
            setFileUploaded(true);

            toast.success("File uploaded successfully. Waiting for WebSocket response...");
        } catch (error) {
            console.error('Upload or WebSocket error:', error);
            toast.error("Error occurred: " + JSON.stringify(error));
            setExcelError('Error processing Excel file');
            setSheets([]);
        } finally {
            setLoading(false);
        }
    };


    const fetchAndDisplayFile = async (fileName: string) => {
        try {
            const endpoint = `${calculationsBaseUrl}api/PartnershipCreation/GetFile?fileName=${fileName}`;
            const response = await fetch(endpoint);

            if (!response.ok) {
                throw new Error('Failed to fetch file');
            }

            const blob = await response.blob();
            const arrayBuffer = await blob.arrayBuffer();
            const fileFromArrayBuffer = arrayBufferToFile(arrayBuffer, fileName);

            const excelData = await transformExcelToFortune(fileFromArrayBuffer);
            setSheets(excelData.sheets);
            setKey(prevKey => prevKey + 1);

            toast.success("File fetched and displayed successfully");
            setExcelError(null);
        } catch (error) {
            console.error('Error fetching file:', error);
            toast.error("Error occurred: " + JSON.stringify(error));
            setExcelError('Error fetching file');
            setSheets([]);
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };

    const onClientChange = (args: any) => {
        setClientId(args.itemData.clientID);
        refreshData();
    };

    const handleImportTypeChange = (args: any) => {
        setImportType(args.itemData.value);
    };

    const refreshData = async () => {
        setLoading(true);
        try {
            // Simulated data refresh
            setImportResults([]); // Example placeholder for results
        } catch (error) {
            console.error('Error refreshing data:', error);
        }
        setLoading(false);
    };

    return (
        <div className="page-content">
            <ToastContainer />
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader />}
                text='Loading data...'
            >
                <div className="col-3">
                    <DropDownListComponent
                        id="atcelement"
                        dataSource={clientList}
                        fields={{ text: 'clientName', value: 'clientID' }}
                        allowFiltering={true}
                        value={clientId}
                        placeholder="Change Client"
                        change={onClientChange}
                    />
                </div>
                <br />
                <div className="col-3">
                    <DropDownListComponent
                        id="importType"
                        dataSource={[
                            { text: "Partenrship Creation", value: OperationType.PartnershipCreation },
                            { text: "Overrides", value: OperationType.Overrides }
                        ]}
                        fields={{ text: 'text', value: 'value' }}
                        value={importType}
                        placeholder="Select Import Type"
                        change={handleImportTypeChange}
                    />
                </div>
                <br />
                <div className="container-fluid">
                    <Col xs={6}>
                        <div className="input-group">
                            <input
                                type="file"
                                ref={fileInputRef}
                                className="form-control"
                                id="inputGroupFile04"
                                aria-describedby="inputGroupFileAddon04"
                                aria-label="Upload"
                                onChange={handleFileChange}
                            />
                            <button
                                className="btn btn-primary"
                                id="inputGroupFileAddon04"
                                onClick={handleButtonClick}
                            >
                                Upload
                            </button>
                        </div>
                    </Col>
                </div>

                <br /><br />
                <div className="col-12">
                    <DataViewGrid
                        id={"importResults"}
                        columns={importResultsColDef}
                        data={importResults}
                        hasMultiSelect={false}
                    />
                </div>
                <br /><br />
                <div className="col-12">
                    {excelError && <div className="alert alert-danger">{excelError}</div>}
                    {sheets.length > 0 && (
                        <div>
                            <h3>Imported Excel Data:</h3>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    height: "60vh",
                                }}
                            >
                                <Workbook data={sheets} key={key} showToolbar={true} allowEdit={false} showFormulaBar={false}/>
                            </div>
                        </div>
                    )}
                </div>
            </LoadingOverlay>
        </div>
    );
};

export default Imports;
