// LoginPage.tsx
import React from 'react';
import { UserManager } from 'oidc-client-ts';
import {oidcConfig} from '../helpers/keycloakHelper';
import {loginuser} from "../slices/auth/login/thunk";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import '../assets/scss/bootstrap.scss'; // Import Bootstrap SCSS

const userManager = new UserManager(oidcConfig);

const LoginPage: React.FC = () => {
    const dispatch: any = useDispatch();

    const navigate = useNavigate();
    const handleLogin = () => {
        console.warn("oidc login called........................");
        if (process.env.REACT_APP_DEFAULTAUTH === 'oidc') {
            dispatch(loginuser());
        }

    }


    return (
        <div className="d-flex justify-content-center align-items-center vh-100" style={{backgroundColor: 'darkslategray'}}>
            <div className="card w-50">
                <div className="card-body text-center">
                    <h2 className="card-title">Welcome to PartnerTech Tax Management Portal</h2>
                    <p className="card-text">Please click on login to continue. </p>
                    <p className="card-text">More on the application coming soon... </p>
                    <p className="card-text">Version 1.0.0 Beta Copyright(C) 2024 All rights reserved </p>
                    <button className="btn btn-primary btn-lg" onClick={handleLogin}>Login</button>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;