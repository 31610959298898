import React, { useState, useEffect } from 'react';
import useApi from "../../hooks/useApi";
import { fetchAccountsData } from "../../BL/CoaAccounts";
import { fetchData } from "../../BL/Clients";
import { toast, ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay-ts";
import BounceLoader from "react-spinners/BounceLoader";
import DataGrid from "../../Components/Common/DataGrid";
import { accountsColDefs } from "../../uihelper/gridutil";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { accountsColDataSources } from "../../uihelper/columndatasources";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import Breadcrumb from "../../Components/Common/Breadcrumb";

const endpoints = {
    getAll: 'api/COAManagement/Accounts',
    GetById: 'api/COAManagement/Accounts/GetByClientId',
    addData: 'api/COAManagement/Accounts',
    updateData: `api/COAManagement/Accounts`,
    deleteData: 'api/COAManagement/Accounts',
};

const Accounts = () => {
    const [accountsData, setAccountsData] = useState<any[]>([]);
    const [baseUrl] = useState<string>(process.env.REACT_APP_UIINTEGRATION_BASE_URL || '');

    const { getDataByParams, addData, updateData, deleteData } = useApi(endpoints, baseUrl);
    const [loading, setLoading] = useState(false);
    const [txAcId, setTxAcId] = useState("");
    const [clientList, setClientList] = useState<any[]>([]);
    const [selectedClient, setSelectedClient] = useLocalStorage("selectedClient", { id: 1 });

    const fetchClientList = async () => {
        try {
            const clientData = await fetchData(getDataByParams);
            if (clientData.success) {
                setClientList(clientData.data);
            } else {
                toast.error("An error occurred while fetching client data: " + clientData.error);
            }
        } catch (error) {
            toast.error("An error occurred while fetching client data.");
            console.error(error);
        }
    };

    useEffect(() => {
        fetchClientList();
    }, []);

    const refreshData = async () => {
        setLoading(true);
        try {
            const result = await fetchAccountsData(getDataByParams, selectedClient.id);
            if (result.success) {
                setAccountsData(result.data);
                toast.success("Data refreshed successfully!");
            } else {
                setAccountsData([]);
                toast.error("An error occurred while fetching the data: " + result.error);
            }
        } catch (error) {
            toast.error("An error occurred while refreshing the data.");
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        refreshData();
    }, [selectedClient]); // Refresh data when selected client changes

    const onDataGridUpdate = (rowData: any) => {
        updateData(txAcId, rowData.data, 'id').then((response: any) => {
            toast.success("Data updated successfully!");
            refreshData()
        }).catch(error => {
            toast.error("An error occurred while updating the data.");
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    const onDataGridAdd = async (data: any) => {
        data.data.clientID = selectedClient.id; // Use selectedClient.id directly
        delete data.data.id;
        try {
            const result = await addData(data.data);
            if (result.success) {
                toast.success("Data added successfully!");
                refreshData(); // Refresh data after adding
            } else {
                toast.error("An error occurred while adding the data: " + result.error);
            }
        } catch (error) {
            toast.error("An error occurred while adding the data.");
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const onDataGridDelete = (data: any) => {
        deleteData(data?.data[0].txAcId, 'id').then((response: any) => {
            toast.success("Data deleted successfully!");
            refreshData(); // Refresh data after deleting
        }).catch(error => {
            toast.error("An error occurred while deleting the data.");
            console.error(error);
            setLoading(false);
        });
    };

    const fields = { text: 'text', value: 'id' };
    const modifiedClientList = clientList.map(client => ({
        id: client.id,
        clientName: client.clientName,
        text: `${client.clientName} - ${client.clientID}`
    }));

    const onClientChange = (args: any) => {
        const selectedClientId = args.itemData.id;
        setSelectedClient({ id: selectedClientId });
        refreshData()
    };

    return (
        <div className="page-content">
            <ToastContainer />
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader />}
                text='Loading data...'
            >
                <div className={"container-fluid"}>
                    <Breadcrumb title="Accounts" breadcrumbItem="Accounts" />

                    <div className="col-3">
                        <DropDownListComponent
                            id="atcelement"
                            dataSource={modifiedClientList}
                            fields={fields}
                            allowFiltering={true}
                            value={selectedClient.id}
                            placeholder="Select Client"
                            change={onClientChange}
                            floatLabelType="Auto"
                        />
                    </div>
                    <br />
                    <DataGrid
                        id={"gridAccounts"}
                        columns={accountsColDefs}
                        data={accountsData}
                        editing={true}
                        add={true}
                        del={true}
                        onAdd={onDataGridAdd}
                        onDelete={onDataGridDelete}
                        onUpdate={onDataGridUpdate}
                        hasColumnDataSource={true}
                        columnDataSources={accountsColDataSources}
                        hasExport={true}
                        pageName="Accounts"
                    />
                </div>
            </LoadingOverlay>
        </div>
    );
}

export default Accounts;
