import React, { useState, useEffect, useRef } from 'react';
import useApi from "../../../hooks/useApi";
import { fetchData } from '../../../BL/Clients';
import { fetchAccountsData } from "../../../BL/CoaAccounts";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { toast, ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay-ts";
import BounceLoader from "react-spinners/BounceLoader";
import { fetchForms } from "../../../BL/CoaForms";
import { fetchFormDefinition, fetchFormParts } from "../../../BL/CoaFormDefinition";
import { FormValidator, FormValidatorModel } from '@syncfusion/ej2-inputs';

const endpoints = {
    getAll: 'api/ClientManagement/Clients',
    getById: 'api/COAManagement/Accounts/GetByClientId',
    addData: 'api/COAManagement/FormMappings',
};

interface AddMappingProps {
    onClose: () => void;
    updateFormMappingData: (data: any) => void;
}

interface FormMappingData {
    accountId: string;
    clientId?: string;
    formId: string;
    formDefinitionId: string;
    formName: string;
    txFormMappingId: number;
    formPart?: string;
    lineId?: string;
    partType?: string;
    partDescription?: string;
}

const AddMapping: React.FC<AddMappingProps> = ({ onClose, updateFormMappingData }) => {
    const [clients, setClients] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [forms, setForms] = useState<{ txFormId: string, formName: string }[]>([]);
    const [formDefs, setFormDefs] = useState([]);
    const [selectedClientId, setSelectedClientId] = useState("");
    const [selectedAccountId, setSelectedAccountId] = useState("");
    const [selectedFormId, setSelectedFormId] = useState("");
    const [selectedFormDefinitionId, setSelectedFormDefinitionId] = useState("");
    const [formMappingData, setFormMappingData] = useState<FormMappingData[]>([]);
    const [baseUrl] = useState(process.env.REACT_APP_UIINTEGRATION_BASE_URL || '');
    const { getDataByParams, addData } = useApi(endpoints, baseUrl);
    const [loading, setLoading] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);
    const formValidatorRef = useRef<FormValidator | null>(null);

    useEffect(() => {
        fetchClients();
        fetchFormData();
        if (formRef.current) {
            const validationRules: FormValidatorModel = {
                rules: {
                    clientDropdown: { required: [true, "Client is required"] },
                    accountDropdown: { required: [true, "Account is required"] },
                    formDropdown: { required: [true, "Form is required"] },
                    formDefsDropdown: { required: [true, "Form definition is required"] },
                }
            };
            formValidatorRef.current = new FormValidator(formRef.current, validationRules);
        }
    }, []);

    const fetchClients = async () => {
        try {
            const clientsData = await fetchData(getDataByParams);
            if (clientsData.success) {
                setClients(clientsData.data);
            } else {
                toast.error("Failed to fetch clients.");
            }
        } catch (error) {
            toast.error("An error occurred while fetching clients.");
            console.error(error);
        }
    };

    const fetchAccountsByClientId = async (selectedClientId: string) => {
        try {
            const accountsData = await fetchAccountsData(getDataByParams, selectedClientId);
            if (accountsData.success) {
                setAccounts(accountsData.data);
            } else {
                toast.error("Failed to fetch accounts for the selected client.");
            }
        } catch (error) {
            toast.error("An error occurred while fetching accounts for the selected client.");
            console.error(error);
        }
    };

    const fetchFormData = async () => {
        try {
            const formsData = await fetchForms(getDataByParams);
            if (formsData.success) {
                setForms(formsData.data);
            } else {
                toast.error("Failed to fetch forms.");
            }
        } catch (error) {
            toast.error("An error occurred while fetching forms.");
            console.error(error);
        }
    };

    const fetchFormDefData = async (selectedFormId: string) => {
        try {
            const formDefsData = await fetchFormDefinition(getDataByParams, selectedFormId);
            if (formDefsData.success) {
                const modifiedData = formDefsData.data.map(item => ({
                    text: `${item.formPart} | ${item.partType} | ${item.lineId} - ${item.txFormDefinitionId}`,
                    value: item.txFormDefinitionId
                }));
                setFormDefs(modifiedData);
            } else {
                toast.error("Failed to fetch form definitions.");
            }
        } catch (error) {
            toast.error("An error occurred while fetching form definitions.");
            console.error(error);
        }
    };

    const handleClientChange = async (args: any) => {
        const clientId = args.value;
        setSelectedClientId(clientId);
        await fetchAccountsByClientId(clientId);
        formValidatorRef.current?.validate("clientDropdown");
    };

    const handleAccountChange = (args: any) => {
        setSelectedAccountId(args.value);
        formValidatorRef.current?.validate("accountDropdown");
    };

    const handleFormChange = async (args: any) => {
        const formId = args.value;
        setSelectedFormId(formId);
        await fetchFormDefData(formId);
        formValidatorRef.current?.validate("formDropdown");
    };

    const handleFormDefChange = (args: any) => {
        const formDefId = args.value;
        setSelectedFormDefinitionId(formDefId);
        formValidatorRef.current?.validate("formDefsDropdown");

        const selectedForm = forms.find(form => form.txFormId === selectedFormId);
        if (selectedForm) {
            const newData: FormMappingData = {
                accountId: selectedAccountId,
                clientId: selectedClientId,
                formId: selectedFormId,
                formName: selectedForm.formName,
                formDefinitionId: formDefId,
                txFormMappingId: 0
            };
            setFormMappingData(prevState => [...prevState, newData]);
        }
    };

    const handleSaveClick = async () => {
        if (formValidatorRef.current && formValidatorRef.current.validate()) {
            try {
                const savedData: any[] = [];
                for (const item of formMappingData) {
                    const requestData = {
                        accountId: item.accountId,
                        formId: item.formId,
                        formDefinitionId: item.formDefinitionId,
                        formName: item.formName,
                        txFormMappingId: 0
                    };
                    const response = await addData(requestData);
                    if (!response.success) {
                        toast.error("Failed to save data.");
                        setLoading(false);
                        return;
                    }
                    const formPartsData = await fetchFormParts(getDataByParams, item.formDefinitionId);
                    if (formPartsData.success) {
                        const { formPart, lineId, partType, lineDescription } = formPartsData.data[0] || {};
                        savedData.push({
                            ...response.data,
                            formPart,
                            lineId,
                            partType,
                            lineDescription
                        });
                    } else {
                        toast.error("Failed to fetch form parts.");
                        setLoading(false);
                        return;
                    }
                }
                setFormMappingData(savedData);
                updateFormMappingData(savedData);
                onClose();
                toast.success("Data saved successfully!");
            } catch (error) {
                console.error("An error occurred while saving data:", error);
                toast.error("An error occurred while saving data.");
                setLoading(false);
            }
        } else {
            toast.error("Please fill in all required fields.");
        }
    };

    return (
        <div className="page-content">
            <ToastContainer />
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader />}
                text='Loading data...'
            >
                <form ref={formRef} className="container-fluid">
                    <div className="row">
                        <div className="col-md-3">
                            <DropDownListComponent
                                id="clientDropdown"
                                dataSource={clients}
                                fields={{ text: 'clientName', value: 'id' }}
                                allowFiltering={true}
                                value={selectedClientId}
                                placeholder="Select Client"
                                change={handleClientChange}
                                floatLabelType="Auto"
                            />
                        </div>
                        <div className="col-md-3">
                            <DropDownListComponent
                                id="accountDropdown"
                                dataSource={accounts}
                                fields={{ text: 'accountId', value: 'txAcId' }}
                                allowFiltering={true}
                                value={selectedAccountId}
                                placeholder="Select Account"
                                change={handleAccountChange}
                                floatLabelType="Auto"
                            />
                        </div>
                        <div className="col-md-3">
                            <DropDownListComponent
                                id="formDropdown"
                                dataSource={forms}
                                fields={{ text: 'formName', value: 'txFormId' }}
                                allowFiltering={true}
                                value={selectedFormId}
                                placeholder="Select Form"
                                change={handleFormChange}
                                floatLabelType="Auto"
                            />
                        </div>
                        <div className="col-md-3">
                            <DropDownListComponent
                                id="formDefsDropdown"
                                dataSource={formDefs}
                                fields={{ text: 'text', value: 'value' }}
                                allowFiltering={true}
                                value={selectedFormDefinitionId}
                                placeholder="Select Form Definition"
                                change={handleFormDefChange}
                                floatLabelType="Auto"
                            />
                        </div>
                    </div>
                    <br/>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <button type="button" className="btn btn-primary" onClick={handleSaveClick}>Add</button>
                        </div>
                    </div>
                </form>
            </LoadingOverlay>
        </div>
    );
};

export default AddMapping;
