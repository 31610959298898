import { Navigate } from "react-router-dom"
import Dashboard from "../pages/Dashboard";
import Clients from "../pages/Clients";
import Partnerships from "../pages/Partnerships/index";
import Partners from "../pages/Partners";
import Contributions from "../pages/Partnerships/Contributions"
import Transfers from "../pages/Partnerships/Transfers"
import Redemptions from "../pages/Partnerships/Redemptions"
import AllocationGroup from "../pages/Partnerships/AllocationGroup"
import Calculations from "../pages/Calculations/index";
import IncomeExpense from "../pages/Partnerships/IncomeExpense";
import Assets from "../pages/Partnerships/Assets";
import Results from "../pages/Results";
import Distributions from "../pages/Partnerships/Distributions";
import DistributionGroup from "../pages/Partnerships/DistributionGroup";
import Imports from "../pages/Imports"
import ClientHome from "../pages/Clients/home";
import PartnershipHome from "../pages/Partnerships/home";
import PartnerHome from "../pages/Partners/home";
import RevalAssetOverride from "../pages/Overrides/RevalAssetOverride";
import Overrides from "../pages/Overrides/Overrides";
import RevalBigbilOverride from "../pages/Overrides/RevalBigbilOverride";
import AssetFMV from "../pages/Overrides/AssetFMV";
import Revaluation from "../pages/Partnerships/Revaluation";
import Roles from "../pages/Admin/roles";
import Modules from "../pages/Admin/modules";
import Operations from "../pages/Admin/operations";
import RolePermissions from "../pages/Admin/rolePermissions";
import Accounts from "../pages/CharterOfAccounts/Accounts";
import FormMaster from "../pages/CharterOfAccounts/FormMaster";
import FormMapping from "../pages/CharterOfAccounts/FormMapping/FormMapping";
import Rollovers from "../pages/Rollovers";
import AssetValautionOverride from "../pages/Overrides/AssetValuationOverride";
import PartnerCapitalOverride from "../pages/Overrides/PartnerCapitalOverride";
import PartnerInterestOverride from "../pages/Overrides/PartnerInterestOverride";
import PartnerBigBilOverride from "../pages/Overrides/PartnerBigBilOverride";


// Auth
import Login from "pages/Authentication/login";
import Logout from "pages/Authentication/Logout";
import UserProfile from "pages/Authentication/user-profile";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import SignUp from "pages/Authentication/Register"
import Users from "../pages/Admin/Users/index";
import AddUserForm from "../pages/Admin/Users/addUserForm";
import ChartOfAccounts from "../pages/ChartOfAccounts";
import LoginPage from "../pages/LoginPage";
import Logs from "../pages/Logs";
import DataManager from "../pages/DataManager";
import UserClientMapping from "../pages/Admin/userClientMapping";


const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/clients", component: <Clients /> },
  { path: "/partnerships", component: <Partnerships /> },
  { path: "/partners", component: <Partners /> },
  { path: "/contributions", component: <Contributions /> },
  { path: "/transfers", component: <Transfers /> },
  { path: "/redemptions", component: <Redemptions /> },
  { path: "/allocations", component: <AllocationGroup /> },
  { path: "/calculations", component: <Calculations /> },
  { path: "/income-expense", component: <IncomeExpense /> },
  { path: "/assets", component: <Assets /> },
  { path: "/results", component: <Results /> },
  { path: "/distributions", component: <Distributions /> },
  { path: "/distributiongroup", component: <DistributionGroup /> },
  { path: "/imports", component: <Imports /> },
  { path: "/clienthome", component: <ClientHome /> },
  { path: "/partnershiphome", component: <PartnershipHome /> },
  { path: "/partnerhome", component: <PartnerHome /> },
  { path: "/users", component: <Users /> },
  { path: "/add-user", component: <AddUserForm /> },
  { path: "/chart-of-accounts", component: <ChartOfAccounts /> },
  { path: "/revaluationOverrides", component: <Overrides /> },
  { path: "/revalAssetOverride", component: <RevalAssetOverride /> },
  { path: "/revalBigbilOverride", component: <RevalBigbilOverride /> },
  { path: "/assetFMV", component: <AssetFMV /> },
  { path: "/revaluation", component: <Revaluation /> },
  { path: "/roles", component: <Roles /> },
  { path: "/modules", component: <Modules /> },
  { path: "/operations", component: <Operations /> },
  { path: "/role-permissions", component: <RolePermissions /> },
  { path: "/accounts", component: <Accounts /> },
  { path: "/form-master", component: <FormMaster /> },
  { path: "/form-mapping", component: <FormMapping /> },
  { path: "/user-client-mapping", component: <UserClientMapping /> },
  { path: "/rollovers", component: <Rollovers /> },
  { path: "/avOverride", component: <AssetValautionOverride /> },
  { path: "/pcOverride", component: <PartnerCapitalOverride /> },
  { path: "/piOverride", component: <PartnerInterestOverride /> },
  { path: "/pbOverride", component: <PartnerBigBilOverride /> },
  { path: "/logs", component: <Logs /> },
  { path: "/data-manager", component: <DataManager /> },
  { path: "/", exact: true, component: <Navigate to="/clients" /> },
];

const publicRoutes = [
  { path: "/login", component: <LoginPage /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/register", component: <SignUp /> }
]
export { authProtectedRoutes, publicRoutes };
