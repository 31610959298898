
import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DataViewGrid from "../../../Components/Common/DataViewGrid";
import { userColDef } from '../../../uihelper/gridutil';
import DataGrid from "../../../Components/Common/DataGrid";
import Breadcrumb from "../../../Components/Common/Breadcrumb";

const Users = () => {
    const [users, setUsers] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate(); // Get the navigate function

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_UIINTEGRATION_BASE_URL_KEYCLOAK}/api/UserManagement/users`);
            setLoading(false);
            if (response.length > 0) {
                setUsers(response);
                console.log("Data refreshed successfully!");
            } else {
                console.log("No data found...");
            }
        } catch (error) {
            console.error('An error occurred while fetching the data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleAddUserClick = () => {
        navigate('/add-user'); // Navigate to the add user page using navigate
    };

    const getBreadcrumbItems  = () => {
        return breadcrumbItems;
    }
    const breadcrumbItems =
        [
            {
                "title": "Admin",
            }
        ]

    return (
        <div className="page-content">
            <div className={"container-fluid"}>
                <Breadcrumb items={getBreadcrumbItems()} title="Admin" breadcrumbItem="Users" />
            </div>
            <div className={"container-fluid"}>
            <Button color="primary" onClick={handleAddUserClick}>Add User</Button> {/* Use Button component from reactstrap */}

            </div>

            <DataViewGrid
                id={"gridUsers"}
                data={users}
                columns={userColDef}
            />
        </div>
    );
};

export default Users;
