import allocationGroup from './AllocationGroup.json';
import asset from './Asset.json';
import assetValuation from './AssetValuation.json';
import bigbil from './BIGBIL.json';
import contribution from './Contribution.json';
import events from './Events.json';
import incomeAllocatedResults from './IncomeAllocatedResults.json';
import incomeExpense from './IncomeExpense.json';
import partner from './Partner.json';
import partnerCapitalAccount from './PartnerCapitalAccount.json';
import partnerInterest from './PartnerInterest.json';
import partnership from './Partnership.json';
import redemption from './Redemption.json';
import transfer from './Transfer.json';
import client from './Client.json';
import distributions from "./Distribution.json";
import distributionGroup from "./DistributionGroup.json";
import distributionResults from "./DistributionResults.json";
import calculationResults from "./CalculationResults.json";
import calculationsPartnership from "./CalculationsPartnership.json";
import importResults from "./ImportResutls.json";
import depreciationResults from "./DepreciationResults.json";
import gainAllocations from "./GainAllocation.json";
import gainDisposition from "./GainDisposition.json";
import user from "./Users.json";
import chartOfAccount from "./ChartOfAccount.json";
import depreciationAllocation from "./DepreciationAllocationResults.json";
import revaluation from "./Revaluation.json";
import assetFMV from "./AssetFMV.json";
import revalAssetOverride from "./RevaluationAssetOverride.json";
import revalBigbilOverride from "./RevaluationBigbilOverride.json";
import role from './Role.json';
import accounts from './CoaAccounts.json';
import formMapping from './CoaFormMapping.json';
import forms from './CoaForms.json';
import formDef from './CoaFormDef.json';
import rolePermissions from './RolePermissions.json';
import modules from './Modules.json';
import operations from './Operations.json';
import assetValuationRollover from './AssetValuationRollover.json';
import partnerBigBilRollover from './BigBilRollover.json';
import partnerCapitalRollover from './PartnerCapitalRollover.json';
import partnerInterestRollover from './PartnerInterestRollover.json';
import assetValuationOverride from './AssetValuationOverride.json';
import partnerBigBilOverride from './BigBilOverride.json';
import partnerCapitalOverride from './PartnerCapitalOverride.json';
import partnerInterestOverride from './PartnerInterestOverride.json';

export const allocationGroupColDef = allocationGroup;
export const assetColDef = asset;
export const assetValuationColDef = assetValuation;
export const bigbilColDef = bigbil;
export const contributionColDef = contribution;
export const eventsColDef = events;
export const incomeAllocatedResultsColDef = incomeAllocatedResults;
export const incomeExpenseColDef = incomeExpense;
export const partnerColDef = partner;
export const partnerCapitalAccountColDef = partnerCapitalAccount;
export const partnerInterestColDef = partnerInterest;
export const partnershipColDef = partnership;
export const redemptionColDef = redemption;
export const transferColDef = transfer;
export const clientColDef = client;
export const distributionsColDef = distributions;
export const distributionGroupColDef = distributionGroup;
export const distributionResultsColDef = distributionResults;
export const calculationResultsColDef = calculationResults;
export const calculationsPartnershipColDef = calculationsPartnership;
export const importResultsColDef = importResults;
export const depreciationResultsColDef = depreciationResults;
export const gainAllocationsColDef = gainAllocations;
export const gainDispositionColDef = gainDisposition;
export const depreciationAllocationColDef = depreciationAllocation;
export const userColDef = user;
export const chartOfAccColDef = chartOfAccount;
export const revaluationColDef = revaluation;
export const assetFMVColDef = assetFMV;
export const revalAssetOverrideColDef = revalAssetOverride;
export const revalBigbilOverrideColDef = revalBigbilOverride;

export const roleColumDefs = role;

export const rolePermissionsColumDefs = rolePermissions;

export const modulesColumDefs = modules;

export const operationsColumDefs = operations;


export const accountsColDefs = accounts;

export const formMappingColDefs = formMapping;

export const formsColDefs = forms;

export const formDefColDefs = formDef;
export const assetValuationRolloverColDef = assetValuationRollover;
export const partnerBigBilRolloverColDef = partnerBigBilRollover;
export const partnerCapitalRolloverColDef = partnerCapitalRollover;
export const partnerInterestRolloverColDef = partnerInterestRollover;
export const assetValuationOverrideColDef = assetValuationOverride;
export const partnerBigBilOverrideColDef = partnerBigBilOverride;
export const partnerCapitalOverrideColDef = partnerCapitalOverride;
export const partnerInterestOverrideColDef = partnerInterestOverride;

export type Column = {
    accessor: string;
    Header: string,
    Filter: boolean;
    isDate?: boolean;
    isRequired?: boolean;
    validationRules?: {
        required: boolean;
        number: boolean
    };
    isPrimary?: boolean;
    editType?: 'numericedit' | 'DatepickerEdit' | 'defaultedit' | 'datepickeredit';
}
