import {GetDataParams} from "../hooks/useApi";

export const fetchAccountsData = async (getDataByParams: (params: GetDataParams) => Promise<any>, id: string) => {
    let endpointUrl = "api/COAManagement/Accounts/GetByClientId";
    try {
        const { success, data, error } = await getDataByParams({
            endpoint: endpointUrl,
            params: {
                clientId: id,
            },
        });
        if (!success) {
            // Handle error
            console.error(error);
            return { success: false, error };
        } else {
            // Use data
            console.log(data);
            return { success: true, data };
        }
    } catch (error:any) {
        console.error(error);
        return { success: false, error: error.message };
    }
};
