import React, {useEffect, useState} from 'react';
import Breadcrumb from "../../Components/Common/Breadcrumb";

import {toast, ToastContainer} from "react-toastify";
import LoadingOverlay from "react-loading-overlay-ts";
import BounceLoader from "react-spinners/BounceLoader";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import {partnerColDataSources} from "../../uihelper/columndatasources";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import  { PdfViewerComponent, Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView,
    ThumbnailView, Print, TextSelection, Annotation, TextSearch, FormFields, FormDesigner, Inject} from '@syncfusion/ej2-react-pdfviewer';

import K1FormPDF from '../../assets/K1Form.pdf';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import '../../assets/scss/datagrid.scss';
import getDataByParamsKOneForm, {GetDataParams} from '../../hooks/useApi';
import {Download} from "@syncfusion/ej2-filemanager/src/file-manager/common";
import useApi from "../../hooks/useApi";
import { Readable } from 'stream';

// import {Inject, Toolbar} from "@syncfusion/ej2-react-grids";
const endpoints = {
    getAll: 'Partners',
    getById: 'Partners',
    addData: 'Partners',
    updateData: 'Partners',
    deleteData: 'Partners',
};

const pdfEndPoint = "api/ReportManagement/ScheduleK1/K1FormReport"
const endpoints11 ={
    pdfEndPoint
}
const PartnerHome = () => {
    const [dialogVisible, setDialogVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [clientId, setClientId] = useLocalStorage("id", "");
    const [partnershipId, setPartnershipId] = useLocalStorage("pid", "");
    const [selectedClient, setSelectedClient] = useLocalStorage("selectedClient", "");
    const [selectedPartnership, setSelectedPartnership] = useLocalStorage("selectedPartnership", "");
    const [selectedPartner, setSelectedPartner] = useLocalStorage("selectedPartner", "");
    const [pdfData, setPdfData] = useState<any>();

    const { submitMultipartForm,getDataByParamsK1Form } = useApi(endpoints,"");

    const pdfUrl = `https://uiintegrationgateway.azurewebsites.net/api/ReportManagement/ScheduleK1/K1FormReport?ClientID=${selectedClient.clientID}&PartnershipID=${selectedPartnership.partnershipID}&PartnerID=${selectedPartner?.partnerID}&taxYear=2021`;

    let pdfViewer;

    const getBreadcrumbItems  = () => {
        breadcrumbItems.push({title: selectedClient.clientName + " (" + selectedClient.clientID + ")", url: "clienthome"});
        breadcrumbItems.push({title: selectedPartnership.partnershipName + " (" + selectedPartnership.partnershipID + ")", url: "partnershiphome"});
        return breadcrumbItems;
    }

    const convertToB64 = (data:any) => {
        const base64 = btoa(
            new Uint8Array(data)
              .reduce((data, byte) => data + String.fromCharCode(byte), '')
          );
          console.warn(base64);
          return base64;
    }



    const handleClick = () => {
        setDialogVisible(true);
    };

    const handleDialogClose = () => {
        setDialogVisible(false);
    };

    const breadcrumbItems =
        [
            {
                "title": "Clients",
                "url": "Clients"
            }
        ]
    return (
        <div className="page-content">
            <ToastContainer />
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader  />}
                text='Loading data...'
            >
                <div className={"container-fluid"}>
                    <Breadcrumb items={getBreadcrumbItems()} title={clientId+" /" + partnershipId} breadcrumbItem={selectedPartner?.partnerName + "(" + selectedPartner?.partnerID + ")"} />
                    <div style={{ width: '110%' }}>
                        <Card>
                            <CardBody>
                                {/*
                            <CardTitle>{selectedPartnership?.partnershipID + "(" + selectedPartnership?.partnershipID + ")"}</CardTitle>
*/}
                                <Row md={12}>
                                    <Col md={6}>
                                        <label>
                                            Partner ID: &nbsp;
                                        </label>
                                        <span className="col-md-4">
                                        {selectedPartner?.partnerID ? "" + selectedPartner.partnerID : ""}
                                    </span>
                                    </Col>
                                    <Col md={6}>
                                        <label>
                                            Partner Name: &nbsp;
                                        </label>
                                        <span className="col-md-4">
                                        {selectedPartner?.partnerName ? "" + selectedPartner.partnerName : "-"}
                                    </span>
                                    </Col>
                                </Row>
                                <Row md={12}>
                                    <Col md={6}>
                                        <label>
                                            Partner TIN: &nbsp;
                                        </label>
                                        <span className="col-md-4">
                                        {selectedPartner?.partnerTIN ? "" + selectedPartner.partnerTIN : ""}
                                    </span>
                                    </Col>
                                    <Col md={6}>
                                        <label>
                                            Partner Type: &nbsp;
                                        </label>
                                        <span className="col-md-4">
                                        {selectedPartner?.partnerTypeID ? "" + selectedPartner.partnerTypeID : ""}
                                    </span>
                                    </Col>
                                </Row>
                                <Row md={12}>
                                    <Col md={6}>
                                        <label>
                                            Partner Tax Type: &nbsp;
                                        </label>
                                        <span className="col-md-4">
                                        {selectedPartner?.partnerTaxTypeID ? "" + selectedPartner.partnerTaxTypeID : ""}
                                    </span>
                                    </Col>
                                    <Col md={6}>
                                        <label>
                                            Is Foreign: &nbsp;
                                        </label>
                                        <span className="col-md-4">
                                        {selectedPartner?.isForeign ? "" + selectedPartner.isForeign : ""}
                                    </span>
                                    </Col>
                                </Row>
                                <Row md={12}>
                                    <Col md={6}>
                                        <label>
                                            Is DRE: &nbsp;
                                        </label>
                                        <span className="col-md-4">
                                        {selectedPartner?.isDRE ? "" + selectedPartner.isDRE : ""}
                                    </span>
                                    </Col>
                                    <Col md={6}>
                                        <label>
                                            GP or LP: &nbsp;
                                        </label>
                                        <span className="col-md-4">
                                        {selectedPartner?.gPorLP ? "" + selectedPartner.gPorLP : ""}
                                    </span>
                                    </Col>
                                </Row>
                                <Row md={12}>
                                    <Col md={6}>
                                        <label>
                                            Parent Partner Id: &nbsp;
                                        </label>
                                        <span className="col-md-4">
                                        {selectedPartner?.parentPartnerID ? "" + selectedPartner.parentPartnerID : ""}
                                    </span>
                                    </Col>
                                </Row>

                                <ButtonComponent
                                    style={{ backgroundColor: '#f5f5f5', color: '#383c40', border: 'none', padding: '0.375rem 0.75rem', fontSize: '1rem', borderRadius: '0.25rem' }}
                                    onClick={handleClick}>
                                    Generate K1
                                </ButtonComponent>


                                <DialogComponent showCloseIcon={true} closeOnEscape={true} cssClass={'custom-dialog'} visible={dialogVisible} close={handleDialogClose}
                                                 header= {'Schedule K1 For ' + selectedPartner?.partnerName + "(" + selectedPartner?.partnerID + ")" }
                                                 style={{ 'height': '100%!important','width':'100%!important' }}>
                                    <div >
                                        <PdfViewerComponent
                                            ref={(scope) => { pdfViewer = scope; }}
                                            documentPath={pdfUrl}
                                            resourceUrl="http://localhost:3000/ej2-pdfviewer-lib"


                                            style={{ 'height': '840px' }}
                                            enablePrint= {true}
                                            enableTextSearch= {true}
                                            enableFormFields= {true}
                                            enableFormDesigner= {false}
                                            toolbarSettings={{ showTooltip: true, toolbarItems: ['DownloadOption','UndoRedoTool', 'PageNavigationTool', 'MagnificationTool', 'PanTool', 'SelectionTool', 'CommentTool', 'SubmitForm', 'AnnotationEditTool', 'SearchOption', 'PrintOption']} }
                                        >
                                            {/*                      <PdfViewerComponent documentPath='http://localhost:3000/K1Form.pdf'*/}
                                            {/*                                          style={{ 'height': '840px' }}>*/}
                                            <Inject services={[ Toolbar, Magnification, Navigation, Annotation, LinkAnnotation, BookmarkView,
                                                ThumbnailView,Print, TextSelection, TextSearch, FormFields, FormDesigner ]}/>
                                        </PdfViewerComponent>
                                    </div>
                                </DialogComponent>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </LoadingOverlay>
        </div>
    );
}
export default PartnerHome;
