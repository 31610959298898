import React, {useEffect, useState} from 'react';
import useApi from "../../hooks/useApi";
import {fetchFormDefinition} from "../../BL/CoaFormDefinition";
import {toast, ToastContainer} from "react-toastify";
import LoadingOverlay from "react-loading-overlay-ts";
import BounceLoader from "react-spinners/BounceLoader";
import DataGrid from "../../Components/Common/DataGrid";
import {formDefColDefs} from "../../uihelper/gridutil";
import {formDefsColDataSources} from "../../uihelper/columndatasources";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import {fetchForms} from "../../BL/CoaForms";
import {DropDownListComponent} from "@syncfusion/ej2-react-dropdowns";
import Breadcrumb from "../../Components/Common/Breadcrumb";
const endpoints = {
    getAll: 'api/COAManagement/FormDefinitions',
    GetById: 'api/COAManagement/FormDefinitions/GetByFormId',
    addData: 'api/COAManagement/FormDefinitions',
    updateData: `api/COAManagement/FormDefinitions`,
    deleteData: 'api/COAManagement/FormDefinitions',
};
const FormDefinition = () => {
    const [formDefinitionData, setFormDefinitionData] = useState<any[]>([]);
    const [baseUrl ] = useState<string>(process.env.REACT_APP_UIINTEGRATION_BASE_URL || '');
    const [txFormDefinitionId, setTxFormDefinitionId] = useState("");

    const { getDataByParams, addData, updateData, deleteData} = useApi(endpoints, baseUrl);
    const [loading, setLoading] = useState(false);
    const [formList, setFormList] = useState<any[]>([]);
    const [selectedForm, setSelectedForm] = useLocalStorage("selectedForm", { txFormId: 1 });

    const fetchFormList = async () => {
        try {
            const formData = await fetchForms(getDataByParams);
            if (formData.success) {
                setFormList(formData.data);
            } else {
                toast.error("An error occurred while fetching form data: " + formData.error);
            }
        } catch (error) {
            toast.error("An error occurred while fetching form data.");
            console.error(error);
        }
    };

    useEffect(() => {
        fetchFormList();
    }, []);
    const refreshData = () => {
        setLoading(true);
        Promise.all([
            fetchFormDefinition(getDataByParams,selectedForm.txFormId),
        ])
            .then(([result]) => {
                setLoading(false);
                if(result.success) {
                    if(result.data.length > 0) {
                        setFormDefinitionData(result.data);
                        toast.success("Data refreshed successfully!");
                    } else {
                        toast.error("No data found...")
                        setFormDefinitionData([]);
                    }
                } else {
                    // Handle error from fetchData
                    toast.error("An error occurred while fetching the data: " + result.error);

                }
            })
            .catch(error => {
                toast.error("An error occurred while refreshing the data.");
                console.error(error);
                setFormDefinitionData([]);

            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        refreshData();
    }, [selectedForm]);

    const onDataGridDelete = (rowData:any) => {
        deleteData(rowData.data[0].txFormDefinitionId,'id').then((response:any) => {
            toast.success("Data deleted successfully!");
            refreshData();
            setLoading(false);
        },error => {
            toast.error("An error occurred while deleting the data.");
            console.error(error);
            setLoading(false);
        });
    }
    // update grid data
    const onDataGridUpdate = (rowData:any) => {
        updateData(txFormDefinitionId,
            rowData.data,'id').then((response:any) => {
            toast.success("Data updated successfully!");
            refreshData();
            setLoading(false);
        },error => {
            toast.error("An error occurred while updating the data.");
            console.error(error);
            setLoading(false);
        });
    }

    // add data
    const onDataGridAdd = async (rowData: any) => {
        console.warn("Inside onDataGridAdd..........",rowData.data);

        const result = await addData(rowData.data);

        if (result.success) {
            console.log('Data added successfully:', result.data);
            toast.success("Data added successfully!");
            refreshData();
            setLoading(false);
        } else {
            console.error('Failed to add data:', result.error);
            if (result.data) {
                toast.error(result.data.data);
            } else {
                toast.error("An error occurred while adding the data.", result.message);
            }

            console.error(result.error);
            setLoading(false);
        }
    };
    const fields = { text: 'formName', value: 'txFormId' };

    const onFormChange = (args: any) => {
      console.warn("onFormChange",args.itemData);
        const selectedFormId = args.itemData.txFormId;
        setSelectedForm({ txFormId: selectedFormId });
        refreshData()
        console.warn(JSON.stringify(args.itemData))
        localStorage.removeItem("fuckForm");
        localStorage.setItem("fuckForm", JSON.stringify(args.itemData));
        let storedObj = localStorage.getItem('fuckForm');
        console.log(storedObj);
        if (storedObj !== null) {
            let parsedObj = JSON.parse(storedObj);
            console.log(parsedObj);
        }
    };


    return (
        <div className="page-content_coa">
            <ToastContainer />
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader  />}
                text='Loading data...'
            >
                <div className={"container-fluid"}>
                    <div className="col-3">
                        <DropDownListComponent
                            id="atcelement"
                            dataSource={formList}
                            fields={fields}
                            allowFiltering={true}
                            value={selectedForm.txFormId}
                            placeholder="Select Form"
                            change={onFormChange}
                            floatLabelType="Auto"
                        />
                    </div>
                    <br />
                    <DataGrid  id={"iegridFormDefinition"}
                               columns={formDefColDefs}
                               data={formDefinitionData}
                               editing={true}
                               add={true}
                               del={true}
                               onAdd={onDataGridAdd}
                               onDelete={onDataGridDelete}
                               onUpdate={onDataGridUpdate}
                               hasColumnDataSource={true}
                               columnDataSources={formDefsColDataSources}
                               pageType={"Definition"}
                               hasExport={true}
                               pageName="FormDefinition"
                    />
                </div>
            </LoadingOverlay>
        </div>
    );
}

export default FormDefinition;
