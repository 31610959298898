export const isValidInput = (input: string) => {
    return /^[A-Za-z\s]+$/.test(input);
}

export const validateAddress = (address: any) => {
    const errors: { [key: string]: string } = {};

    if (!isValidInput(address.city)) {
        errors.city = "City can only contain alphabets.";
    }

    if (!isValidInput(address.state)) {
        errors.state = "State can only contain alphabets.";
    }

    if (!isValidInput(address.country)) {
        errors.country = "Country can only contain alphabets.";
    }

    return errors;
}
