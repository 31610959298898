import React, { useEffect, useState } from 'react';
import { Sheet } from "@fortune-sheet/core";
import { Workbook } from "@fortune-sheet/react";
import "@fortune-sheet/react/dist/index.css";
import {transformExcelToFortune} from "@zenmrp/fortune-sheet-excel";



const ExcelLoader: React.FC = () => {
    const [sheets, setSheets] = React.useState<any[]>([{ name: "Sheet1" }]);
    const [key, setKey] = React.useState<number>(0);
    const sheetRef = React.useRef<any>(null);


    useEffect(() => {
        // This will now only run once when the component mounts
        // If you need to set initial data, do it here
    }, []);

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            const reader = new FileReader();

            const excelData = await transformExcelToFortune(file);
            setSheets(excelData.sheets)
            setKey(k => k + 1)
            console.log('Loaded', excelData, 'into', sheetRef) // Log the dynamic object that will be changed by the engine
            reader.readAsArrayBuffer(file);
        }
    };

    return (
        <div       style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "60vh",
        }}>
            <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
            <Workbook ref={sheetRef} key={key} data={sheets} onChange={() => {

                for (const sheet of sheetRef.current.getAllSheets()) {
                    if (!sheet.data) continue;
                    for (let r = 0; r < sheet.data.length; r++)
                        for (let c = 0; c < sheet.data[r].length; c++)
                            if (typeof sheet.data[r][c]?.ct === 'object' && sheet.data[r][c].ct.t !== 's')
                                sheetRef.current.setCellFormat(r, c, 'ct', sheet.data[r][c].ct, { id: sheet.id });
                }

            }

            } />
        </div>
    );
};

export default ExcelLoader;