import React, {useState, useEffect} from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import BounceLoader from 'react-spinners/BounceLoader'
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useApi from '../../hooks/useApi';
import Breadcrumb from "../../Components/Common/Breadcrumb";

import {
    fetchAssetValuationRolloverData,
    fetchPartnerBigBilRolloverData,
    fetchPartnerCapitalRolloverData,
    fetchPartnerInterestRolloverData
} from '../Results/bl';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import DataViewGrid from "../../Components/Common/DataViewGrid";
import {
    assetValuationRolloverColDef,
    partnerBigBilRolloverColDef,
    partnerCapitalRolloverColDef,
    partnerInterestRolloverColDef
} from "../../uihelper/gridutil";
import {useLocalStorage} from "../../hooks/useLocalStorage";

const endpoints = {
    getAll: '/api/RolloverManagement',
    getById: '/api/RolloverManagement/{id}',
    addData: '/api/RolloverManagement',
    updateData: '/api/RolloverManagement/{id}',
};

const Rollovers = () => {

    const [state, setState] = useState(() => {
        const savedState = localStorage.getItem('resultsState');
        return savedState ? JSON.parse(savedState) : {
            capitalAcctData: [],
            interestData: [],
            bigBilData: [],
            assetValuationData: [],
        };
    });

    const {interestData,  capitalAcctData, bigBilData, assetValuationData} = state;

    const { submitMultipartForm, getDataByParams } = useApi(endpoints, process.env.REACT_APP_UIINTEGRATION_BASE_URL || '');

    const [loading, setLoading] = useState(false);
    const [clientId, setClientId] = useLocalStorage("id", "");
    const [partnershipId, setPartnershipId] = useLocalStorage("pid", "");
    const [selectedClient, setSelectedClient] = useLocalStorage("selectedClient", "");
    const [selectedPartnership, setSelectedPartnership] = useLocalStorage("selectedPartnership", "");

    const refreshData = () => {
        setLoading(true);
        Promise.all([
            fetchAssetValuationRolloverData(getDataByParams, clientId, partnershipId),
            fetchPartnerBigBilRolloverData(getDataByParams, clientId, partnershipId),
            fetchPartnerCapitalRolloverData(getDataByParams, clientId, partnershipId),
            fetchPartnerInterestRolloverData(getDataByParams, clientId, partnershipId)
        ])
            .then(([data1, data2, data3, data4]) => {
                setLoading(false);
                if (data1.success && data2.success && data3.success) {
                    setState(prevState => ({
                        ...prevState,
                        assetValuationData: data1.data,
                        bigBilData: data2.data,
                        capitalAcctData: data3.data,
                        interestData: data4.data,
                    }));
                    toast.success("Data refreshed successfully!");
                } else {
                    toast.error("No data found...");
                }
            })
            .catch(error => {
                toast.error("An error occurred while refreshing the data.");
                console.error(error);
                setLoading(false);
            });
    };
    // uncomment if required

    useEffect(() => {
        refreshData();
    }, []);

    const breadcrumbItems =
        [
            {
                "title": "Clients",
                "url": "Clients"
            }
        ]

    const getBreadcrumbItems  = () => {
        breadcrumbItems.push({title: selectedClient.clientName + " (" + selectedClient.clientID + ")", url: "clienthome"});
        breadcrumbItems.push({title: selectedPartnership.partnershipName + " (" + selectedPartnership.partnershipID + ")", url: "partnershiphome"});
        return breadcrumbItems;
    }

    return (

        <div className="page-content">
            <ToastContainer/>
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader/>}
                text='Loading data...'
            >
                <br/>

                <div className="container-fluid" id={"tabHolder"}>
                    <Breadcrumb items={getBreadcrumbItems()} title={selectedClient.clientName} breadcrumbItem="Rollovers" />
                    <Tabs
                        defaultActiveKey="index1"
                        id="fill-tab-example"
                        className="mb-3 tab-container"
                        fill
                        navbar
                        navbarScroll
                    >
                        <Tab eventKey="index1" title="Asset Valuation">
                            <div className="container-fluid">
                                <DataViewGrid id={"id1"}
                                              columns={assetValuationRolloverColDef}
                                              data={assetValuationData}
                                              hasExport={true}
                                              pageName="AssetValuationRollovers"
                                />
                            </div>
                        </Tab>
                        <Tab eventKey="index2" title="Partner Interests">
                            <div className="container-fluid">
                                <DataViewGrid id={"id2"}
                                              columns={partnerInterestRolloverColDef}
                                              data={interestData}
                                              hasExport={true}
                                              pageName="PartnerInterestRollovers"
                                />
                            </div>
                        </Tab>
                        <Tab eventKey="index3" title="Capital Accounts">
                            <div className="container-fluid">
                                <DataViewGrid id={"id3"}
                                              columns={partnerCapitalRolloverColDef}
                                              data={capitalAcctData}
                                              hasExport={true}
                                              pageName="CapitalAccountsRollovers"
                                />
                            </div>
                        </Tab>
                        <Tab eventKey="index4" title="BigBil">
                            <div className="container-fluid">
                                <DataViewGrid id="id4" columns={partnerBigBilRolloverColDef}
                                              data={bigBilData}
                                              hasExport={true}
                                              pageName="BigBilRollovers"
                                />
                            </div>
                        </Tab>
                    </Tabs>

                    <br/><br/>
                </div>
            </LoadingOverlay>
        </div>

    );
};

export default Rollovers;
