import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { loginSuccess, apiError, logoutUserSuccess, sessionExpired } from "./reducer";
import { UserManager } from "oidc-client-ts";
import {oidcConfig} from "../../../helpers/keycloakHelper";

const userManager = new UserManager(oidcConfig);

export interface AuthSettings {
    profile: any;
    token: string;
    isAuthenticated: boolean;
}

export const loginuser = (): ThunkAction<void, any, unknown, AnyAction> => async (dispatch) => {
    try {
        console.warn(oidcConfig)
        //console.log('Starting OIDC login process');
        await userManager.signinRedirect();
    } catch (error: any) {
     //  console.error('Login error during OIDC redirect:', error);
        dispatch(apiError(error.message));
    }
};

export const handleCallback = (history: any): ThunkAction<void, any, unknown, AnyAction> => async (dispatch) => {
    try {
      //  console.warn('Handling OIDC callback ................................');
        const user = await userManager.signinRedirectCallback();
        const profile = user.profile;
        const token = user.access_token;
        let auth = {
            profile,
            token,
            isAuthenticated: !!token,
        };
     //   console.log('User authenticated:', auth);
        sessionStorage.setItem('authUser', JSON.stringify(auth));
        localStorage.setItem('authUser', JSON.stringify(auth));
        dispatch(loginSuccess(auth));
        history('/clients', { replace: true });

        // Handle token expiration
        // userManager.events.addAccessTokenExpired(() => {
        //     console.warn('Access token expired *******************************************');
        //     console.log('Starting logout process');
        //     localStorage.removeItem("authUser");
        //     sessionStorage.removeItem("authUser");
        //     userManager.signoutRedirect();
        //     history('/login', { replace: true });
        // });
    } catch (error: any) {
        console.error('Error handling OIDC callback:', error);
        dispatch(apiError(error.message));
    }
};

export const logoutUser = (navigate: any): ThunkAction<void, any, unknown, AnyAction> => async (dispatch) => {
    try {
        console.log('Starting logout process');
        localStorage.removeItem("authUser");
        sessionStorage.removeItem("authUser");
        await userManager.signoutRedirect();
        navigate('/login', { replace: true });
        dispatch(logoutUserSuccess(true));
    } catch (error: any) {
        console.error('Logout error:', error);
        dispatch(apiError(error.message));
    }
};

export const resetLoginMsgFlag = () => (dispatch: any) => {
    try {
        dispatch({ type: 'login/resetLoginFlag' });
        console.log('Reset login message flag');
    } catch (error) {
        console.error('Reset login message flag error:', error);
    }
};

// export const socialLogin = (type: any, history: any): ThunkAction<void, any, unknown, AnyAction> => async (dispatch) => {
//     try {
//         console.log('Starting social login process');
//         let response: any;
//         if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
//             const fireBaseBackend = getFirebaseBackend();
//             response = fireBaseBackend.socialLoginUser(type);
//         }
//
//         const socialdata = await response;
//         if (socialdata) {
//             sessionStorage.setItem("authUser", JSON.stringify(socialdata));
//             dispatch(loginSuccess(socialdata));
//             history('/dashboard');
//             console.log('Social login successful:', socialdata);
//         }
//     } catch (error: any) {
//         console.error('Social login error:', error);
//         dispatch(apiError({ message: error.message }));
//     }
// };
