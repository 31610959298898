import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import {
    Aggregate,
    ColumnDirective,
    ColumnsDirective, ExcelExport, Filter,
    GridComponent, Group, Inject, Page, Resize, Search, Sort, Toolbar
} from "@syncfusion/ej2-react-grids";
import '../../assets/scss/datagrid.scss';
import partnerCapitalAccount from '../../uihelper/PartnerCapitalAccount.json'; // Adjust the import path accordingly
interface ColumnDefinition {
    Header: string;
    accessor?: string;
    Filter?: boolean;
    isDate?: boolean;
    isRequired?: boolean;
    editType?: string;
    visible?: boolean;
    key?: boolean;
    editable?: boolean;
    columnType?: string;
    template?: Function; // Define template as a function type if used
    isGrouping?: boolean; // Add properties if used in your logic
    isAggregated?: boolean; // Add properties if used in your logic
    columns?: ColumnDefinition[];
    dependentField?:any;
    requiredValues?: any; // Adjust the type as per your application's requirement
}
interface DataViewGridProps {
    id: string;
    data: any[];
    columns: ColumnDefinition[]; // Added columns property here
    hasMultiSelect?: boolean;
    setSelectedRows?: (args: any) => void;
    hasExport?: boolean;
    pageName?: string; // Add this line
    gridTitle?: string;
    hasGrouping?: boolean;
}

const mapColumn = (col: any): ColumnDefinition => {
    if (col.columns && col.columns.length > 0) {
        return {
            Header: col.Header,
            columns: col.columns.map((subCol: any) => mapColumn(subCol)),
            accessor: col.accessor,
            Filter: col.Filter,
            isDate: col.isDate,
            isRequired: col.isRequired,
            editType: col.editType,
            visible: col.visible !== undefined ? col.visible : true,
            key: col.key !== undefined ? col.key : false,
            editable: col.editable !== undefined ? col.editable : true,
            columnType: col.columnType,
            template: col.template,
            isGrouping: true,
            isAggregated: col.isAggregated,
            requiredValues: col.requiredValues,
            dependentField: col.dependentField,
        };
    } else {
        return {
            Header: col.Header,
            accessor: col.accessor,
            Filter: col.Filter,
            isDate: col.isDate,
            isRequired: col.isRequired,
            editType: col.editType,
            visible: col.visible !== undefined ? col.visible : true,
            key: col.key !== undefined ? col.key : false,
            editable: col.editable !== undefined ? col.editable : true,
            columnType: col.columnType,
            template: col.template,
            isGrouping: false,
            isAggregated: col.isAggregated,
            requiredValues: col.requiredValues,
            dependentField: col.dependentField,
        };
    }
};

const partnerCapitalAccountColDef: ColumnDefinition[] = partnerCapitalAccount.map((column: any) => mapColumn(column));

const GroupedColDataViewGrid = forwardRef((props: DataViewGridProps, ref) => {
    const {
        id,
        data,
        columns,
        hasMultiSelect = false,
        setSelectedRows = () => { },
        hasExport = false,
        pageName = 'Export', // Default export name
        gridTitle,
        hasGrouping = false
    } = props;

    let gridInstance: any;
    const [rowsSelected, setRowsSelected] = useState<any[]>([]);
    const [listenerAdded, setListenerAdded] = useState<boolean>(false);
    const [columnDefs, setColumnDefs] = useState<any[]>([]);

    useEffect(() => {
        const columnDefinitions = columns.map((col) => {
            if (col.columns) {
                return {
                    headerText: col.Header,
                    columns: col.columns.map((childCol) => ({
                        field: childCol.accessor,
                        headerText: childCol.Header,
                        width: 150,
                        visible: childCol.visible,
                        customAttributes: { style: { textAlign: 'center' } }
                    })),
                    customAttributes: { style: { textAlign: 'center', fontWeight: 'bold' } }
                };
            } else {
                return {
                    field: col.accessor,
                    headerText: col.Header,
                    width: 100,
                    visible: col.visible,
                    customAttributes: { style: { textAlign: 'center', fontWeight: 'bold' } }
                };
            }
        });
        setColumnDefs(columnDefinitions);
    }, [columns]);

    useEffect(() => {
        if (gridInstance) {
            gridInstance.dataSource = data;
            gridInstance.dataBind();
            setupSearchListener();
            setListenerAdded(true);
        }
    }, [data, gridInstance]);

    useImperativeHandle(ref, () => ({
        refreshData: (newData: any[]) => {
            if (gridInstance) {
                gridInstance.changeDataSource(newData);
                setupSearchListener();
            }
        }
    }));

    const footTemplate = (props) => {
        return (
            <span style={{fontWeight: 'bold', textAlign: 'left'}}>
                Sum: {props.Sum}
            </span>
        );
    };
// Function that returns a React functional component using the title prop
    const createToolbarTemplate = (title?: string) => {
        return () => (
            title ? (
                <div style={{fontWeight: 'bold', marginRight: '50px', fontSize: 'larger'}}>
                    {title}
                </div>
            ) : <div style={{display: 'none', width: '0px'}}></div>  // Return an empty div if title is null or undefined
        );
    };


    /**
     * This method is called when the grid is created
     * Here grouping and aggregate columns are added to the grid
     */
    const created = () => {
        if (gridInstance) {
            if (hasGrouping) {
                let aggregateColumns: any = [];

                columns.forEach((col) => {
                    if (col.isAggregated && hasGrouping) {
                        // Add the aggregate column.
                        let aggregateColumn = {
                            type: 'Sum',
                            field: col.accessor,
                            groupFooterTemplate: footTemplate
                        };

                        aggregateColumns.push(aggregateColumn);
                    }
                });

                (gridInstance as GridComponent).aggregates = [{
                    columns: aggregateColumns
                }];
            }
        }
    };

    /**
     * This method sets up the search listener for the grid search button
     */
    const setupSearchListener = () =>{
        if (gridInstance && gridInstance.element) {
            //   console.warn((gridInstance as GridComponent).element.id + "_searchbar");
            let elementName = (gridInstance as GridComponent).element.id + "_searchbar";
            const searchbarElement = document.getElementById(elementName) as HTMLElement;

            //     console.warn('searchbar element is ... ', searchbarElement);

            if (searchbarElement) {
                //   console.warn('Inside if searchbar -------------------------------------');
                searchbarElement.addEventListener('keyup', (event) => {
                    const value = (event.target as HTMLInputElement).value;
                    //    console.warn(value + ' -------------------------------------');
                    if (value !== null) {
                        (gridInstance as GridComponent)?.search(value);
                    } else {
                        console.error('Value is null or gridInstance is null');
                    }
                });

                // Add 'input' event listener to clear the search when the 'X' button is pressed
                searchbarElement.addEventListener('input', (event) => {
                    const value = (event.target as HTMLInputElement).value;
                    if (value === '') {
                        (gridInstance as GridComponent)?.search(value);
                    }
                });
            } else {
                console.error('Searchbar element not found');
            }
        }
    }
    const rowSelected = (args: any) => {
        const selectedRecords = gridInstance.getSelectedRecords();

        setRowsSelected(selectedRecords);
        setSelectedRows(selectedRecords);
        console.log("Selected records are ...", selectedRecords);
    };
    const ToolbarTemplate = createToolbarTemplate(gridTitle);

    let toolbarOptions: (string | {
        template: () => JSX.Element,
        align: 'Left' | 'Right' | 'Center'
    })[] = [
        {template: ToolbarTemplate, align: 'Left'}, // Custom template with title
        'Search', // Other predefined toolbar items
    ];
    const pageSettings = {pageSize: 20, pageSizes: true};


    if (hasExport) {
        toolbarOptions.push('ExcelExport');
    }

    const handleToolbarClick = ({item}) => {

        if (item.properties.id === `${id}_excelexport`) {
            console.log("Insided exceel export ....................");
            const exportProperties = {
                fileName: `${pageName}.xlsx`,
            };
            (gridInstance as GridComponent)?.excelExport(exportProperties);
        }
    };


    const groupColumns = columns.filter(col => col.isGrouping).map(col => col.accessor);
    // console.warn("Group columns??????????????????,", groupColumns);
    const groupOptions = {
        columns: groupColumns,
        showDropArea: false,
        disablePageWiseAggregates: true
    };
    const filterOptions:any = {
        type: 'Excel'
    };
    const handleDataBound = () => {
        if(!listenerAdded)
        {
            setupSearchListener();
        }

    };
    const customDateFormat = (col, value) => {
        // console.log("Custom date format value is ", value);
        let val = value[col.accessor];
        if (typeof val !== 'string') return "";
        // Truncate the fractional seconds part
        const truncatedValue = val.split('.')[0];
        const date = new Date(truncatedValue);
        let datevalue: any;
        if (col.isDate) {
            datevalue = date.toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'

            });

        }
        return datevalue;
    };
    return (
        <GridComponent ref={grid => gridInstance = grid}
                       id={id}
                       dataBound={handleDataBound}
                       pageSettings={pageSettings}
                       allowTextWrap={true}
                       textWrapSettings={{wrapMode: 'Header'}}
                       dataSource={data}
                       autoFit={true}
                       allowPaging={true}
                       gridLines='Both'
                       toolbar={toolbarOptions}
                       toolbarClick={handleToolbarClick}
                       selectionSettings={hasMultiSelect ? {type: 'Multiple'} : {}}
                       allowResizing={false}
                       allowSorting={true}
                       enableImmutableMode={false}
                       created={created.bind(this)}
                       allowSelection={hasMultiSelect}
                       rowSelected={rowSelected.bind(this)}
                       allowExcelExport={true}
                       groupSettings={hasGrouping ? groupOptions : {}}
                       allowGrouping={hasGrouping}
                       filterSettings={filterOptions}
                       allowFiltering={true}



        >
            <ColumnsDirective>
                {hasMultiSelect && <ColumnDirective type='checkbox' width='50'/>}
                {columnDefs.map((col, index) => (
                    <ColumnDirective key={index.toString()} {...col}
                                     autoFit={true}
                                     allowEditing={false}
                                     visible={col.visible}
                                     isPrimaryKey={col.key}
                                     template={col.columnType === 'date-time' ? (rowData) => customDateFormat(col, rowData) : undefined}
                                     filter={col.Filter}
                    />
                ))}
            </ColumnsDirective>
            <Inject
                services={hasGrouping ? [Sort, Toolbar, Resize, Page, Search, ExcelExport, Aggregate, Group] : [Sort, Toolbar, Resize, Page, Search, ExcelExport,Filter]}/>
        </GridComponent>
    );
});

export default GroupedColDataViewGrid;
