import React, { useEffect, useState } from 'react';
import useApi from "../../../hooks/useApi";
import { fetchFormMapping } from "../../../BL/CoaFormMapping";
import { toast, ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay-ts";
import BounceLoader from "react-spinners/BounceLoader";
import DataGrid from "../../../Components/Common/DataGrid";
import { formMappingColDefs } from "../../../uihelper/gridutil";
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap"; // Import modal components
import { fetchData } from "../../../BL/Clients";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import AddMapping from "./AddMapping";
import { formMappingColDataSources } from "../../../uihelper/columndatasources";
import Breadcrumb from "../../../Components/Common/Breadcrumb";

const endpoints = {
    getAll: 'api/COAManagement/FormMappings/join',
    GetById: 'api/COAManagement/FormMappings',
    addData: 'api/COAManagement/FormMappings',
    updateData: `api/COAManagement/FormMappings`,
    deleteData: 'api/COAManagement/FormMappings',
};

const FormMapping = () => {
    const [formMappingData, setFormMappingData] = useState<any[]>([]);
    const [baseUrl] = useState<string>(process.env.REACT_APP_UIINTEGRATION_BASE_URL || '');
    const { getDataByParams, addData, updateData, deleteData } = useApi(endpoints, baseUrl);
    const [loading, setLoading] = useState(false);
    const [txFormMappingId, setTxFormMappingId] = useState("");
    const navigate = useNavigate(); // Get the navigate function
    const [clientList, setClientList] = useState<any[]>([]);
    const [selectedClient, setSelectedClient] = useLocalStorage("selectedClient", { id: 1 });
    const [modalOpen, setModalOpen] = useState(false);
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    const fetchClientList = async () => {
        try {
            const clientData = await fetchData(getDataByParams);
            if (clientData.success) {
                setClientList(clientData.data);
            } else {
                toast.error("An error occurred while fetching client data: " + clientData.error);
            }
        } catch (error) {
            toast.error("An error occurred while fetching client data.");
            console.error(error);
        }
    };

    useEffect(() => {
        fetchClientList();
    }, []);

    const refreshData = async () => {
        setLoading(true);
        try {
            const result = await fetchFormMapping(getDataByParams, selectedClient.id);
            if (result.success) {
                setFormMappingData(result.data);
                toast.success("Data refreshed successfully!");
            } else {
                setFormMappingData([]);
                toast.error("An error occurred while fetching the data: " + result.error);
            }
        } catch (error) {
            toast.error("An error occurred while refreshing the data.");
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        refreshData().then(r => {
            console.log('Data refreshed successfully!');

        });
    }, [selectedClient]);

    const onDataGridDelete = (rowData: any) => {
        deleteData(rowData.data[0].txFormMappingId, 'id').then((response: any) => {
            toast.success("Data deleted successfully!");
            refreshData();
            setLoading(false);
        }, error => {
            toast.error("An error occurred while deleting the data.");
            console.error(error);
            setLoading(false);
        });
    }
    // update grid data

    // add data
    const updateFormMappingData = (data: any) => {
        setFormMappingData([...formMappingData, ...data]);
        refreshData()
    };
    const fields = { text: 'text', value: 'id' };
    const modifiedClientList = clientList.map(client => ({
        id: client.id,
        clientName: client.clientName,
        text: `${client.clientName} - ${client.clientID}`
    }));
    const onClientChange = (args: any) => {

        const selectedClientId = args.itemData.id;
        setSelectedClient({ id: selectedClientId });
        refreshData()
    };
    return (
        <div className="page-content">
            <ToastContainer />
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader />}
                text='Loading data...'
            >
                <div className={"container-fluid"}>
                    <Breadcrumb title="Form Mapping" breadcrumbItem="Form Mapping" />

                    <div className="row">
                        <div className="col-md-3">
                            <DropDownListComponent
                                id="atcelement"
                                dataSource={modifiedClientList}
                                fields={fields}
                                allowFiltering={true}
                                value={selectedClient.id}
                                placeholder="Select Client"
                                change={onClientChange}
                                floatLabelType="Auto"
                            />
                        </div>
                        <div className="col-md-3 d-flex justify-content-end">
                            <Button color="primary" onClick={toggleModal}>Add Mapping</Button>
                        </div>
                        <Modal isOpen={modalOpen} toggle={toggleModal} style={{ maxWidth: '60%', width: 'auto', maxHeight: '40%', height: 'auto' }}>
                            <ModalHeader toggle={toggleModal}>Add Mapping</ModalHeader>
                            <ModalBody>
                                <AddMapping onClose={toggleModal} updateFormMappingData={updateFormMappingData} />
                            </ModalBody>
                        </Modal>
                    </div>
                    <br />
                    <DataGrid
                        id={"gridFormMapping"}
                        columns={formMappingColDefs}
                        data={formMappingData}
                        editing={false}
                        add={false}
                        del={true}
                        template={false}
                        onDelete={onDataGridDelete}
                        hasColumnDataSource={true}
                        columnDataSources={formMappingColDataSources}
                        hasExport={true}
                        pageName="FormMapping"
                    />
                </div>
            </LoadingOverlay>
        </div>
    );
}

export default FormMapping;
