import { GetDataParams } from '../hooks/useApi';// adjust the import path according to your project structure

// export const fetchData = async (getDataByParams: (params: GetDataParams) => Promise<any>) => {
//     let endpointUrl = "api/ClientManagement/Clients";
//     try {
//         const data = await getDataByParams({
//             endpoint: endpointUrl,
//         });
//         return data;
//     } catch (error) {
//         console.error(error);
//         return error;
//     }
// };
export const fetchData = async (getDataByParams: (params: GetDataParams) => Promise<any>) => {
    let endpointUrl = "api/ClientManagement/Clients";
    try {
        const { success, data, error } = await getDataByParams({
            endpoint: endpointUrl,
        });
        if (!success) {
            // Handle error
            console.error(error);
            return { success: false, error };
        } else {
            // Use data
            console.log(data);
            return { success: true, data };
        }
    } catch (error:any) {
        console.error(error);
        return { success: false, error: error.message };
    }
};