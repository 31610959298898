import React, {useEffect, useState} from 'react';
import { Button, Card, CardBody, Container, FormGroup, Label, Input, Col, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useApi from "../../../hooks/useApi";
import Breadcrumb from "../../../Components/Common/Breadcrumb";
import { DropDownListComponent, MultiSelectComponent, CheckBoxSelection, Inject } from '@syncfusion/ej2-react-dropdowns';

const endpoints = {
    getClients: 'api/ClientManagement/Clients',
    getRoles: 'api/RbacManagement/Role'
}

const AddUserForm = () => {
    const [baseUrl] = useState<string>(process.env.REACT_APP_UIINTEGRATION_BASE_URL || '');
    const { getDataByParams } = useApi(endpoints, baseUrl);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        password: '',
        confirmPassword: '',
        clientID: '',
        txRoleIds: [] as string[],
        keycloakUserId: ''
    });
    const [errors, setErrors] = useState<any>({});
    const navigate = useNavigate();
    const [clients, setClients] = useState<any[]>([]);
    const [roles, setRoles] = useState<any[]>([]);

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await getDataByParams({ endpoint: endpoints.getClients });
                setClients(response.data);
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };

        fetchClients();
    }, []);

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await getDataByParams({ endpoint: endpoints.getRoles });
                setRoles(response.data);
            } catch (error) {
                console.error('Error fetching roles:', error);
            }
        };

        fetchRoles();
    }, []);

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;

        if (name === 'username') {
            setFormData({
                ...formData,
                [name]: value,
                keycloakUserId: value
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleClientChange = (e: any) => {
        setFormData({
            ...formData,
            clientID: e.value
        });
    };

    const handleRoleChange = (e: any) => {
        const selectedRoleIds = e.value;
        setFormData({
            ...formData,
            txRoleIds: selectedRoleIds
        });
    };

    const validateForm = () => {
        const errorsCopy = { ...errors };
        let isValid = true;

        // Check if fields are empty
        for (const field in formData) {
            const value = formData[field as keyof typeof formData];
            if (typeof value === 'string') {
                if (value.trim() === '') {
                    errorsCopy[field] = true;
                    isValid = false;
                } else {
                    errorsCopy[field] = false;
                }
            } else if (Array.isArray(value)) {
                if (value.length === 0) {
                    errorsCopy[field] = true;
                    isValid = false;
                } else {
                    errorsCopy[field] = false;
                }
            } else {
                if (!value) {
                    errorsCopy[field] = true;
                    isValid = false;
                } else {
                    errorsCopy[field] = false;
                }
            }
        }
        // Validate email format
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            errorsCopy.email = true;
            isValid = false;
        }

        // Validate password and confirm password match
        if (formData.password !== formData.confirmPassword) {
            errorsCopy.password = true;
            errorsCopy.confirmPassword = true;
            isValid = false;
        }

        setErrors(errorsCopy);
        return isValid;
    };

    const handleSubmit = async () => {
        console.log('Submitting form...');
        const isValid = validateForm();

        if (isValid) {
            try {
                const { confirmPassword, ...formDataWithoutConfirmPassword } = formData;

                await axios.post(`${process.env.REACT_APP_UIINTEGRATION_BASE_URL_KEYCLOAK}/api/UserManagement/user`, formDataWithoutConfirmPassword);
                console.log('Form submitted successfully');
                navigate('/users');
            } catch (error) {
                console.error('Error adding user:', error);
            }
        } else {
            console.log('Form validation failed');
        }
    };

    const onBackButtonClick = () => {
        navigate('/users');
    };
    const getBreadcrumbItems  = () => {
        return breadcrumbItems;
    }
    const breadcrumbItems =
        [
            {
                "title": "Admin / Users",
            }
        ]

    return (
        <div className="page-content">
            <div className={"container-fluid"}>
                <Breadcrumb items={getBreadcrumbItems()} title="Users" breadcrumbItem="Add User" />
            </div>
            <Container>
                <Card style={{ marginTop: 0, width: '100%' }}>
                    <CardBody>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="clientID">Select Client</Label>
                                    <DropDownListComponent
                                        id="clientID"
                                        name="clientID"
                                        dataSource={clients}
                                        fields={{ text: 'clientName', value: 'clientID' }}
                                        placeholder="Select a client"
                                        change={handleClientChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="txRoleIds">Select Roles</Label>
                                    <MultiSelectComponent
                                        id="txRoleIds"
                                        name="txRoleIds"
                                        dataSource={roles}
                                        fields={{ text: 'txRoleName', value: 'txRoleId' }}
                                        placeholder="Select roles"
                                        mode="CheckBox"
                                        showSelectAll={true}
                                        showDropDownIcon={true}
                                        change={handleRoleChange}
                                    >
                                        <Inject services={[CheckBoxSelection]} />
                                    </MultiSelectComponent>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="firstName">First Name</Label>
                                    <Input
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                        invalid={!!errors.firstName}
                                        placeholder="Enter First Name"
                                    />
                                    {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="lastName">Last Name</Label>
                                    <Input
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                        invalid={!!errors.lastName}
                                        placeholder="Enter Last Name"
                                    />
                                    {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        invalid={!!errors.email}
                                        placeholder="Enter Email"
                                    />
                                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="username">User Name</Label>
                                    <Input
                                        type="text"
                                        id="username"
                                        name="username"
                                        value={formData.username}
                                        onChange={handleInputChange}
                                        invalid={!!errors.username}
                                        placeholder="Enter User Name"
                                    />
                                    {errors.username && <div className="invalid-feedback">{errors.username}</div>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="password">Password</Label>
                                    <Input
                                        type="password"
                                        id="password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleInputChange}
                                        invalid={!!errors.password}
                                        placeholder="Enter Password"
                                        autoComplete="new-password"
                                    />
                                    {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="confirmPassword">Confirm Password</Label>
                                    <Input
                                        type="password"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        value={formData.confirmPassword}
                                        onChange={handleInputChange}
                                        invalid={!!errors.confirmPassword}
                                        placeholder="Confirm Password"
                                    />
                                    {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Button color="primary" onClick={onBackButtonClick}>
                                    Back
                                </Button>
                            </Col>
                            <Col md={6} className="text-right">
                                <Button color="primary" onClick={handleSubmit}>
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default AddUserForm;
