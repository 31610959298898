import { useState, useEffect } from "react";

function getStorageValue(key, defaultValue) {
    // getting stored value
    const saved = localStorage.getItem(key);
    let initial = defaultValue; // Set initial value to default
    try {
        if (saved) {
            initial = JSON.parse(saved);
        }
    } catch (error) {
        console.error("Error parsing stored value:", error);
    }
    return initial;
}

export const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
        return getStorageValue(key, defaultValue);
    });

    useEffect(() => {
        // storing input name
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
};
