import { DataManager, WebApiAdaptor } from '@syncfusion/ej2-data';

export  interface columnDataSource{
    accessorName: string ;
    datasource: object[];
}

export  const partnershipColDataSources: columnDataSource[]  =[{
    accessorName: "isSubEntity", datasource: [
        {name:'Yes',value:'Yes'},{name:'No',value: 'No'}
    ]},{
    accessorName: "isPTP", datasource: [
        {name:'Yes',value:'Yes'},{name:'No',value: 'No'}
    ]},
    {
        accessorName: "election754", datasource: [
            {name:'Yes',value:'Yes'},{name:'No',value: 'No'}
        ]},
    {
        accessorName: "interestType", datasource: [
            {name:'Percentage',value:'Percentage'},{name:'Units',value: 'Units'}
        ]},
]

export  const partnerColDataSources: columnDataSource[]  =[{
    accessorName: "partnerTypeID", datasource: [
        {name:'Individual',value:'Individual'},{name:'Business',value:'Business'}
    ]},{
    accessorName: "partnerTaxTypeID", datasource: [
        {name:'Individual',value:'Individual'},{name:'C Corporation',value:'CCorporation'},
        {name:'Sole Proprietor',value:'SoleProprietor'},{name:'LLC',value:'LLC'},
        {name:'LLP',value:'LLP'},{name:'Partnership',value:'Partnership'},
        {name:'S Corporation',value:'SCorporation'},{name:'IRA',value:'IRA'},
        {name:'Non Profit Corporation',value:'NonProfitCorporation'},{name:'Fund',value:'Fund'},
        {name:'DRE',value:'DRE'}
    ]},{
    accessorName: "isForeign", datasource: [
        {name:'Yes',value:'Yes'},{name:'No',value: 'No'}
    ]},{
    accessorName: "isDRE", datasource: [
        {name:'Yes',value:'Yes'},{name:'No',value: 'No'}
    ]},
    {
        accessorName: "gPorLP", datasource: [
            {name:'GP',value:'GP'},{name:'LP',value: 'LP'}
        ]},
    {
        accessorName: "isTieringPartner", datasource: [
            {name:'Yes',value:'Yes'},{name:'No',value: 'No'}
        ]}
]

export  const contributionColDataSources: columnDataSource[]  =[{
    accessorName: "contributionType", datasource: [
        {name:'Asset',value:'Asset'},{name:'Cash',value: 'Cash'}
    ]},
]

export  const transferColDataSources: columnDataSource[]  =[{
    accessorName: "transferLot", datasource: [
        {name:'FIFO',value:'FIFO'},{name:'LIFO',value: 'LIFO'},{name:'Blended',value: 'Blended'},{name:'Specific',value: 'Specific'}
    ]},
]

export  const redemptionColDataSources: columnDataSource[]  =[{
    accessorName: "redemptionLot", datasource: [
        {name:'FIFO',value:'FIFO'},{name:'LIFO',value: 'LIFO'},{name:'Blended',value: 'Blended'},{name:'Specific',value: 'Specific'}
    ]},
]

export  const assetsColDataSources: columnDataSource[]  =[{
    accessorName: "acquisitionType", datasource: [
        {name:'Purchase',value:'Purchase'},{name:'Contribution',value: 'Contribution'},{name:'Exchange',value: 'Exchange'}
    ]},
    {
    accessorName: "newLife", datasource: [
        {name:'Yes',value:'Yes'},{name:'No',value: 'No'}
    ]},
    {
    accessorName: "depreciationMethod", datasource: [
        {name:'MACRS',value:'MACRS'},{name:'Straight Line',value: 'SL'},{name:'Units of Consumption',value: 'Units of Consumption'},{name:'Depletion',value: 'Depletion'}
    ]},
    {
    accessorName: "convention", datasource: [
        {name:'Mid Month',value:'MM'},{name:'Half Year',value: 'HY'},{name:'Mid Quarter',value: 'MQ'}
    ]},
    {
    accessorName: "isRemedial", datasource: [
        {name:'Yes',value:'Yes'},{name:'No',value: 'No'}
    ]},
    {
        accessorName: "isDepreciable", datasource: [
            {name:'Yes',value:'Yes'},{name:'No',value: 'No'}
        ]},
    {
        accessorName: "dispositionType", datasource: [
            {name:'Sale',value:'Sale'},{name:'Distribution',value: 'Distribution'}
        ]},
    {
        accessorName: "depreciationAllocationMethod", datasource: [
            {name:'Traditional - Aggregated',value:'Traditional - Aggregated'},{name:'Traditional - Segregated',value: 'Traditional - Segregated'},{name:'Remedial - Segregated',value: 'Remedial - Segregated'},
            {name:'Remedial - Aggregated',value: 'Remedial - Aggregated'},{name:'Traditional Curative - Aggregated',value: 'Traditional Curative - Aggregated'},{name:'Traditional Curative - Segregated',value: 'Traditional Curative - Segregated'}
        ]},
    {
        accessorName: "gainAllocationMethod", datasource: [
            {name:'Traditional',value:'Traditional'},{name:'Remedial',value: 'Remedial'},{name:'Traditional Curative',value: 'Traditional Curative'}
        ]},
    {
        accessorName: "dispositionType", datasource: [
            {name:'Sale',value:'Sale'},{name:'Distribution',value: 'Distribution'}
        ]},
    {
        accessorName: "currentAssets", datasource: [
            {name:'Cash and Cash Equivalents',value:'Cash and Cash Equivalents'},{name:'Accounts Receivables',value: 'Accounts Receivables'},{name:'Inventory',value: 'Inventory'}
        ]},
    {
        accessorName: "intangibleAssets", datasource: [
            {name:'Goodwill',value:'Goodwill'},{name:'Patents',value: 'Patents'},{name:'Brand',value:'Brand'},{name:'Copyrights',value: 'Copyrights'},{name:'Trademarks',value:'Trademarks'},{name:'Trade secrets',value: 'Trade secrets'},{name:'Licenses and permits',value:'Licenses and permits'},{name:'Corporate intellectual property',value:'Corporate intellectual property'}
        ]},
    {
        accessorName: "fixedAssets", datasource: [
            {name:'Land',value:'Land'},{name:'Building',value: 'Building'},{name:'Machinery',value:'Machinery'},{name:'Equipment',value: 'Equipment'}
        ]},
    {
        accessorName: "gainTreatment", datasource: [
            {name:'Ordinary',value:'Ordinary'},{name:'Capital',value: 'Capital'},{name:'1245',value:'1245'},{name:'1250',value: '1250'}
        ]},
    {
        accessorName: "assetClass", datasource: [
            {name:'Current Assets',value:'Current Assets'},{name:'Intangible Assets',value: 'Intangible Assets'},{name:'Fixed Assets',value: 'Fixed Assets'}
        ]},
    {
        accessorName:
            "assetType"
        , datasource: [
            {name:
                    'Cash and Cash Equivalents'
                ,value:
                    'Cash and Cash Equivalents'
            },{name:
                    'Accounts Receivables'
                ,value:
                    'Accounts Receivables'
            },{name:
                    'Inventory'
                ,value:
                    'Inventory'
            },
            {name:
                    'Goodwill'
                ,value:
                    'Goodwill'
            },{name:
                    'Patents'
                ,value:
                    'Patents'
            },{name:
                    'Brand'
                ,value:
                    'Brand'
            },{name:
                    'Copyrights'
                ,value:
                    'Copyrights'
            },{name:
                    'Trademarks'
                ,value:
                    'Trademarks'
            },{name:
                    'Trade secrets'
                ,value:
                    'Trade secrets'
            },{name:
                    'Licenses and permits'
                ,value:
                    'Licenses and permits'
            },{name:
                    'Corporate intellectual property'
                ,value:
                    'Corporate intellectual property'
            },
            {name:
                    'Land'
                ,value:
                    'Land'
            },{name:
                    'Building'
                ,value:
                    'Building'
            },{name:
                    'Machinery'
                ,value:
                    'Machinery'
            },{name:
                    'Equipment'
                ,value:
                    'Equipment'
            }
        ]},
]

export  const incomeExpenseColDataSources: columnDataSource[]  =[{
    accessorName: "allocationMethod", datasource: [
        {name:'Prorata',value:'Prorata'},{name:'Special',value: 'Special'},{name:'704(b)',value: '704(b)'}
    ]},
]

export  const distributionColDataSources: columnDataSource[]  =[{
    accessorName: "distributionMethod", datasource: [
        {name:'Specific Partner',value: 'Specific Partner'},{name:'Prorata',value:'Prorata'},{name:'Special',value: 'Special'},{name:'704(b)',value: '704(b)'}
    ]},
    {
    accessorName: "distributionType", datasource: [
    {name:'Cash',value: 'Cash'},{name:'Asset',value:'Asset'}
    ]},
]
export  const accountsColDataSources: columnDataSource[]  =[{
    accessorName: "taxableIncomeAddSubtract", datasource: [
        {name:'Add',value:'Add'},{name:'Subtract',value: 'Subtract'},{name:'Not Applicable',value: 'Not Applicable'}
    ]},
    {
        accessorName: "income704BAddSubtract", datasource: [
            {name:'Add',value:'Add'},{name:'Subtract',value: 'Subtract'},{name:'Not Applicable',value: 'Not Applicable'}
        ]},
    {
        accessorName: "accountType", datasource: [
            {name:'Asset',value: 'Asset'},{name:'Liability',value:'Liability'},
            {name:'Equity',value: 'Equity'},{name:'Revenue',value:'Revenue'},
            {name:'Expense',value:'Expense'}
        ]},
]

export  const formDefsColDataSources: columnDataSource[]  =[{
    accessorName: "partType", datasource: [
        {name:'Part I',value:'Part I'},{name:'Part II',value: 'Part II'},{name:'Part III',value: 'Part III'}
    ]},
    {
        accessorName: "lineId", datasource: [
            {name:'A',value:'A'},{name:'B',value: 'B'},{name:'C',value:'C'},{name:'D',value: 'D'},
            {name:'E',value:'E'},{name:'F',value: 'F'},{name:'G',value:'G'},{name:'H1',value: 'H1'},{name:'H2',value: 'H2'},{name:'I1',value: 'I1'},{name:'I2',value: 'I2'},{name:'J',value: 'J'},{name:'K1',value: 'K1'},{name:'K2',value: 'K2'},{name:'K3',value: 'K3'},{name:'L',value: 'L'},{name:'M',value: 'M'},{name:'N',value: 'N'},
            {name:'1',value:'1'},{name:'2',value: '2'},{name:'3',value:'3'},{name:'4',value: '4'},{name:'5',value: '5'},{name:'6',value: '6'},{name:'7',value: '7'},{name:'8',value: '8'},{name:'9',value: '9'},{name:'10',value: '10'},{name:'11',value: '11'},{name:'12',value: '12'},{name:'13',value: '13'},{name:'14',value: '14'},{name:'15',value: '15'}
        ]},
    {
        accessorName: "partI", datasource: [
            {name:'A',value:'A'},{name:'B',value: 'B'},{name:'C',value: 'C'},{name:'D',value: 'D'}
        ]},
    {
        accessorName: "partII", datasource: [
            {name:'E',value:'E'},{name:'F',value: 'F'},{name:'G',value:'G'},{name:'H1',value: 'H1'},{name:'H2',value: 'H2'},{name:'I1',value: 'I1'},{name:'I2',value: 'I2'},{name:'J',value: 'J'},{name:'K1',value: 'K1'},{name:'K2',value: 'K2'},{name:'K3',value: 'K3'},{name:'L',value: 'L'},{name:'M',value: 'M'},{name:'N',value: 'N'}
        ]},
    {
        accessorName: "partIII", datasource: [
            {name:'1',value:'1'},{name:'2',value: '2'},{name:'3',value:'3'},{name:'4',value: '4'},{name:'5',value: '5'},{name:'6',value: '6'},{name:'7',value: '7'},{name:'8',value: '8'},{name:'9',value: '9'},{name:'10',value: '10'},{name:'11',value: '11'},{name:'12',value: '12'},{name:'13',value: '13'},{name:'14',value: '14'},{name:'15',value: '15'}
        ]},
    {
        accessorName: "formPart", datasource: [
            {name:'Top Right',value:'Top Right'},{name:'Middle',value: 'Middle'},{name:'Bottom Right',value: 'Bottom Right'},
            {name:'Top Left',value:'Top Left'},{name:'Bottom Left',value: 'Bottom Left'}
        ]},
]

export  const chartOfAccountColDataSources: columnDataSource[]  =[{
    accessorName: "taxableIncome", datasource: [
        {name:'Add',value: 'Add'},{name:'Substract',value:'Substract'}
    ]},
    {
    accessorName: "bookIncome", datasource: [
        {name:'Add',value: 'Add'},{name:'Substract',value:'Substract'}
    ]},
]
    export const formsColDataSources: columnDataSource[] = [{
    accessorName: "jurisdiction", datasource: [
    { value: 'IRS', name: 'Federal (IRS)' }, { value: 'AL', name: 'Alabama' }, { value: 'AK', name: 'Alaska' },
    { value: 'AZ', name: 'Arizona' }, { value: 'AR', name: 'Arkansas' }, { value: 'CA', name: 'California' },
    { value: 'CO', name: 'Colorado' }, { value: 'CT', name: 'Connecticut' }, { value: 'DE', name: 'Delaware' },
    { value: 'FL', name: 'Florida' }, { value: 'GA', name: 'Georgia' }, { value: 'HI', name: 'Hawaii' },
    { value: 'ID', name: 'Idaho' }, { value: 'IL', name: 'Illinois' }, { value: 'IN', name: 'Indiana' },
    { value: 'IA', name: 'Iowa' }, { value: 'KS', name: 'Kansas' }, { value: 'KY', name: 'Kentucky' },
    { value: 'LA', name: 'Louisiana' }, { value: 'ME', name: 'Maine' }, { value: 'MD', name: 'Maryland' },
    { value: 'MA', name: 'Massachusetts' }, { value: 'MI', name: 'Michigan' }, { value: 'MN', name: 'Minnesota' },
    { value: 'MS', name: 'Mississippi' }, { value: 'MO', name: 'Missouri' }, { value: 'MT', name: 'Montana' },
    { value: 'NE', name: 'Nebraska' }, { value: 'NV', name: 'Nevada' }, { value: 'NH', name: 'New Hampshire' },
    { value: 'NJ', name: 'New Jersey' }, { value: 'NM', name: 'New Mexico' }, { value: 'NY', name: 'New York' },
    { value: 'NC', name: 'North Carolina' }, { value: 'ND', name: 'North Dakota' }, { value: 'OH', name: 'Ohio' },
    { value: 'OK', name: 'Oklahoma' }, { value: 'OR', name: 'Oregon' }, { value: 'PA', name: 'Pennsylvania' },
    { value: 'RI', name: 'Rhode Island' }, { value: 'SC', name: 'South Carolina' }, { value: 'SD', name: 'South Dakota' },
    { value: 'TN', name: 'Tennessee' }, { value: 'TX', name: 'Texas' }, { value: 'UT', name: 'Utah' },
    { value: 'VT', name: 'Vermont' }, { value: 'VA', name: 'Virginia' }, { value: 'WA', name: 'Washington' },
    { value: 'WV', name: 'West Virginia' }, { value: 'WI', name: 'Wisconsin' }, { value: 'WY', name: 'Wyoming' }

]},
    ]
export  const formMappingColDataSources: columnDataSource[]  =[{
    accessorName: "formDefinitionPartType", datasource: [
        {name:'Part I',value:'Part I'},{name:'Part II',value: 'Part II'},{name:'Part III',value: 'Part III'}
    ]},
    {
        accessorName: "formDefinitionLineId", datasource: [
            {name:'A',value:'A'},{name:'B',value: 'B'},{name:'C',value:'C'},{name:'D',value: 'D'},
            {name:'E',value:'E'},{name:'F',value: 'F'},{name:'G',value:'G'},{name:'H1',value: 'H1'},{name:'H2',value: 'H2'},{name:'I1',value: 'I1'},{name:'I2',value: 'I2'},{name:'J',value: 'J'},{name:'K1',value: 'K1'},{name:'K2',value: 'K2'},{name:'K3',value: 'K3'},{name:'L',value: 'L'},{name:'M',value: 'M'},{name:'N',value: 'N'},
            {name:'1',value:'1'},{name:'2',value: '2'},{name:'3',value:'3'},{name:'4',value: '4'},{name:'5',value: '5'},{name:'6',value: '6'},{name:'7',value: '7'},{name:'8',value: '8'},{name:'9',value: '9'},{name:'10',value: '10'},{name:'11',value: '11'},{name:'12',value: '12'},{name:'13',value: '13'},{name:'14',value: '14'},{name:'15',value: '15'}
        ]},
    {
        accessorName: "partI", datasource: [
            {name:'A',value:'A'},{name:'B',value: 'B'},{name:'C',value: 'C'},{name:'D',value: 'D'}
        ]},
    {
        accessorName: "partII", datasource: [
            {name:'E',value:'E'},{name:'F',value: 'F'},{name:'G',value:'G'},{name:'H1',value: 'H1'},{name:'H2',value: 'H2'},{name:'I1',value: 'I1'},{name:'I2',value: 'I2'},{name:'J',value: 'J'},{name:'K1',value: 'K1'},{name:'K2',value: 'K2'},{name:'K3',value: 'K3'},{name:'L',value: 'L'},{name:'M',value: 'M'},{name:'N',value: 'N'}
        ]},
    {
        accessorName: "partIII", datasource: [
            {name:'1',value:'1'},{name:'2',value: '2'},{name:'3',value:'3'},{name:'4',value: '4'},{name:'5',value: '5'},{name:'6',value: '6'},{name:'7',value: '7'},{name:'8',value: '8'},{name:'9',value: '9'},{name:'10',value: '10'},{name:'11',value: '11'},{name:'12',value: '12'},{name:'13',value: '13'},{name:'14',value: '14'},{name:'15',value: '15'}
        ]},
    {
        accessorName: "formDefinitionFormPart", datasource: [
            {name:'Top Right',value:'Top Right'},{name:'Middle',value: 'Middle'},{name:'Bottom Right',value: 'Bottom Right'},
            {name:'Top Left',value:'Top Left'},{name:'Bottom Left',value: 'Bottom Left'}
        ]},
    {
        accessorName: "formJurisdiction", datasource: [
            { value: 'IRS', name: 'Federal (IRS)' }, { value: 'AL', name: 'Alabama' }, { value: 'AK', name: 'Alaska' },
            { value: 'AZ', name: 'Arizona' }, { value: 'AR', name: 'Arkansas' }, { value: 'CA', name: 'California' },
            { value: 'CO', name: 'Colorado' }, { value: 'CT', name: 'Connecticut' }, { value: 'DE', name: 'Delaware' },
            { value: 'FL', name: 'Florida' }, { value: 'GA', name: 'Georgia' }, { value: 'HI', name: 'Hawaii' },
            { value: 'ID', name: 'Idaho' }, { value: 'IL', name: 'Illinois' }, { value: 'IN', name: 'Indiana' },
            { value: 'IA', name: 'Iowa' }, { value: 'KS', name: 'Kansas' }, { value: 'KY', name: 'Kentucky' },
            { value: 'LA', name: 'Louisiana' }, { value: 'ME', name: 'Maine' }, { value: 'MD', name: 'Maryland' },
            { value: 'MA', name: 'Massachusetts' }, { value: 'MI', name: 'Michigan' }, { value: 'MN', name: 'Minnesota' },
            { value: 'MS', name: 'Mississippi' }, { value: 'MO', name: 'Missouri' }, { value: 'MT', name: 'Montana' },
            { value: 'NE', name: 'Nebraska' }, { value: 'NV', name: 'Nevada' }, { value: 'NH', name: 'New Hampshire' },
            { value: 'NJ', name: 'New Jersey' }, { value: 'NM', name: 'New Mexico' }, { value: 'NY', name: 'New York' },
            { value: 'NC', name: 'North Carolina' }, { value: 'ND', name: 'North Dakota' }, { value: 'OH', name: 'Ohio' },
            { value: 'OK', name: 'Oklahoma' }, { value: 'OR', name: 'Oregon' }, { value: 'PA', name: 'Pennsylvania' },
            { value: 'RI', name: 'Rhode Island' }, { value: 'SC', name: 'South Carolina' }, { value: 'SD', name: 'South Dakota' },
            { value: 'TN', name: 'Tennessee' }, { value: 'TX', name: 'Texas' }, { value: 'UT', name: 'Utah' },
            { value: 'VT', name: 'Vermont' }, { value: 'VA', name: 'Virginia' }, { value: 'WA', name: 'Washington' },
            { value: 'WV', name: 'West Virginia' }, { value: 'WI', name: 'Wisconsin' }, { value: 'WY', name: 'Wyoming' }

        ]},
]
export const formDefColDataSources = [
    {
        accessorName: "partType",
        datasource: new DataManager({
            url: "https://uiintegrationgateway.azurewebsites.net/api/COAManagement/Part",
            adaptor: new WebApiAdaptor(),
            crossDomain: true,
            map: (response) => {
                return response.map((item) => ({
                    name: item.name, // Assuming "name" is the property containing the name of the part
                    value: item.txPartId // Assuming "value" is the property containing the value of the part
                }));
            }
        })
    }
];

export  const revaluationsColDataSources: columnDataSource[]  =[{
    accessorName: "depreciationMethod", datasource: [
        {name:'MACRS',value:'MACRS'},{name:'Straight Line',value: 'SL'},{name:'Units of Consumption',value: 'Units of Consumption'},{name:'Depletion',value: 'Depletion'}
    ]},
]


export function getDataSource(variableName: string): { [key: string]: any } | undefined {
    // Create a dictionary with variable names as keys and data sources as values
    const allColumnDataSources: { [key: string]: columnDataSource[] } = {
        partnershipColDataSources,
        partnerColDataSources,
        contributionColDataSources,
        transferColDataSources,
        redemptionColDataSources,
        assetsColDataSources,
        incomeExpenseColDataSources,
        distributionColDataSources
    };

    // Find the key that starts with the provided variable name
    const key = Object.keys(allColumnDataSources).find(k => k.startsWith(variableName));
    console.warn("Inside get datasources ...." , key);
    // If a key is found, return a dictionary where the keys are the accessorName and the values are the datasource
    // If no key is found, return undefined
    return key ? allColumnDataSources[key].reduce((acc, cur) => {
        acc[cur.accessorName] = cur.datasource;
        return acc;
    }, {}) : undefined;
}
