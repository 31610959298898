import React, { useEffect, useState } from 'react';
import {toast, ToastContainer} from 'react-toastify';
import { fetchData as fetchPartnershipData } from "../../BL/Partnerships";
import { fetchData as fetchClientData } from "../../BL/Clients";
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { saveAs } from 'file-saver';
import useApi from "../../hooks/useApi";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Inject } from '@syncfusion/ej2-react-grids';
import BounceLoader from "react-spinners/BounceLoader";
import LoadingOverlay from "react-loading-overlay-ts";

const endpoints = {
    getAll: 'Partnerships',
    getById: 'Partnerships',
    addData: 'Partnerships',
    updateData: 'Partnerships',
    deleteData: 'Partnerships',
};

const DataManager: React.FC = () => {
    const [baseUrl] = useState<string>(process.env.REACT_APP_UIINTEGRATION_BASE_URL || '');
    const [loading, setLoading] = useState(false);
    const [clientList, setClientList] = useState<any[]>([]);
    const [partnershipData, setPartnershipData] = useState<any[]>([]);
    const { getDataByParams } = useApi(endpoints, baseUrl);

    const [clientId, setClientId] = useLocalStorage("id", "");
    const [selectedClient, setSelectedClient] = useLocalStorage("selectedClient", "");

    const fetchClientList = async () => {
        setLoading(true);
        try {
            const clientData = await fetchClientData(getDataByParams);
            if (clientData.success) {
                setClientList(clientData.data);
            } else {
                toast.error("An error occurred while fetching client data: " + clientData.error);
            }
        } catch (error) {
            toast.error("An error occurred while fetching client data.");
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const refreshData = async () => {
        setLoading(true);
        try {
            const result = await fetchPartnershipData(getDataByParams, clientId);
            if (result.success) {
                if (result.data.length > 0) {
                    setPartnershipData(result.data);
                    toast.success("Data refreshed successfully!");
                } else {
                    setPartnershipData([]);
                    toast.error("No data found...");
                }
            } else {
                toast.error("An error occurred while fetching the data: " + result.error);
            }
        } catch (error) {
            toast.error("An error occurred while refreshing the data.");
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchClientList();
    }, []);

    useEffect(() => {
        if (clientId) {
            refreshData();
        }
    }, [clientId]);

    const handleExport = (partnershipId: string) => {
        if (clientId && partnershipId) {
            const url = `https://uiintegrationgateway.azurewebsites.net/api/PartnershipCreation/export?clientId=${clientId}&partnershipId=${partnershipId}`;
            fetch(url)
                .then(response => response.blob())
                .then(blob => {
                    saveAs(blob, `export_${partnershipId}.xlsx`);
                })
                .catch(error => {
                    toast.error("An error occurred while exporting the data.");
                    console.error(error);
                });
        }
    };

    const fields = { text: 'text', value: 'clientID' };
    const modifiedClientList = clientList.map(client => ({
        clientID: client.clientID,
        clientName: client.clientName,
        text: `${client.clientName} - ${client.clientID}`
    }));

    const onClientChange = (args: any) => {
        setClientId(args.itemData.clientID);
        setSelectedClient(args.itemData.clientName); // Optional, if you need the name elsewhere
    };


    return (
        <div className="page-content">
            <ToastContainer />
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader />}
                text='Loading data...'
            >            <div className="col-3">
                <DropDownListComponent
                    id="clientDropdown"
                    dataSource={modifiedClientList}
                    fields={fields}
                    allowFiltering={true}
                    value={clientId}
                    placeholder="Select Client"
                    change={onClientChange}
                    floatLabelType="Auto"
                />

            </div>

            {partnershipData.length > 0 && (
                <div>
                    <h2>Partnerships for Client {selectedClient}</h2>
                    <GridComponent dataSource={partnershipData} allowPaging={true}>
                        <ColumnsDirective>
                            <ColumnDirective field='clientID' headerText='Client ID' width='100' textAlign="Center" />
                            <ColumnDirective field='partnershipID' headerText='Partnership ID' width='150' textAlign="Center" />
                            <ColumnDirective
                                headerText='Export'
                                width='100'
                                textAlign="Center"
                                template={(rowData: any) => (
                                    <button onClick={() => handleExport(rowData.partnershipID)}>
                                        Export
                                    </button>
                                )}
                            />
                        </ColumnsDirective>
                        <Inject services={[Page]} />
                    </GridComponent>
                </div>
            )}
            </LoadingOverlay>

        </div>
    );
};

export default DataManager;
