import React, {useState} from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay-ts';
import BounceLoader from 'react-spinners/BounceLoader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RevalAssetOverride from "./RevalAssetOverride";
import RevalBigbilOverride from "./RevalBigbilOverride";

const Overrides: React.FC = () => {
    const [loading, setLoading] = useState(false);

    return (
        <div className="page-content">
            <ToastContainer />
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader />}
                text="Loading data..."
            >
                <div className="container-fluid" id="tabHolder">
                    <Tabs
                        defaultActiveKey="forms"
                        id="fill-tab-example"
                        className="mb-3 tab-container"
                        fill
                        navbar
                        navbarScroll
                    >
                        <Tab eventKey="forms" title="Revaluation Asset Overrides">
                            <div className="card-body">
                                <RevalAssetOverride />
                            </div>
                        </Tab>
                        <Tab eventKey="form-definition" title="Revaluation Bigbil Overrides">
                            <div className="card-body">
                                <RevalBigbilOverride />
                            </div>
                        </Tab>
                    </Tabs>
                    <br />
                    <br />
                </div>
            </LoadingOverlay>
        </div>
    );
};

export default Overrides;
