import React, {useEffect, useMemo, useRef, useState} from 'react';

import Breadcrumb from "../../Components/Common/Breadcrumb";
import DataGrid from "../../Components/Common/DataGrid";
import {contributionColDef} from "../../uihelper/gridutil";
import {fetchData} from '../../BL/Contributions';
import LoadingOverlay from 'react-loading-overlay-ts';
import BounceLoader from 'react-spinners/BounceLoader'
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useApi from "../../hooks/useApi";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import moment from "moment";
import {contributionColDataSources} from "../../uihelper/columndatasources";
import axios from "axios";

const endpoints = {
    getAll: 'api/ContributionManagement/contribution',
    getById: 'api/ContributionManagement/contribution',
    addData: 'api/ContributionManagement/contribution',
    updateData: 'api/ContributionManagement/contribution',
    deleteData: 'api/ContributionManagement/contribution',
};

const Contributions = () => {

    const [contributionsData, setContributionsData] = useState<any[]>([]);
    const [baseUrl ] = useState<string>(process.env.REACT_APP_UIINTEGRATION_BASE_URL || '');

    const { getDataByParams, addData, updateData, deleteData} = useApi(endpoints, baseUrl);
    const [loading, setLoading] = useState(false);

    const [clientId, setClientId] = useLocalStorage("id", "");
    const [partnershipId, setPartnershipId] = useLocalStorage("pid", "");
    const [clientList, setClientList] = useLocalStorage("clients", "");
    const [selectedClient, setSelectedClient] = useLocalStorage("selectedClient", "");
    const [selectedPartnership, setSelectedPartnership] = useLocalStorage("selectedPartnership", "");

    console.log(selectedClient)
    console.log(selectedPartnership)
    const refreshData = () => {
        setLoading(true);
        fetchData(getDataByParams, partnershipId, clientId)
            .then(result => {
                setLoading(false);
                if(result.success) {
                    if(result.data.length > 0) {
                        setContributionsData(result.data);
                        console.log("Value of the data is ...", result.data);
                    } else {
                        toast.error("No data found...")
                    }
                } else {
                    // Handle error from fetchData
                    toast.error("An error occurred while fetching the data: " + result.error);
                }
            })
            .catch(error => {
                toast.error("An error occurred while refreshing the data.");
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
       refreshData();
    }, []);

    const getBreadcrumbItems  = () => {
        breadcrumbItems.push({title: selectedClient.clientName + " (" + selectedClient.clientID + ")", url: "clienthome"});
        breadcrumbItems.push({title: selectedPartnership.partnershipName + " (" + selectedPartnership.partnershipID + ")", url: "partnershiphome"});
        return breadcrumbItems;
    }

    const breadcrumbItems =
        [
            {
                "title": "Clients",
                "url": "Clients"
            }
        ]

    const onDataGridDelete = (data:any) => {
        console.log(data.data)
        deleteData(data?.data[0]?.id,'id').then((response:any) => {
            toast.success("Data deleted successfully!");
            refreshData();
            setLoading(false);
        },error => {
            toast.error("An error occurred while deleting the data.");
            console.error(error);
            setLoading(false);
        });
    };

    // update grid data
    const onDataGridUpdate = async (data:any) => {
        if(data.data.assetID===null || data.data.assetID===undefined)
            data.data.assetID="";
        /*if(data.data.fractionOfContribution===null || data.data.fractionOfContribution===undefined)
            data.data.fractionOfContribution="";
        if(data.data.unitsAssigned===null || data.data.unitsAssigned===undefined)
            data.data.unitsAssigned="";*/
        if(data.data.unitsClass===null || data.data.unitsClass===undefined)
            data.data.unitsClass="";
        const result = await updateData(clientId,
            data.data,'id')
        if (result.success) {
            console.log('Data updated successfully:', result.data);
            toast.success("Data updated successfully!");
            refreshData();
            setLoading(false);
        } else {
            console.error('Failed to update data:', result.error);
            if (result.data) {
                toast.error(result.data.data);
            } else {
                toast.error("An error occurred while updating the data.", result.message);
            }

            console.error(result.error);
            setLoading(false);
        }
    }

    // add data
    const onDataGridAdd = async (rowData: any) => {
        rowData.data.clientID = clientId;
        rowData.data.partnershipID = partnershipId;

        delete rowData.data.id;
        if (rowData.data.assetID === null || rowData.data.assetID === undefined)
            rowData.data.assetID = "";
        if (rowData.data.unitsClass === null || rowData.data.unitsClass === undefined)
            rowData.data.unitsClass = "";

            const result = await addData(rowData.data);

            if (result.success) {
                console.log('Data added successfully:', result.data);
                toast.success("Data added successfully!");
                refreshData();
                setLoading(false);
            } else {
                console.error('Failed to add data:', result.error);
                if (result.data) {
                    toast.error(result.data.data);
                } else {
                    toast.error("An error occurred while adding the data.", result.message);
                }

                console.error(result.error);
                setLoading(false);
            }

    };

    return (
        <div className="page-content">
            <ToastContainer />
            <LoadingOverlay
                active={loading}
                spinner={<BounceLoader  />}
                text='Loading data...'
            >
            <div className={"container-fluid"}>
                <Breadcrumb items={getBreadcrumbItems()} title={selectedClient.clientName} breadcrumbItem="Contributions" />
                <DataGrid  id={"gridPartnerContrPartnerships"} columns={contributionColDef}
                          data={contributionsData} editing={true} add={true} del={true} onAdd={onDataGridAdd}
                          onDelete={onDataGridDelete} onUpdate={onDataGridUpdate}
                           hasColumnDataSource={true} columnDataSources={contributionColDataSources}
                           pageType={"Contribution"}
                           hasExport={true}
                           pageName="Contributions"
                />
            </div>
            </LoadingOverlay>
        </div>
    );
}

export default Contributions;
