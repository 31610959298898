// @ts-ignore
import Keycloak from 'keycloak-js';
import {OidcClientSettings} from "oidc-client-ts";

export const oidcConfig: any = {
    authority: process.env.REACT_APP_KEYCLOAK_AUTHORITY,
    client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    redirect_uri: window.location.origin + '/callback',
    post_logout_redirect_uri: window.location.origin,
    response_type: 'code',
    scope: 'openid profile email', // Adjust scopes as necessary
    automaticSilentRenew: true,
    loadUserInfo: true,
    client_secret: process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET
};

export const keycloakConfig: any = {
    url: process.env.REACT_APP_KEYCLOAK_INSTANCE_URL,
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    credentials: {
        secret: process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET
    }
};

export const keycloakInstance = new Keycloak(keycloakConfig);