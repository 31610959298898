import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthProtected: React.FC<{ children: React.ReactNode }> = ({ children }) => {


  const authUser = useSelector((state: any) => {
    console.warn("State is ******************",state); // This will print the entire state to the console
    return state.Login.user;
  });
  console.warn("Auth user is ^^^^^^^^^^^^^^^^^^^^^",authUser);

  if (!authUser || !authUser.isAuthenticated) {
    console.log('User not authenticated, redirecting to login');
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

export default AuthProtected;
