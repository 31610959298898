import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from './slices';
import { pdfjs } from 'react-pdf';
import AppWithProvider from "./App";

// Configure the worker source
pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.js';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
});

root.render(
    <Provider store={store}>
        <React.Fragment>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <AppWithProvider />
            </BrowserRouter>
        </React.Fragment>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
