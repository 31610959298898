import React, { useState, useEffect } from 'react';
import { DropDownListComponent, MultiSelectComponent, CheckBoxSelection, Inject } from '@syncfusion/ej2-react-dropdowns';
import Breadcrumb from "../../Components/Common/Breadcrumb";
import { Form, Button } from 'react-bootstrap';
import useApi from "../../hooks/useApi";
import axios from "axios";

const endpoints = {
    getAllRoles: 'api/RbacManagement/Role',
    getAllModules: 'api/RbacManagement/Module',
    getAllOperations: 'api/RbacManagement/Operations',
    getAllRolePermissions: 'api/RbacManagement/RolePermission',
    bulkInsertPermissions: 'api/RbacManagement/RolePermission/BulkInsert',
};

const RolePermissions = () => {
    const [roles, setRoles] = useState([]);
    const [modules, setModules] = useState<{ txModuleId: string; txModuleName: string }[]>([]);
    const [operations, setOperations] = useState<{ txOperationId: string; txOperationName: string }[]>([]);
    const [selectedRole, setSelectedRole] = useState<string | null>(null);
    const [selectedModules, setSelectedModules] = useState<string[]>([]);
    const [permissions, setPermissions] = useState<{ [key: string]: boolean }>({});
    const [showTable, setShowTable] = useState(false);
    const [baseUrl] = useState<string>(process.env.REACT_APP_UIINTEGRATION_BASE_URL || '');
    const { getDataByParams } = useApi(endpoints, baseUrl);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const rolesResponse = await getDataByParams({ endpoint: endpoints.getAllRoles });
                setRoles(rolesResponse.data);

                const modulesResponse = await getDataByParams({ endpoint: endpoints.getAllModules });
                setModules(modulesResponse.data);

                const operationsResponse = await getDataByParams({ endpoint: endpoints.getAllOperations });
                setOperations(operationsResponse.data);

            } catch (error) {
                console.error('Error fetching roles, modules, or operations:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const fetchRolePermissions = async (roleId: string, moduleIds: string[]) => {
        try {
            setLoading(true);
            const response = await axios.get(`${baseUrl}/${endpoints.getAllRolePermissions}`);

            if (Array.isArray(response)) { // Assuming response is an array directly
                const updatedPermissions: { [key: string]: boolean } = {};

                response.forEach((permission: any) => {
                    const { txRoleId, txModuleId, txOperationId, permissionValue } = permission;
                    if (roleId === txRoleId && moduleIds.includes(txModuleId)) {
                        updatedPermissions[`${txModuleId}-${txOperationId}`] = permissionValue;
                    }
                });

                setPermissions(updatedPermissions);
                setShowTable(true);
            } else {
                console.error('Invalid or empty data returned from RolePermission API:', response);
            }
        } catch (error) {
            console.error('Error fetching role permissions:', error);
        } finally {
            setLoading(false);
        }
    };


    const handleRoleChange = (args: any) => {
        setSelectedRole(args.itemData.txRoleId);
        setSelectedModules([]);
        setPermissions({});
        setShowTable(false);
    };

    const handleModuleChange = (args: any) => {
        const selectedModuleIds = args.value.map((moduleName: string) => {
            const module = modules.find((module: any) => module.txModuleName === moduleName);
            return module ? module.txModuleId : undefined;
        }).filter((moduleId): moduleId is string => !!moduleId);
        setSelectedModules(selectedModuleIds);
    };

    const handleOKClick = async () => {
        if (selectedRole && selectedModules.length > 0) {
            fetchRolePermissions(selectedRole, selectedModules);
        }
    };

    const handleChange = (moduleId: string, operationId: string, isChecked: boolean) => {
        const permissionKey = `${moduleId}-${operationId}`;
        setPermissions(prevPermissions => ({
            ...prevPermissions,
            [permissionKey]: isChecked
        }));
    };
    const handleSaveClick = async () => {
        try {
            setLoading(true);
            const rolePermissionsToSave = Object.entries(permissions).map(([key, value]) => {
                const [moduleId, operationId] = key.split('-');
                return {
                    txRolePermissionId: 0, // Assuming this is for a new permission record
                    txRoleId: parseInt(selectedRole!, 10), // Ensure txRoleId is parsed to a number if needed
                    txModuleId: parseInt(moduleId, 10),
                    txOperationId: parseInt(operationId, 10),
                    permissionValue: value
                };
            });

            const response = await axios.post(`${baseUrl}/${endpoints.bulkInsertPermissions}`, rolePermissionsToSave);
            console.log('Role permissions saved:', response.data);

            // Optionally, fetch updated permissions after save
            fetchRolePermissions(selectedRole!, selectedModules);
        } catch (error) {
            console.error('Error saving role permissions:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumb title="Admin" breadcrumbItem="Role Permissions" />

                <div className="row">
                    <div className="col-md-3">
                        <Form.Group controlId="role-select">
                            <Form.Label>Select Roles:</Form.Label>
                            <DropDownListComponent
                                dataSource={roles}
                                placeholder="Select a role..."
                                fields={{ text: 'txRoleName', value: 'txRoleId' }}
                                value={selectedRole}
                                change={handleRoleChange}
                            />
                        </Form.Group>
                    </div>

                    <div className="col-md-3">
                        <Form.Group controlId="module-select">
                            <Form.Label>Select Modules:</Form.Label>
                            <MultiSelectComponent
                                dataSource={modules}
                                mode="CheckBox"
                                showDropDownIcon={true}
                                fields={{ text: 'txModuleName', value: 'txModuleName' }}
                                value={selectedModules.map(moduleId => {
                                    const module = modules.find(module => module.txModuleId === moduleId);
                                    return module ? module.txModuleName : '';
                                })}
                                placeholder="Select modules..."
                                onChange={handleModuleChange}
                                cssClass="multiselect-dropdown"
                                popupHeight="300px"
                                allowFiltering={true}
                                filterBarPlaceholder="Search modules"
                            >
                                <Inject services={[CheckBoxSelection]} />
                            </MultiSelectComponent>
                        </Form.Group>
                    </div>

                    <div className="col-md-3 mt-4">
                        <Button className="btn btn-primary mt-2" onClick={handleOKClick}>
                            OK
                        </Button>
                    </div>
                    <div className="col-md-3 mt-4">
                        <Button className="btn btn-primary mt-2" onClick={handleSaveClick} disabled={!selectedRole || selectedModules.length === 0 || !showTable}>
                            Save
                        </Button>
                    </div>
                </div>

                {selectedRole && selectedModules.length > 0 && showTable && (
                    <div className="row">
                        <div className="col-md-12">
                            <table className="table table-bordered mt-4 text-center">
                                <colgroup>
                                    <col style={{ width: "30%" }} />
                                    <col style={{ width: "30%" }} />
                                    <col style={{ width: "40%" }} />
                                </colgroup>
                                <thead>
                                <tr>
                                    <th>Module</th>
                                    <th>Operations</th>
                                    <th>Permissions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {selectedModules.map(moduleId => {
                                    const module = modules.find(mod => mod.txModuleId === moduleId);
                                    return module ? (
                                        operations.map(operation => {
                                            const permissionKey = `${moduleId}-${operation.txOperationId}`;
                                            return (
                                                <tr key={permissionKey}>
                                                    {operations.indexOf(operation) === 0 && (
                                                        <td rowSpan={operations.length}>{module.txModuleName}</td>
                                                    )}
                                                    <td>{operation.txOperationName}</td>
                                                    <td>
                                                        <label style={{ marginRight: '10px' }}>
                                                            <input
                                                                type="radio"
                                                                name={permissionKey}
                                                                checked={permissions[permissionKey]}
                                                                onChange={() => handleChange(moduleId, operation.txOperationId, true)}
                                                            />
                                                            Yes
                                                        </label>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                name={permissionKey}
                                                                checked={!permissions[permissionKey]}
                                                                onChange={() => handleChange(moduleId, operation.txOperationId, false)}
                                                            />
                                                            No
                                                        </label>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : null;
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RolePermissions;
