import React from 'react';
import '../../assets/scss/bootstrap.scss'; // Import Bootstrap SCSS

interface SessionExpiredCardProps {
    onRelogin: () => void;
}

const SessionExpiredCard: React.FC<SessionExpiredCardProps> = ({ onRelogin }) => {
    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="card text-white bg-dark mb-3" style={{ maxWidth: '20rem' }}>
                <div className="card-header">
                    <i className="bi bi-exclamation-triangle-fill" style={{ fontSize: '2rem' }}></i>
                    <h5 className="card-title mt-2">Session Expired</h5>
                </div>
                <div className="card-body">
                    <p className="card-text">Your session has expired. Please log in again.</p>
                    <button className="btn btn-danger" onClick={onRelogin}>
                        Login
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SessionExpiredCard;
