import { useState } from 'react';
import ApiHelper from './ApiHelper';
import axios from 'axios';

export interface Endpoints {
  [key: string]: string;
}
//refactor this as per the chat
export interface GetDataParams {
  endpoint: string;
  params?: { [key: string]: string | number };
}

function useApi<T>(endpoints: Endpoints,baseUrl?:string ) {
  const [data, setData] = useState<T[]>([]);
  const [item, setItem] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  //added for retries
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 1000; // Delay in milliseconds

  const apiHelper = new ApiHelper<T>(baseUrl);
  const getAll = async () => {
    if (!endpoints['getAll']) {
      throw new Error('getAll endpoint is not provided');
    }

    setLoading(true);
    try {
      console.error("calling api helper get all..." + endpoints['getAll'])
      const response = await apiHelper.get(endpoints['getAll']);
      setData(response as T[]);
      setLoading(false);
      return { success: true, data: response as T[] };
    } catch (err:any) {
      setLoading(false);
      return {
        success: false,
        error: err as Error,
        message: err.message, // Add this line
        status: err.status, // And this line
        data: err.data // And this one
      };
    }
  };

  const getById = async (id: string) => {
    if (!endpoints['getById']) {
      throw new Error('getById endpoint is not provided');
    }

    setLoading(true);
    try {
      const response = await apiHelper.get(`${endpoints['getById']}/${id}`);
      setItem(response as T);
      setLoading(false);
      return { success: true, data: response as T };
    } catch (err:any) {
      setLoading(false);
      return {
        success: false,
        error: err as Error,
        message: err.message, // Add this line
        status: err.status, // And this line
        data: err.data // And this one
      };
    }
  };

  const addData = async (newData: T) => {
    if (!endpoints['addData']) {
      throw new Error('addData endpoint is not provided');
    }

    setLoading(true);
    try {
     console.log("useApi - updating: ",endpoints['updateData'],JSON.stringify(newData))
    //  console.log("useApi - updating: ",endpoints['updateData'],newData)
      const response = await apiHelper.post(endpoints['addData'], newData);
      setData(prevData => [...prevData, response as T]);
      setLoading(false);
      return { success: true, data: response as T };
    } catch (err:any) {
      setLoading(false);
      return {
        success: false,
        error: err as Error,
        message: err.message, // Add this line
        status: err.status, // And this line
        data: err.response // And this one
      };
    }
  };

  const updateData = async (id: string, updatedData: T, idField: string) => {
    if (!endpoints['updateData']) {
      throw new Error('updateData endpoint is not provided');
    }

    setLoading(true);
  //  console.log("useApi - updating: ",endpoints['updateData'],JSON.stringify(updatedData))
    try {
      await apiHelper.put(endpoints['updateData'], JSON.stringify(updatedData));
      setData(prevData => prevData.map(item => (item[idField] as string) === id ? updatedData : item));
      setLoading(false);
      return { success: true };
    }catch (err:any) {
      setLoading(false);
      return {
        success: false,
        error: err as Error,
        message: err.message, // Add this line
        status: err.status, // And this line
        data: err.response // And this one
      };
    }
  };


  const deleteData = async (id: string, idField: string, clientId?: string) => {
 //   console.log("useApi-deleteData: ", id)
    if (!endpoints['deleteData']) {
      throw new Error('deleteData endpoint is not provided');
    }

    setLoading(true);
    try {
      let url = endpoints['deleteData']+"?ids="+id;
      await apiHelper.delete(url);
      setData(prevData => prevData.filter(item => (item[idField] as string) !== id));
      setLoading(false);
      return { success: true };
    } catch (err:any) {
      setLoading(false);
      return {
        success: false,
        error: err as Error,
        message: err.message, // Add this line
        status: err.status, // And this line
        data: err.data // And this one
      };
    }
  };


const uploadFile = async (url: string, file: File, additionalData: any): Promise<string> => {
  setLoading(true);
  try {
    await apiHelper.uploadFile(url, file, additionalData);
    return 'File uploaded successfully';
  } catch (err) {
    setError(err as Error);
    throw err;
  } finally {
    setLoading(false);
  }
};


const submitMultipartForm = async (endpoint: string, formData: FormData,apiType:number): Promise<any> => {
  setLoading(true);
  let response;
  let token;
  let config:any;

  if(process.env.REACT_APP_DEFAULTAUTH === "oidc" && sessionStorage.getItem("authUser") !== null){
    token = JSON.parse(sessionStorage.getItem("authUser") || '{}').token;
  }

  try {
    let baseUrl = '';
    if(apiType===1){
      baseUrl = process.env.REACT_APP_CALCULATIONS_BASE_URL as string;
    }else if(apiType===2){
      baseUrl = process.env.REACT_APP_PARTNERSHIP_BASE_URL as string;
    }

  //  console.log(baseUrl)
    const url = baseUrl + endpoint;
  //  console.log(url)
    // add http headers
    if(token){
      config = {
        headers: {
          'content-type': 'multipart/form-data;',
            'Authorization': `Bearer ${token}`
        },
      };
    }else {
        config = {
            headers: {
            'content-type': 'multipart/form-data;',
            },
        };
    }


    // add this to axios posst
    response = await axios.post(url, formData,config);
    console.error("response is ...",response)

  } catch (error) {
    setError(error as Error);
    throw error;
  } finally {
    setLoading(false);
  }

  return response;
};



const downloadFile = async (url: string, id: string, additionalParams: any): Promise<T> => {
  setLoading(true);
  try {
    const blob = await apiHelper.downloadFile(url, id, additionalParams);
    return blob;
  } catch (err) {
    setError(err as Error);
    throw err;
  } finally {
    setLoading(false);
  }
};

const getData = async (endpoint: string, id?: string) => {
  setLoading(true);
  try {
    let response;
    if (id) {
      response = await apiHelper.get(`${endpoint}/${id}`);
      setItem(response as T);
    } else {
      response = await apiHelper.get(endpoint);
      setData(response as T[]);
    }
  } catch (err:any) {
    setLoading(false);
    return {
      success: false,
      error: err as Error,
      message: err.message, // Add this line
      status: err.status, // And this line
      data: err.data // And this one
    };
  }
  setLoading(false);
};

const postData = async (endpoint: string, data: T = {} as T) => {
  setLoading(true);
  try {
    const response = await apiHelper.post(endpoint, data);
    setData(prevData => [...prevData, response as T]);

  } catch (err:any) {
    setLoading(false);
    return {
      success: false,
      error: err as Error,
      message: err.message, // Add this line
      status: err.status, // And this line
      data: err.data // And this one
    };
  }
  setLoading(false);
};

const getDataByParams = async ({ endpoint, params }: GetDataParams) => {
  setLoading(true);
  const authUser = sessionStorage.getItem('authUser');
  const token = authUser ? JSON.parse(authUser).token : '';


//  console.warn("token IS..............", token );
  try {
    let  response:any;
    if(token){
      response = await axios.get(`${process.env.REACT_APP_UIINTEGRATION_BASE_URL}${endpoint}`, {
        params,
        headers: { Authorization: `Bearer ${token}` }
      });
    }else{
        response = await axios.get(`${process.env.REACT_APP_UIINTEGRATION_BASE_URL}${endpoint}`, { params });
    }

 //   console.log("------------------ Data Is --------- ", response)
    setData(response as T[]);
    return { success: true, data: response as T[] };
  } catch (err:any) {
    setLoading(false);
    return {
      success: false,
      error: err as Error,
      message: err.message, // Add this line
      status: err.status, // And this line
      data: err.data // And this one
    };
  } finally {
    setLoading(false);
  }
};


  const getDataByParamsK1Form = async ({ endpoint, params }: GetDataParams) => {
    setLoading(true);
    const authUser = sessionStorage.getItem('authUser');
    const token = authUser ? JSON.parse(authUser).token : '';


    try {

      let  response:any;
      if(token){
        response = await axios.get(`${process.env.REACT_APP_UIDFK1_BASE_URL}${endpoint}`, {
          params,
          headers: { Authorization: `Bearer ${token}` }
        });
      }else{
        response = await axios.get(`${process.env.REACT_APP_UIDFK1_BASE_URL}${endpoint}`, { params });
      }
 //     console.log("------------------ Data Is --------- ", response)
      setData(response as T[]);
      return { success: true, data: response as T[] };
    } catch (err:any) {
      setLoading(false);
      return {
        success: false,
        error: err as Error,
        message: err.message, // Add this line
        status: err.status, // And this line
        data: err.data // And this one
      };
    } finally {
      setLoading(false);
    }
  };

  return { data, item, getAll, getById, addData, updateData,getDataByParams,getDataByParamsK1Form,
     deleteData,uploadFile,downloadFile, postData,getData,submitMultipartForm,error, loading };

}
export default useApi;
