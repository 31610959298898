import { GetDataParams } from '../../hooks/useApi';// adjust the import path according to your project structure

export const fetchData = async (getDataByParams: (params: GetDataParams) => Promise<any>, partnershipId: string, clientId: string, taxYearId: string) => {
  let endpointUrl = "PartnerInterest";
  try {
    const { success, data, error } = await getDataByParams({
      endpoint: endpointUrl,
      params: {
        PartnershipID: partnershipId,
        clientId: clientId,
        taxYearID: taxYearId,
      },
    });
    if (!success) {
      // Handle error
      console.error(error);
      return { success: false, error };
    } else {
      // Use data
  //    console.log(data);
      return { success: true, data };
    }
  } catch (error) {
    console.error(error);
    return { success: false, error: error as any };
  }
};

export const fetchDataIcalc = async (getDataByParams: (params: GetDataParams) => Promise<any>, partnershipId: string, clientId: string, taxYearId: string) => {
  let endpointUrl = "IncomeAllocation";
  try {
    const { success, data, error } = await getDataByParams({
      endpoint: endpointUrl,
      params: {
        PartnershipID: partnershipId,
        clientId: clientId,
        taxYearID: taxYearId,
      },
    });
    if (!success) {
      // Handle error
      console.error(error);
      return { success: false, error };
    } else {
      // Use data
   //   console.log(data);
      return { success: true, data };
    }
  } catch (error) {
    console.error(error);
    return { success: false, error: error as any };
  }
};

export const fetchEventData = async (getDataByParams: (params: GetDataParams) => Promise<any>, partnershipId: string, clientId: string, taxYearId: string) => {
  let endpointUrl = "Event";
  try {
    const { success, data, error } = await getDataByParams({
      endpoint: endpointUrl,
      params: {
        PartnershipID: partnershipId,
        clientId: clientId,
        taxYearID: taxYearId,
      },
    });
    if (!success) {
      // Handle error
      console.error(error);
      return { success: false, error };
    } else {
      // Use data
    //  console.log(data);
      return { success: true, data };
    }
  } catch (error) {
    console.error(error);
    return { success: false, error: error as any };
  }
};

export const fetchCapitalAccountData = async (getDataByParams: (params: GetDataParams) => Promise<any>, partnershipId: string, clientId: string, taxYearId: string) => {
  let endpointUrl = "PartnerCapitaldata";
  try {
    const { success, data, error } = await getDataByParams({
      endpoint: endpointUrl,
      params: {
        PartnershipID: partnershipId,
        clientId: clientId,
        taxYearID: taxYearId,
      },
    });
    if (!success) {
      // Handle error
      console.error(error);
      return { success: false, error };
    } else {
      // Use data
   //   console.log(data);
      return { success: true, data };
    }
  } catch (error) {
    console.error(error);
    return { success: false, error: error as any };
  }
};

export const fetchBigBilData = async (getDataByParams: (params: GetDataParams) => Promise<any>, partnershipId: string, clientId: string, taxYearId: string) => {
  let endpointUrl = "BIGBIL";
  try {
    const { success, data, error } = await getDataByParams({
      endpoint: endpointUrl,
      params: {
        PartnershipID: partnershipId,
        clientId: clientId,
        taxYearID: taxYearId,
      },
    });
    if (!success) {
      // Handle error
      console.error(error);
      return { success: false, error };
    } else {
      // Use data
  //    console.log(data);
      return { success: true, data };
    }
  } catch (error) {
    console.error(error);
    return { success: false, error: error as any };
  }
};

export const fetchDistributionResultsData = async (getDataByParams: (params: GetDataParams) => Promise<any>, partnershipId: string, clientId: string, taxYearId: string) => {
  let endpointUrl = "DistributionReults";
  try {
    const { success, data, error } = await getDataByParams({
      endpoint: endpointUrl,
      params: {
        PartnershipID: partnershipId,
        clientId: clientId,
        taxYearID: taxYearId,
      },
    });
    if (!success) {
      // Handle error
      console.error(error);
      return { success: false, error };
    } else {
      // Use data
   //   console.log(data);
      return { success: true, data };
    }
  } catch (error) {
    console.error(error);
    return { success: false, error: error as any };
  }
};

export const fetchDepreciationResultsData = async (getDataByParams: (params: GetDataParams) => Promise<any>, partnershipId: string, clientId: string, taxYearId: string) => {
  let endpointUrl = "DepreciationResults";
  try {
    const { success, data, error } = await getDataByParams({
      endpoint: endpointUrl,
      params: {
        PartnershipID: partnershipId,
        clientId: clientId,
        taxYearID: taxYearId,
      },
    });
    if (!success) {
      // Handle error
      console.error(error);
      return { success: false, error };
    } else {
      // Use data
  //    console.log(data);
      return { success: true, data };
    }
  } catch (error) {
    console.error(error);
    return { success: false, error: error as any };
  }
};

export const fetchAssetValuationData = async (getDataByParams: (params: GetDataParams) => Promise<any>, partnershipId: string, clientId: string) => {
  let endpointUrl = "AssetValuations";
  try {
    const { success, data, error } = await getDataByParams({
      endpoint: endpointUrl,
      params: {
        PartnershipID: partnershipId,
        clientId: clientId,
      },
    });
    if (!success) {
      // Handle error
      console.error(error);
      return { success: false, error };
    } else {
      // Use data
 //     console.log(data);
      return { success: true, data };
    }
  } catch (error) {
    console.error(error);
    return { success: false, error: error as any };
  }
};
export const fetchGainAllocationData = async (getDataByParams: (params: GetDataParams) => Promise<any>, partnershipId: string, clientId: string, taxYearId: string) => {
  let endpointUrl = "GainAllocationResults";
  try {
    const { success, data, error } = await getDataByParams({
      endpoint: endpointUrl,
      params: {
        PartnershipID: partnershipId,
        clientId: clientId,
        taxYearID: taxYearId,
      },
    });
    if (!success) {
      // Handle error
      console.error(error);
      return { success: false, error };
    } else {
      // Use data
  //    console.log(data);
      return { success: true, data };
    }
  } catch (error) {
    console.error(error);
    return { success: false, error: error as any };
  }
};

export const fetchGainDispositionData = async (getDataByParams: (params: GetDataParams) => Promise<any>, partnershipId: string, clientId: string, taxYearId: string) => {
  let endpointUrl = "GainDispositionResults";
  try {
    const { success, data, error } = await getDataByParams({
      endpoint: endpointUrl,
      params: {
        PartnershipID: partnershipId,
        clientId: clientId,
        taxYearID: taxYearId,
      },
    });
    if (!success) {
      // Handle error
      console.error(error);
      return { success: false, error };
    } else {
      // Use data
    //  console.log(data);
      return { success: true, data };
    }
  } catch (error) {
    console.error(error);
    return { success: false, error: error as any };
  }
};
export const fetchDepreciationAllocationData = async (getDataByParams: (params: GetDataParams) => Promise<any>, partnershipId: string, clientId: string, taxYearId: string) => {
  let endpointUrl = "DepreciationAllocation";
  try {
    const { success, data, error } = await getDataByParams({
      endpoint: endpointUrl,
      params: {
        PartnershipID: partnershipId,
        clientId: clientId,
        taxYearID: taxYearId,
      },
    });
    if (!success) {
      // Handle error
      console.error(error);
      return { success: false, error };
    } else {
      // Use data
      //console.log(data);
      return { success: true, data };
    }
  } catch (error) {
    console.error(error);
    return { success: false, error: error as any };
  }
};

export const fetchAssetValuationRolloverData = async (getDataByParams: (params: GetDataParams) => Promise<any>, clientId: string, partnershipId: string) => {
  let endpointUrl = "api/RolloverManagement/AssetValuation/ByClientAndPartnership";
  try {
    const { success, data, error } = await getDataByParams({
      endpoint: endpointUrl,
      params: {
        clientId: clientId,
        PartnershipID: partnershipId,
      },
    });
    if (!success) {
      // Handle error
      console.error(error);
      return { success: false, error };
    } else {
      // Use data
      //console.log(data);
      return { success: true, data };
    }
  } catch (error) {
    console.error(error);
    return { success: false, error: error as any };
  }
};

export const fetchPartnerBigBilRolloverData = async (getDataByParams: (params: GetDataParams) => Promise<any>, clientId: string, partnershipId: string) => {
  let endpointUrl = "api/RolloverManagement/BigBil/ByClientAndPartnership";
  try {
    const { success, data, error } = await getDataByParams({
      endpoint: endpointUrl,
      params: {
        clientId: clientId,
        PartnershipID: partnershipId,
      },
    });
    if (!success) {
      // Handle error
      console.error(error);
      return { success: false, error };
    } else {
      // Use data
      //console.log(data);
      return { success: true, data };
    }
  } catch (error) {
    console.error(error);
    return { success: false, error: error as any };
  }
};
export const fetchPartnerCapitalRolloverData = async (getDataByParams: (params: GetDataParams) => Promise<any>, clientId: string, partnershipId: string) => {
  let endpointUrl = "api/RolloverManagement/PartnerCapital/ByClientAndPartnership";
  try {
    const { success, data, error } = await getDataByParams({
      endpoint: endpointUrl,
      params: {
        clientId: clientId,
        PartnershipID: partnershipId,
      },
    });
    if (!success) {
      // Handle error
      console.error(error);
      return { success: false, error };
    } else {
      // Use data
      //console.log(data);
      return { success: true, data };
    }
  } catch (error) {
    console.error(error);
    return { success: false, error: error as any };
  }
};
export const fetchPartnerInterestRolloverData = async (getDataByParams: (params: GetDataParams) => Promise<any>, clientId: string, partnershipId: string) => {
  let endpointUrl = "api/RolloverManagement/PartnerInterest/ByClientAndPartnership";
  try {
    const { success, data, error } = await getDataByParams({
      endpoint: endpointUrl,
      params: {
        clientId: clientId,
        PartnershipID: partnershipId,
      },
    });
    if (!success) {
      // Handle error
      console.error(error);
      return { success: false, error };
    } else {
      // Use data
      //console.error(data);
      return { success: true, data };
    }
  } catch (error) {
    console.error(error);
    return { success: false, error: error as any };
  }
};
export const fetchHypotheticalGainData = async (getDataByParams: (params: GetDataParams) => Promise<any>, partnershipId: string, clientId: string, taxYearId: string) => {
  let endpointUrl = "HypotheticalGain";
  try {
    const { success, data, error } = await getDataByParams({
      endpoint: endpointUrl,
      params: {
        PartnershipID: partnershipId,
        clientId: clientId,
        taxYearID: taxYearId,
      },
    });
    if (!success) {
      // Handle error
      console.error(error);
      return { success: false, error };
    } else {
      // Use data
      //    console.log(data);
      return { success: true, data };
    }
  } catch (error) {
    console.error(error);
    return { success: false, error: error as any };
  }
};
export const fetchHypotheticalGainAllocationData = async (getDataByParams: (params: GetDataParams) => Promise<any>, partnershipId: string, clientId: string, taxYearId: string) => {
  let endpointUrl = "HypotheticalGainAllocation";
  try {
    const { success, data, error } = await getDataByParams({
      endpoint: endpointUrl,
      params: {
        PartnershipID: partnershipId,
        clientId: clientId,
        taxYearID: taxYearId,
      },
    });
    if (!success) {
      // Handle error
      console.error(error);
      return { success: false, error };
    } else {
      // Use data
      //    console.log(data);
      return { success: true, data };
    }
  } catch (error) {
    console.error(error);
    return { success: false, error: error as any };
  }
};

