import React, {useEffect} from 'react';
import { Container } from 'reactstrap';
import Breadcrumb from 'Components/Common/Breadcrumb';
import SwaggerUtility from "../../helpers/swaggerutils";
import axios from "axios";
import ColumnDefGenerator from 'Components/generator/ColumnDefGeenrator';
import ExcelLoader from "../../Components/ExcelLoader";

const Dashboard = () => {
  document.title = "PartnerTec | Tax calculations made easy";


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Dashboards" breadcrumbItem="Dashboards" />
        </Container>
         <Container fluid>
                 {/*<ColumnDefGenerator url="https://conceptive.co.in/c19/api.json" />*/}
            <ExcelLoader/>
         </Container>
      </div>
    </React.Fragment >
  );
}

export default Dashboard;